<template>
  <div class="Law9">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Law
              <span class="cont">法律常識</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/law" class="">法律常識</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  法律內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">台灣人民與大陸配偶離婚之方式</h2>
              <h5 class="tl">資料來源:法務部</h5>
              <p>
                <span class="cont-p">壹、案例</span><br>
                &emsp;&emsp;小王經朋友介紹，赴大陸四川娶了位大陸女子陳某為妻，結婚返台後，向有關機關申請妻子陳某來台團聚，妻子來台與小王生活一段期間後，小王與妻子經常為日常生活瑣事而爭吵，令小王困擾不已；某日妻子不告而別返回大陸，小王想與妻子解除婚姻，惟妻子已返回大陸，小王應如何與大陸配偶辦理離婚手續？<br><br>
                <span class="cont-p">貳、法律解析</span><br>
                &emsp;&emsp;隨著兩岸交流日益密切，兩岸聯婚逐年增加，惟因兩岸分隔分治多年，兩岸人民在生活習慣及處理事情理念上難免有些隔閡，衍生出許多家庭及社會問題。而兩岸婚姻問題肇因男女心態、來台生活適應不良等問題，使得兩岸婚姻離婚率亦相對增加。
                台灣地區人民與大陸地區人民結婚後如擬離婚，離婚程序可分為在台或在大陸辦理兩種，茲簡述如下：
                <ul class="list">
                  <li>
                    <span>在台灣地區離婚</span>
                    <ul class="list-in">
                      <li>協議離婚：雙方當事人持離婚協議書及身份證正本、戶口名簿等資料至台灣當事人戶籍所在地之戶政事務所申辦離婚登記，兩願離婚始生效力。於辦妥離婚登記後雙方持戶籍謄本、離婚協議書等資料向法院公證處或民間公證事務所辦理離婚事實宣誓書之認證，由大陸配偶持經認證之離婚事實宣誓書，返回大陸辦理離婚登記事宜。</li>
                      <li>判決離婚：大陸配偶具有民法第一千零五十二條規定之事由者（重婚者、與人通姦等），台灣當事人得向戶籍所在地地方法院提起離婚之訴，離婚訴訟判決確定後，至戶務事務所辦理離婚登記。</li>
                    </ul>
                  </li>
                  <li>
                    <span>在大陸地區離婚</span>
                    <ul class="list-in">
                      <li>協議離婚：雙方至大陸配偶戶籍所在地婚姻登記管理機關申請離婚登記，並取得離婚證。台灣當事人持該離婚證，向大陸當地公證處辦理離婚公證書，經海基會驗證後，向我方戶政機關申請離婚登記，離婚日期以離婚證所載之離婚日期為準。</li>
                      <li>調解離婚：經大陸人民法院調解離婚者，可提憑大陸地區人民法院民事調解書暨簽發予離婚兩造之送達證書（或離婚調解書生效證明），向大陸當地公證處辦理公證書，經海基會驗證後，向我方戶政機關申請離婚登記，離婚日期以該民事調解書送達雙方當事人簽收之日期為準。</li>
                      <li>判決離婚：經大陸人民法院判決離婚者，可提憑大陸人民法院民事判決書及確定證明書，向大陸當地公證處辦理公證，經海基會驗證後，再經我法院裁定認可，其離婚日期溯及大陸地區離婚判決確定日期為準。</li>
                    </ul>                  
                  </li>
                </ul>
                <span class="cont-p">參、結語</span><br>
                &emsp;&emsp;上述案例中小王妻子陳某已返回大陸，小王如擬與妻子離婚，可考慮在台提起離婚訴訟或依上述在大陸離婚方式，赴大陸與妻子協議離婚或調解離婚。台灣地區人民如對兩岸離婚方式有任何問題。<br><br>
                <span class="cont-p">相關法律規定：</span><br>
                <span>台灣地區與大陸地區人民關係條例</span><br>
                第五十二條 結婚或兩願離婚之方式及其他要件，依行為地之規定。判決離婚之事由，依台灣地區之法律。<br>
                第五十三條 夫妻之一方為台灣地區人民，一方為大陸地區人民者，其結婚或離婚之效力，依台灣地區之法律。<br>
                第七十四條 第一項 在大陸地區作成之民事確定裁判、民事仲裁判斷，不違背台灣地區公共秩序或善良風俗者，得聲請法院裁定認可。<br><br>
                <span class="cont-p">民法</span><br>
                第一千零四十九條 夫妻兩願離婚者，得自行離婚。但未成年人，應得法定代理人之同意。<br>
                第一千零五十條 兩願離婚，應以書面為之，有二人以上證人之簽名並應向戶政機關為離婚之登記。<br>
                第一千零五十二條 夫妻之一方，有左列情形之一者，他方得向法院請求離婚<br>
                <ul class="list">
                    <li>重婚者。</li>
                    <li>與人通姦者。</li>
                    <li>夫妻之一方受他方不堪同居之虐待者。</li>
                    <li>夫妻之一方對於他方之直系尊親屬為虐待，或受他方之直系尊親屬之虐待，致不堪為共同生活者。</li>
                    <li>夫妻之一方以惡意遺棄他方在繼續狀態中者。</li>
                    <li>夫妻之一方意圖殺害他方者。</li>
                    <li>有不治之惡疾者。</li>
                    <li>有重大不治之精神病者。</li>
                    <li>生死不明已逾三年者。</li>
                    <li>被處三年以上徒刑或因犯不名譽之罪被處徒刑者。</li>
                </ul>
                有前項以外之重大事由，難以維持婚姻者，夫妻之一方得請求離婚。但其事由應由夫妻之一方負責者，僅前方得請求離婚。<br><br>
                <span class="cont-p">大陸婚姻法</span><br>
                第三十一條 男女雙方自願離婚的，准予離婚。雙方須到婚姻登記機關申請離婚。婚姻登記機關查明雙方確實是自願並對子女和財產問題已有適當處理時，發給離婚證。<br>
                第三十二條 男女一方要求離婚的，可由有關部門進行調解或直接向人民法院提出離婚訴訟。人民法院審理離婚案件，應當進行調解；如感情確已破裂，應准予離婚。<br><br>
                <span class="cont-p">有下列情形之一，調解無效的，應准予離婚：</span>
                <ul class="list">
                  <li>重婚或有配偶者與他人同居的；</li>
                  <li>實施家庭暴力或虐待、遺棄家庭成員的；</li>
                  <li>有賭博、吸毒等惡習屢教不改的；</li>
                  <li>因感情不和分居滿二年的；</li>
                  <li>其他導致夫妻感情破裂的情形。</li>
                </ul>
                一方被宣告失蹤，另一方提出離婚訴訟的，應准予離婚。
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Law9',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service4.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
  margin: 1rem auto;
}
.list-in{
  list-style: upper-roman;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>