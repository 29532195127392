<template>
  <div class="News12">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              News
              <span class="cont">真實案例</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/News" class="">真實案例</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">李鴻源妻再戰高金素梅 死守30年婚姻 與夫「生死與共」</h2>
              <h5 class="tl">蘋果日報 2009/03/24 報導:特勤組、突發中心、晏明強</h5>
              <p>                
                &emsp;&emsp;台北縣前副縣長李鴻源和立委高金素梅婚外情曝光近3年，因女方半年前花1億2千萬元在長安東路和大直買2間豪宅，資金來源不明遭特偵組調查，消息曝光後，李鴻源辭去副縣長，回台大教書，他老婆李慧馨跟學校請假幾天，昨現身台灣藝術大學教課，對老公為高金丟了烏紗帽，她心疼哽咽，仍願痴等他回頭，她說：「我們都結婚30幾年了……他陪著我走過人生中很多重要時刻，我們『情深義重，生死與共』，我會照顧他的。」<br><br>
                &emsp;&emsp;李慧馨昨午穿白西裝外套、窄裙、配上絲巾和淡紫色眼影，跟平時脂粉未施、一身輕便判若兩人，昨她特地換教室上課，下課後到停車場取車，記者上前採訪，她顯得鎮定沉穩，仍願停下腳步受訪，以下為記者（記）採訪李慧馨（李）內容：<br><br>
                <span class="cont-p">他會繼續翱翔</span><br>
                記：李副縣長因為跟高金的緋聞處理不當而請辭，會不會替他覺得可惜或難過？<br>
                李：當然是會……在電視上他應該已經講得很清楚，你們可以去參考《歸去來辭》，我想那裡面寫的就代表他的心聲，這次他算是在人生中跌了很大一跤（語氣哽咽），但是我都知道，也覺得其實還好，他就像一隻大鵬鳥般傷到幾根羽毛而已，沒有傷到最重要的「飛羽」，我相信他會活得很好，繼續翱翔。<br>
                記：他辭職時妳第一時間就知道嗎？還是夫妻倆討論出的決定？<br>
                李：……（眉頭深鎖，低頭不語）<br>
                記：小孩都知道爸爸現在處境嗎？<br>
                李：嗯……他對我、對父母、對子女所做的我們都有感受到，也都會繼續照顧他。<br>
                記：有傳聞說妳的婆婆（李鴻源之母）借1000萬給高金素梅買房子，是真的嗎？<br>
                李：你們都是斷章取義，看圖說故事……他不是已經否認過了嗎？<br>
                記：《壹週刊》報導妳常半夜去高金素梅溫州路住處「喚夫」，讓鄰居不堪其擾，他們才會決定另築愛巢，是真的嗎？<br>
                李：（面露尷尬，說話結巴）我……我就說了你們就是……斷章取義跟看圖說故事嘛！<br><br>
                <span class="cont-p">我會照顧他的</span><br>
                記：李鴻源跟高金素梅位於「戀戀長安」和「明水大觀」的新家正在裝潢，妳有去看過嗎？<br>
                李：（變臉微怒、激動）我不回答這個問題！<br>
                記：有什麼話要對高金素梅說的？<br>
                李：我說過我不回答這類的問題！<br>
                記：老公今天有回家嗎？是不是仍不常回來？<br>
                李：（一時語塞，迴避問題）……我就說了嘛，我會照顧他的！<br>
                記：婚姻會繼續下去嗎？<br>
                李：拜託！我們都結婚30幾年了……他陪著我走過人生中很多重要時刻，我們「情深義重，生死與共」，就這樣！<br>
                記：事件爆發後在學校會不會壓力很大？妳前幾天好像都請假？<br>
                李：（情緒稍緩，強顏歡笑）我現在不是來了嗎？<br>
                高金素梅沉潛多日後，今日可能現身立法院，高金幕僚昨表示有許多部落民眾北上為她打氣，她決定出面接待。至於李鴻源妻子表示與他生死與共，似乎不會與李離婚，高金幕僚表示，這並非公務問題，無法代回應。記者昨晚致電李鴻源，但截稿前仍未取得回應。
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'News12',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service3.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
    margin: 1rem auto;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>