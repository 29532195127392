<template>
  <div class="Woman8">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Woman
              <span class="cont">女人心事</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/woman_sample" class="">女人心事</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">縣長愛情故事 佳人失語 楊秋興暗戀成真</h2>
              <h5 class="tl">聯合新聞網 2008/09/29 【記者胡宗鳳卅高雄縣報導】</h5>
              <p>
                起初 她連一眼也沒看他...<br>
                她出車禍 他陪在旁教她重新認字說話...<br>
                現在提到妻子 他總說「阮叨ㄟ水某」<br><br>
                高雄縣長楊秋興的妻子林淑芬的笑容甜美，但在笑顏背後，有一段不為人知的愛情故事；當年楊秋興苦追不成，她因一場車禍導致失語，楊秋興陪在身邊一字一句教她三年，終於抱得美人歸。<br><br>
                婚後迄今，林淑芬現在提筆仍會忘字，兩人當年用來識字的一本字典，已被翻得破爛不堪，她仍捨不得丟掉；因常忘字，她參加公益活動時常「惜字如金」，用微笑代替言語。<br><br>
                「阮叨ㄟ水某」只要談到太太林淑芬，楊秋興就忍不住溜出這句話。他們曾是中鼎公司同事，「這麼水，又會寫文章的女孩真少！」楊秋興說，林淑芬常在公司刊物發表散文，吸引他的注意；「那時她連看我一眼都沒有」，加上身高比他高，他始終不敢表達愛意。<br><br>
                七十一年九月，林淑芬下班騎機車回家車禍撞傷腦部，陷入重度昏迷，醫院不敢替她動手術，昏迷兩周後腦部血塊奇蹟似消退而醒來，但無法言語和寫字，讓她很痛苦。<br><br>
                休息三個月後回公司上班，楊秋興將她編入同組，為她分擔部分工作，教她重新認字、說話。楊秋興的體貼，讓他取得「敗部復活」的機會。<br><br>
                「考不上研究所就不要來往」，林淑芬感受到楊秋興的濃情蜜意，期望他考上研究所再來追她；楊秋興也提出條件，「那妳要陪我到圖書館Ｋ書」。<br><br>
                楊秋興考上研究所後與林淑芬訂婚。當時公司沒有人知道他們交往，林淑芬發喜糖時，同事還不解問：「怎麼楊秋興訂婚，卻是妳在發喜糖？」<br><br>
                林淑芬早年曾考取高雄女中與師專，為貼補家計改讀高雄工專，未讀大學是她最大的遺憾；五年前，林淑芬悄悄報考高雄應用科技大學商學管理研究所，去年畢業。<br><br>
                楊秋興說，儘管林淑芬讀書認字仍很吃力，卻願意比別人多用三年的時間完成學業，「可見她的毅力！」<br><br>
                儘管車禍迄今已廿六年，林淑芬目前仍須每天服藥。去年總統大選，藍綠拚場激烈，楊秋興在台前喊得聲嘶力竭，林淑芬在後台昏倒，引發場面混亂。至今，楊秋興每天都要叮嚀老婆吃藥，體貼不改當年。
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Woman8',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service5.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
  margin: 1rem auto;
}
.list-in{
  list-style: upper-roman;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>