<template>
  <div class="Serve2">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Service
              <span class="cont">工商徵信</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/Service" class="">服務項目</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  工商徵信
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">工商徵信</h2>
              <p>
                現在時代進步，科技愈來愈發達，一切都朝向e世代前進，工商徵信在經濟生活與工商社會，愈來愈重要，且扮演著市場交易安全的監控、市場交易的穩定性、交易信用驗證的重要角色。<br><br>
                女人徵信有限公司因洞悉現今環境如此，故特別成立「工商徵信服務」，目的為節省一般公司行號授信流程繁久、人力成本花費 … 等問題，並有效的預防呆帳產生；建立公司行號往來客戶徵信之檔案及信用檢核制度。只要您提供想了解之工商對象資料，女人徵信有限公司為您快速、有效率性的完成信用審核的所有作業流程，專業工商徵信協助您解決事業問題。
              </p>
              <h4>女人徵信有限公司工商徵信服務項目如下：</h4>
              <ul class="list">
                  <li>商標專利仿冒</li>
                  <li>合作廠商調查</li>
                  <li>應收帳款催收</li>
                  <li>工商法律諮詢</li>
                  <li>各種您需要的客製化服務</li>
              </ul>
              <h4>工商徵信－合作廠商調查之要點：</h4>
              <ul class="list">
                  <li>主動徵信： 爭取優良往來的廠商，開發事業合作的新夥件。</li>
                  <li>事前徵信： 針對新往來客戶的篩選，以了解對方的背景，一一加以過濾，再決定是否交易。</li>
                  <li>定期徵信： 掌握長期往來客戶的最新動態，定期了解及早點發現潛在危機。</li>
                  <li>臨時性徵信： 有異常徵兆發生或接獲其有危險訊息時，應立即透過徵信調查了解真實現況，以採取因應措施。</li>
              </ul>
              <p>工商徵信之於企業團體為不可或缺之行為；企業想要營運順利，除了本身的體制優良之外，合作廠商的重要性不言可喻，然而許多企業主卻往往無法正確得知對方的營運狀況，於是被合作廠商所拖累而蒙受重大損失。女人徵信有限公司有專業工商徵信服務，針對您的需求進行全面性之完整調查；專業工商徵信服務，是您事業上的最佳拍檔！</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Serve2',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service2.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
}
.list{
  margin: 0.5rem auto;
  list-style-type: decimal;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 22px; 
  height: 22px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
       transform: rotate(45deg);
       transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>