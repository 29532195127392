<template>
  <div class="Law">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Law
              <span class="cont">法律常識</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  法律常識
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="item-content">
              <div class="item">
                <div class="row justify-content-center item-t1">
                  <div class="col-12">
                    <h2 class="lineder mb-5" id="element">法律常識</h2>
                  </div>
                  <div class="col-lg-6 col-md-10 col-sm-12">
                    <ul class="list">
                      <li>
                        <router-link to="/law1">
                          丈夫大陸包二奶又生子，元配有何權利？得拒絕該名小孩入籍嗎？
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/law2">
                          大陸二奶所生子女，經台商生父認領後，是否具有繼承權？
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/law3">
                          大陸包二奶之刑責
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/law4">
                          大陸二奶可否爭取台商財產？
                        </router-link>
                        
                      </li>
                      <li>
                        <router-link to="/law5">
                          什麼狀況下，可請求離婚？
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/law6">
                          什麼情況可以申請保護令？
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/law7">
                          分居三年，就可以訴請離婚？
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/law8">
                          夫妻離婚 須分擔扶養義務
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/law9">
                          台灣人民與大陸配偶離婚之方式
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/law10">
                          如何辦理離婚程序？
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/law11">
                          有公開儀式結婚但不登記，算無效婚姻？
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/law12">
                          何謂家庭暴力？
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/law13">
                          拖油瓶、分婚生子女是否可繼承繼父的遺產？
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/law14">
                          法律對於夫妻之住所是否有相關規定？
                        </router-link>
                      </li>
                    </ul>
                    <nav class="d-flex justify-content-center">
                        <ul class="pagination">
                            <li class="page-item disabled">
                                <span class="page-link">回上頁</span>
                            </li>
                            <li class="page-item active" aria-current="page">
                                <span class="page-link">1</span>
                            </li>
                            <li class="page-item disabled">
                                <span class="page-link">下一頁</span>
                            </li>
                        </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Law",
  data() {
    return {};
  },
};
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service4.jpg);
  background-position: 30% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.lineder {
  font-size: 3rem;
  text-align: center;
}
.lineder::after {
  content: "";
  width: 8%;
  height: 1.5px;
  background: red;
  display: block;
  margin: 0 auto;
}
.item-t1{
  margin-top: 10px;
}
.item-content{
  margin-bottom: 20px;
}
.item-content .item {
  padding: 1rem;
  background: white;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
  text-align: inherit;
}
.list{
  margin-bottom: 2rem;
  list-style: circle;
}
.list li{
  margin: 0.8rem auto;
  background-image: linear-gradient(to left, darkgoldenrod 0%, darkgoldenrod 70%, transparent 50%);
  background-size: 16px 1px;
  background-position: bottom;
  background-repeat: repeat-x;
}
.list li a{
  color:  black;
}
.list li a:hover{
  color:  burlywood;
}
.disabled:hover{
  cursor: not-allowed;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>

