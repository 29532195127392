<template>
  <div class="Sitemap">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Sitemap
              <span class="cont">網路地圖</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  網路地圖
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="item-content">
              <div class="item">
                <div class="row justify-content-center item-t1">
                  <div class="col-12">
                    <h2 class="lineder mb-5" id="element">網路地圖</h2>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="sitemap-main">★主要類別</div>
                    <div class="sitemap-link">
                      <ul class="list">
                        <li><router-link to="/" class="nav-link"><font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />首頁</router-link></li>
                        <li><router-link to="/About" class="nav-link"><font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />關於我們</router-link></li>
                        <li>
                          <div class="">
                            <div class="sitemap">
                              <router-link to="/Service" class="nav-link">
                                ★服務項目
                              </router-link>                          
                            </div>
                            <ul class="list">
                              <li>
                                <router-link to="/Service1" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />外遇抓姦
                                </router-link>
                              </li>
                              <li>
                                <router-link to="/Service2" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />工商徵信
                                </router-link>
                              </li>
                              <li>
                                <router-link to="/Service3" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />感情挽回
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/Service4" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />婚前徵信
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/Service5" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />家暴徵信
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/Service6" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />跨國專案
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/Service7" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />離婚協助
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/Service8" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />尋人徵信
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/Service9" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />詐騙行為蒐證
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/Service10" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />專利商標仿冒
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/Service11" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />其他徵信社服務
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/Service12" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />二十四小時行蹤監控
                                </router-link>
                                </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div class="">
                            <div class="sitemap">
                              <router-link to="/News" class="nav-link">
                                ★真實案例
                              </router-link>                          
                            </div>
                            <ul class="list">
                              <li>
                                <router-link to="/News1" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />宇多田離婚 只為喘口氣
                                </router-link>
                              </li>
                              <li>
                                <router-link to="/News2" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />佼佼保證 阿寶非第三者
                                </router-link>
                              </li>
                              <li>
                                <router-link to="/News3" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />原諒妻出軌 再告不起訴
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/News4" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />假孫女養出真親情 追身世為領養
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/News5" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />男子戴綠帽 收傳票才知道
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/News6" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />為婚外情殺妻女 張鶴齡判死刑
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/News7" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />家暴漢性侵2少年 判刑13年
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/News8" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />要養私生子 妻驗...不是他的
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/News9" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />失蹤4年房客 見報一天現身
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/News10" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />扁公開信爆內幕女兒遭劈腿、閃電結婚
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/News11" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />紀香離婚前捉猴 宣示主權
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/News12" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />李鴻源妻再戰高金素梅 死守30年婚姻 與夫「生死與共」
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/News13" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />劈腿？林子欽已和女友結婚 撇談陳幸妤
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/News14" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />三分之一日本女性有遭家暴經驗
                                </router-link>
                                </li>
                              <li>
                                <router-link to="/News15" class="nav-link">
                                  <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />妻劈腿生女 夫白養10年才知
                                </router-link>
                                </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="sitemap">
                      <router-link to="/law" class="nav-link">★法律常識</router-link>
                    </div>
                    <div class="sitemap-link">
                      <ul class="list">
                        <li>
                          <router-link to="/law1" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />丈夫大陸包二奶又生子，元配有何權利？得拒絕該名小孩入籍嗎？
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/law2" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />大陸二奶所生子女，經台商生父認領後，是否具有繼承權？
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/law3" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />大陸包二奶之刑責
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/law4" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />大陸二奶可否爭取台商財產？
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/law5" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />什麼狀況下，可請求離婚？
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/law6" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />什麼情況可以申請保護令？
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/law7" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />分居三年，就可以訴請離婚？
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/law8" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />夫妻離婚 須分擔扶養義務
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/law9" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />台灣人民與大陸配偶離婚之方式
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/law10" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />如何辦理離婚程序？
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/law11" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />有公開儀式結婚但不登記，算無效婚姻？
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/law12" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />何謂家庭暴力？
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/law13" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />拖油瓶、分婚生子女是否可繼承繼父的遺產？
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/law14" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />法律對於夫妻之住所是否有相關規定？
                          </router-link>
                        </li>
                      </ul>
                    </div>
                    <div class="sitemap">
                      <router-link to="/woman_sample" class="nav-link">★女人心事</router-link>
                    </div>
                    <div class="sitemap-link">
                      <ul class="list">
                        <li>
                          <router-link to="/woman1" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />婚姻的危機－外遇
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/woman2" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />你劈腿我就外遇
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/woman3" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />離婚後，我竟成了前夫的二奶
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/woman4" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />遠離家暴 找回自信
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/woman5" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />不帶孩子的離婚難題
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/woman6" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />已婚裝單身 搞什麼鬼？
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/woman7" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />職場經驗 差點成為第三者
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/woman8" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />縣長愛情故事 佳人失語 楊秋興暗戀成真
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/woman9" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />戀情二十年 天天在捉姦
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/woman10" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />離婚故事 只是在等孩子長大
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/woman11" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />凋零的花朵...家庭暴力
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/woman12" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />簡短是愛情的平安險
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/woman13" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />人沒變，變的是感覺
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/woman14" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />關於，男人的甜言蜜語。
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/woman15" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />見面‧做愛‧回家。
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/woman16" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />女人，喜歡男人怎麼吻她？
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/woman17" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />人間蒸發，超愚蠢的分手手段啊！
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/woman18" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />如果我死了
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/woman19" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />男人偷腥大爆料！
                          </router-link>
                          </li>
                        <li>
                          <router-link to="/woman20" class="nav-link">
                            <font-awesome-icon icon="long-arrow-alt-right" class="mr-2" />沒事分手??
                          </router-link>
                          </li>                        
                        <li>
                          <div class="sitemap">
                            <router-link to="/Contact" class="nav-link">★聯絡我們</router-link>
                          </div>
                          </li>
                        <li>
                          <div class="sitemap">
                            <a href="javascript:void(0)" @click="rest()" class="nav-link">★網路地圖</a>
                          </div>
                          </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Sitemap",
  data(){
    return{};
  },
  methods:{
    rest(){
      this.$router.go(0)
    }
  }
};
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service7.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.lineder {
  font-size: 3rem;
  text-align: center;
}
.lineder::after {
  content: "";
  width: 8%;
  height: 1.5px;
  background: red;
  display: block;
  margin: 0 auto;
}
.item-t1{
  margin-top: 10px;
}
.item-content{
  margin-bottom: 20px;
}
.item-content .item {
  padding: 1rem;
  background: white;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
  text-align: inherit;
}
.list{
  margin: 0 auto;
  margin-bottom: 2rem;
  list-style: none;
  padding: 0;

}
.list li{
  margin: 0.8rem auto;
  background-image: linear-gradient(to left, darkgoldenrod 0%, darkgoldenrod 70%, transparent 50%);
  background-size: 16px 1px;
  background-position: bottom;
  background-repeat: repeat-x;
  text-align: center;

}
.list li a{
  color:  black;
}
.list li a:hover{
  color:  burlywood;
}
.disabled:hover{
  cursor: not-allowed;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
.sitemap-main{
  margin: 0 auto;
  text-align: center;
  background-color: turquoise;
  border-radius: 12px;
  padding: 5px 0px;
  font-size: 25px;
}
.sitemap a{
  color: black;
  background-color: turquoise;
  border-radius: 12px;
  text-align: center;
  font-size: 21px;
}
.sitemap a:hover{
  color: burlywood;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>
