<template>
  <div class="Woman14">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Woman
              <span class="cont">女人心事</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/woman_sample" class="">女人心事</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">關於，男人的甜言蜜語。</h2>
              <h5 class="tl">水瓶鯨魚 轉載自Yahoo!奇摩時尚</h5>
              <div class="">
                <img src="../assets/images/Woman.jpg" class="img-fluid mb-3" alt="男人的甜言蜜語">
              </div>
              <p>
                後來的我，從不相信男人的甜言蜜語，卻得承認，那瞬間，很受用。<br>
                受用的原因，和全世界所有少女、輕熟女和熟女都一樣，被稱讚：「我從來沒遇過像妳這麼有趣的女人」、「我不知道妳身材這麼好」、「為什麼妳的皮膚這麼好？」「開我玩笑吧？！我以為妳不到30歲。」「不可能，妳怎麼會沒有男朋友？」「妳條件這麼好，為何還不結婚？一定太挑了。」<br>
                <span class="cont-p">這些年長男人、年輕男人甚或陌生男人的甜言蜜語，可能是客套話、敷衍話、調情話或有勾引意味的試探語，第一次聽到都是開心的，任何男人的甜言蜜語總讓女人多幾分自信。</span><br>
                以前的我，總是非常害羞回應：「沒有啦，我很嚴肅，沒那麼有趣」、「我身材不好，腰部很多贅肉」、「因為我化妝，燈光太暗，你一定沒看清楚，我有黑眼圈呢」、「你也在開玩笑吧，我是歐巴桑了」、「我條件哪裡好？哪比得上年輕小女生？」<br>
                後來的我，偶爾還是會不好意思，慢慢地，卻厚起小臉皮，小鳴小放。<br>
                「原來，我就只剩下有趣喔？老是聽到這種形容詞，講別的嘛，取悅我一下！」「好吧，謝謝我媽遺傳，讓我身材沒變形型。」<br>
                「哎喲，我也很疑惑我條件不差，為什麼沒有結婚？」<br><br>
                <span class="cont-p">因為在這個講究「個人姿態」的淺碟子世代，越來越不同。</span><br>
                當一個人自謙自己笨，倘若他沒有可引以為傲的成就，講久了，所有的人都會認為他笨；當一個人自謙自己是爛好人，所有的人都會慢慢以爛好人幫他定位、希望他好人幫到底；當一個人說自己條件很差，所有的人不是私下罵這個人假仙、就是用放大鏡確認他確實條件不好。<br>
                與其如此，我也不想當古代人。<br>
                <span class="cont-p">謙遜，對普通人來講，已經不是美德，運氣壞的時候，還會讓人輕視你、欺負你，因為你為自己這樣定位</span>。謙遜態度，只適合一些有豐功偉業的人，大家會馬後炮讚美你；謙遜態度，只適合真正認識你的人，他們才知道你是謙遜。<br>
                於是，當一個女人認為自己不是美女，過度挑剔自己，慢慢地，沒有男人和女人會認為妳是美女。何必挖瘡疤給每個人知道？他們又不是妳那一國的。妳下巴或臉頰那顆小小冒出來的青春痘，只有妳最清楚，妳不講，沒有人會發現；妳開玩笑講了，其他人都笑了，感覺妳的好玩，反而忘了你的青春痘。<br>
                男人對女人講的甜言蜜語，就像一個視力不好的人，他說的或許不準確，是客套話、敷衍話、調情話或有勾引意味的試探語；男人對女人說的甜言蜜語，也可能像一個視力很好的人，他真的是客套話、敷衍話、調情話或有勾引意味的試探語。<br>
                好吧，我接受客套話、敷衍話、調情話或有勾引意味的試探語，表示我還值得男人這樣做，我有行情。<br>
                「美女！」客套話來了。<br>
                「嗯，怎樣？幹麻？」我來了。<br>
                很受用，讓我高興，只是我不會真的當真。當然，不能當真。<br><br>
                <span class="cont-p">我們要對自己謙遜，不要對別人過度謙遜。</span><br>
                <span class="cont-p">畢竟甜言蜜語，比毒舌狠話，好聽太多。</span><br><br>
                男人對女人說的甜言蜜語，某瞬間，都是溼冷天季節裡的小陽光，只是女人們別被灌了迷湯，就忘了自備雨具出門。               
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Woman14',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service5.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
  margin: 1rem auto;
}
.list-in{
  list-style: upper-roman;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>