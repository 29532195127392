<template>
  <div class="Woman20">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Woman
              <span class="cont">女人心事</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/woman_sample" class="">女人心事</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">沒事分手??</h2>
              <h5 class="tl">躲在角落畫圈圈 轉載自Yahoo!奇摩時尚</h5>
              <p>
                輾轉耳聞米奇分手的消息時...<br><br>
                朋友之間都驚訝不已...<br><br>
                情侶分手本身不是什麼太了不起的事...<br><br>
                但是這對情侶已經交往了五六年或著更久...<br><br>
                某種程度上...<br><br>
                他們算是一個朋友間的幸福精神指標...<br><br>
                我在尋找愛情時也常將自己的願望投射在他倆身上...<br><br>
                沒想到這道精神上的高牆...<br><br>
                垮了!!!!<br><br>
                過了幾天...壓抑不住好奇心...<br><br>
                在電腦前用手指敲著鍵盤試著向米奇探探口風...<br><br>
                我是有抱米奇應該完全不想談不想聊這事兒的心理準備...<br><br>
                但...沒想到他的回話居然表現的頗平靜...<br><br>
                完全沒有流露出反抗這題話的意思...<br><br>
                『是有誰偷吃嗎??』我問了... <br><br>
                <span class="cont-p">『完全沒有...我們二人在這方面上到是一直相當自律...』</span>米奇回了<br><br>
                『該不會是誰外遇有了第三者??』我再度發問... <br><br>
                <span class="cont-p">『也不是...連偷吃都懶的二個人怎麼可能會有發展成外遇的機會..』米奇回的一派輕鬆...</span><br><br>
                『是他哪裡對你不好嗎??』我繼續問... <br><br>
                <span class="cont-p">『這一二年的確沒有像剛開始交往時那麼好...但也不至於到"不好"的程度...』</span>米奇回的很快...<br><br>
                『還是你受不了他的壞習慣??』我持續發問... <br><br>
                <span class="cont-p">『再壞的習慣...都相處這麼久...也早該習慣了吧...』米奇仍舊語帶平靜...</span><br><br>
                問到這裡...我墜入五里霧了... <br><br>
                『那到底是為了什麼事吵架...吵到非要分手不可??』我有點沒耐性的再問...<br><br>
                <span class="cont-p">『你誤會了...我們已經一二年沒有吵架了....』</span><br><br>
                『那...那...那...那到底是為了什麼要分手啊???』我終於受不了的直搗黃龍...<br><br>
                <span class="cont-p">『因為...連架都吵不起來...』</span><br><br>
                <span class="cont-p">『彷彿有很多事以前都會生氣的...但...現在卻連架都吵不起來了...』米奇終於有點情緒了...</span><br><br>
                『沒架可吵不是很好嗎??...這...這...這...這不是沒事自找麻煩嗎??』我真的不解...<br><br>
                <span class="cont-p">『是的...就是沒事...已經沒事到連吵架的動力都沒有...』</span><br><br>
                <span class="cont-p">『這段感情說要直到終老似乎也可以成立...』</span><br><br>              
                <span class="cont-p">『但...二個人不只是肉體連心靈都程裸相見...對於彼此的生活習慣完全知曉...連小祕密都很難生存...』</span><br><br>
                <span class="cont-p">『完全沒有神祕感...這段感情被透視的一清二楚...』</span><br><br>
                <span class="cont-p">『但...最重要的"期待"呢??...這段被摸透了的愛情我感受不到"期待"了...』</span><br><br>
                <span class="cont-p">『是的...就是因為已經沒事...所以差不多可以分手了...』</span><br><br>
                『..............................................』               
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Woman20',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service5.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
  margin: 1rem auto;
}
.list-in{
  list-style: upper-roman;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>