<template>
  <div class="Serve6">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Service
              <span class="cont">跨國專案</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/Service" class="">服務項目</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  跨國專案
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">跨國專案</h2>
              <p>
                台商大陸包二奶的新聞頻傳，女人徵信有限公司專業跨國專案服務提供台商台幹行蹤調查、大陸二奶調查、跨海抓姦服務；女人徵信有限公司專業跨國專案服務，除了有專業人員派遣大陸之外，更使用高科技徵信器材跟蹤調查蒐證，讓您輕輕鬆鬆的掌握另外一半的行蹤、是否包二奶等相關情形，讓您及早預防二奶破壞家庭！<br><br>
                女人徵信有限公司有嚴格的職業道德，對於客戶之隱私完全保密！大陸地區地廣人多，酒店、聲色場所的消費低廉，與多酒店女子或者有心人士往往看上台商口袋裡面的錢，手段無所不用其極、為達目的不擇手段。很多人不知道丈夫大陸包二奶，或者是以為丈夫大陸包二奶不過是逢場作戲，然而許多真實案例顯示二奶問題輕則夫妻失和、破財消災，重則妻離子散、家財散盡！<br><br>                
                <span style="color:red;font-weight:600;font-size:18px;">台商大陸包二奶的原因通常為：</span><br>
                <ul class="list">
                    <li>異鄉寂寞：台商往往隻身前往，漫漫長夜於是寂寞難耐，於是當有機會時，就很容易包養二奶。</li>
                    <li>包養費用便宜：大陸地區的消費比台灣便宜太多，台商在大陸往往被奉為上賓，相對的，想要包養二奶的費用也比台灣便宜許多，也於是，台商大陸包二奶成為普遍的行為。</li>
                    <li>齊人之福的快感：男人天生是下半身思考的動物；每個男人內心難免幻想左擁右抱，而在大陸的天時、地利、人和的情況之下，誰都會動想要包養二奶的念頭。相較於台灣女子的強勢，大陸女子的年輕溫柔滿足台商大男人感覺，於是大陸包二奶也因此蔚為風氣。</li>
                    <li>大家都這樣：大陸包二奶於台商之間是非常普遍的行為；即使內心認為外遇是背叛婚姻、對妻子的不忠，然而長久時間耳濡目染之下，很容易開始認為包養二奶也沒什麼，反正大家都這樣！更何況台商之間往往相互掩護，甚至比較誰包的二奶比較多！</li>
                </ul>
                <span style="color:red;font-weight:600;font-size:18px;">女人徵信有限公司大陸包二奶服務，協助您調查以下項目：</span><br>
                <ul class="list">
                    <li>台商台幹行蹤調查</li>
                    <li>大陸二奶調查蒐證</li>
                    <li>跨海抓姦</li>
                </ul>
                女人徵信有限公司協助您解決大陸包二奶問題、順利擊退二奶！
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Serve6',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service2.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
}
.list{
  margin: 0.5rem auto;
  list-style-type: decimal;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>