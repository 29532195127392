<template>
  <div class="Service">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Service
              <span class="cont">服務項目</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  服務項目
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="warp">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="item-content">
                <div class="item">
                  <div class="row justify-content-center item-t1">
                    <div class="col-12">
                      <h2 class="lineder mb-5" id="element">服務項目</h2>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="grid">
                        <figure class="effect-romeo">
                          <img src="../assets/images/serve/1.png" alt="外遇抓姦"/>
                          <figcaption>
                            <h2>外遇 <span>抓姦</span></h2>
                            <p>女人徵信有限公司的外遇抓姦服務都是您最好的選擇！</p>
                            <router-link to="/Service1">
                              View more
                            </router-link>
                          </figcaption>			
                        </figure>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="grid">
                        <figure class="effect-romeo">
                          <img src="../assets/images/serve/2.png" alt="工商徵信"/>
                          <figcaption>
                            <h2>工商<span>徵信</span></h2>
                            <p>女人徵信有限公司為您快速、有效率性的完成信用審核的所有作業流程。</p>
                            <router-link to="/Service2">
                              View more
                            </router-link>
                          </figcaption>			
                        </figure>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="grid">
                        <figure class="effect-romeo">
                          <img src="../assets/images/serve/3.png" alt="感情挽回"/>
                          <figcaption>
                            <h2>感情<span>挽回</span></h2>
                            <p>您想挽回他/她的心嗎?交給女人徵信有限公司來處理吧！</p>
                            <router-link to="/Service3">
                              View more
                            </router-link>
                          </figcaption>			
                        </figure>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="grid">
                        <figure class="effect-romeo">
                          <img src="../assets/images/serve/4.png" alt="婚前徵信"/>
                          <figcaption>
                            <h2>婚前<span>徵信</span></h2>
                            <p>為何當初以為是佳偶天成，結婚後卻發現對方不如自己想像？</p>
                            <router-link to="/Service4">
                              View more
                            </router-link>
                          </figcaption>			
                        </figure>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="grid">
                        <figure class="effect-romeo">
                          <img src="../assets/images/serve/5.png" alt="家暴徵信"/>
                          <figcaption>
                            <h2>家爆<span>徵信</span></h2>
                            <p>當您面臨家庭暴力的威脅時，讓女人徵信有限公司來協助您。</p>
                            <router-link to="/Service5">
                              View more
                            </router-link>
                          </figcaption>			
                        </figure>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="grid">
                        <figure class="effect-romeo">
                          <img src="../assets/images/serve/6.png" alt="跨國專案"/>
                          <figcaption>
                            <h2>跨國<span>專案</span></h2>
                            <p>女人徵信有限公司有嚴格的職業道德，對於客戶之隱私完全保密！</p>
                            <router-link to="/Service6">
                              View more
                            </router-link>
                          </figcaption>			
                        </figure>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="grid">
                        <figure class="effect-romeo">
                          <img src="../assets/images/serve/7.png" alt="離婚協助"/>
                          <figcaption>
                            <h2>離婚<span>協助</span></h2>
                            <p>不是每段婚姻都可以白頭偕老、甜蜜恩愛的走到最後。</p>
                            <router-link to="/Service7">
                              View more
                            </router-link>
                          </figcaption>			
                        </figure>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="grid">
                        <figure class="effect-romeo">
                          <img src="../assets/images/serve/8.png" alt="尋人徵信"/>
                          <figcaption>
                            <h2>尋人<span>徵信</span></h2>
                            <p>女人徵信有限公司有專業尋人專家，無論您尋人對象是誰，都讓女人徵信有限公司為您成功尋人！</p>
                            <router-link to="/Service8">
                              View more
                            </router-link>
                          </figcaption>			
                        </figure>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="grid">
                        <figure class="effect-romeo">
                          <img src="../assets/images/serve/10.png" alt="詐騙行為蒐證"/>
                          <figcaption>
                            <h2>詐騙行為<span>蒐證</span></h2>
                            <p>女人徵信有限公司提供詐騙行為蒐證服務，提供相關防詐騙諮詢與蒐證協助！</p>
                            <router-link to="/Service9">
                              View more
                            </router-link>
                          </figcaption>			
                        </figure>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="grid">
                        <figure class="effect-romeo">
                          <img src="../assets/images/serve/11.png" alt="專利商標仿冒"/>
                          <figcaption>
                            <h2>專利商標<span>仿冒</span></h2>
                            <p>女人徵信特別成立專利商標仿冒調查小組，專門針對企業疲於應付的專利商標仿冒問題做徹底的調查。</p>
                            <router-link to="/Service10">
                              View more
                            </router-link>
                          </figcaption>			
                        </figure>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="grid">
                        <figure class="effect-romeo">
                          <img src="../assets/images/serve/12.png" alt="其他徵信社服務"/>
                          <figcaption>
                            <h2>其他徵信社<span>服務</span></h2>
                            <p>請聯絡女人徵信有限公司讓我們的專業迅速的優質服務幫助您順利解決各種問題！</p>
                            <router-link to="/Service11">
                              View more
                            </router-link>
                          </figcaption>			
                        </figure>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="grid">
                        <figure class="effect-romeo">
                          <img src="../assets/images/serve/13.png" alt="二十四小時行蹤監控"/>
                          <figcaption>
                            <h2>二十四小時行蹤<span>監控</span></h2>
                            <p>當您有所懷疑就要調查清楚，讓我們專業二十四小時行蹤監控服務為您找出真相！</p>
                            <router-link to="/Service12">
                              View more
                            </router-link>
                          </figcaption>			
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <b-modal id="modal-lg" size="lg" centered title="外遇調查">
        <div class="d-block">
          <p class="qa-p">
            愛真的可以天長地久嗎? 我的另一半明天是否依然愛我?<br><br>
            &emsp;&emsp;親愛的他(她)對你越來越冷淡了?當你遇到人生中不如意的事情，正當不知所措急於想知道事情的真相，雖然我們都知道，事實的真相是殘酷的現實，況且您可能只是懷疑，我們給您快速又專業的徵信，你只要一如平常的作息等待我們用最短的時間給您真相。<br>
            國華女人徵信依據業務多年的經驗提供下列幾點給各位做個參考：<br>
            <ul class="list">
              <li>找各種徹夜不歸或晚歸的理由（如：開會、加班）</li>
              <li>行為舉止怪異（如：態度冷漠、動不動就說要離婚或分手）</li>
              <li>接到常有不出聲電話、電話費明顯暴增、手機有曖昧之簡訊</li>
              <li>接到某人來電時藉故迴避談話</li>
              <li>性愛次數明顯減少</li>
              <li>開銷花費明顯增加</li>
              <li>轎車上或身上有來歷不明的物品</li>
              <li>耳聞同事或友人所見之情形</li>
              <li>常外出說要去 7-11 等便利商店買東西，其實是為了掩飾打電話給情人</li>
              <li>突然變得很溫柔，變得很容易妥協 ! ( 彌補偷情的愧疚心理 )</li>
              <li>對於你的問題回答的很模糊，甚至問太多就會動怒</li>
            </ul>
            &emsp;&emsp;以上幾點是一般外遇徵兆常見之情形，也十之八九有問題；其實另一半只要有外遇，不管如何謹慎小心，一定都會有破綻。但是每個人常犯的錯誤就是打草驚蛇、沉不住氣或是杯弓蛇影、無中生有，所以在沒有確切證據前，沒有辦法小心沉著，是許多人無法忍耐的；我們的專業不僅只是告訴您真相，而是可以在您的另一半確實有出軌行為的蒐證與建議，重要的是如果另一半真是沒有外遇行為，也不希望因為懷疑造成家庭破碎。
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-1" size="lg" centered title="外遇蒐證">
        <div class="d-block">
          <p class="qa-p">
            &emsp;&emsp;在現今社會中，無論是男人或是女人，在外遇的時候都會給自己很多藉口，甚至大部分的偷情者都有種”家花不如野花香”的觀念，讓不知情的另一半因此而深受傷害；因為絕大多數的人，在偷情以後，就忽略了原本一直陪在身邊的伴侶。<br><br>
            &emsp;&emsp;經過了外遇調查後，若證實對方已經有外遇劈腿的情形，後續的處理，需請您沉住氣，因為接下來的就不是像吵架一樣的一吐為快，如何在這個時候佔到優勢的地方，就是您需要最專業的我們的原因，以下大致分為三種方式：<br><br>
            <span style="color:red; font-size:20px;">感情破壞：</span><br>
            針對第三者所研究出來的方式，配合狀況，使第三者或被查人在"不知情"的情況下自然的分手，並徹底不再往來，使夫妻兩人之間感情的障礙移除，但是想要恢復以往美滿和諧的家庭生活，有時候並不只是第三者的原因，在這方面，國華女人也可以提供最完整、實在的方式，來讓您了解問題的癥結點在哪，而達到完美的結局。<br><br>
            <span style="color:red; font-size:20px;">感情挽回：</span><br>
            &emsp;&emsp;如何挽回先生太太的心？需依當時情況而言，之中技巧萬千，非筆墨所能形容；主要需針對客戶狀況，主要為選用科技種類與心理技巧、打動人心......等，以達到挽回之目的。<br>
            其實女人外遇是最困難且挽回機率很低的，相對於男人外遇大多是肉體外遇，女人卻是精神外遇先開始才會到肉體外遇，但是女人變心並不快，可是只要一變心就會變的冷血！如果女人真的外遇，男人真的要沉靜心裡先想想看到底是什麼讓你們的感情走了樣；讓她覺得被忽略？覺得你不再呵護她？不在乎她的感受、把她捧在心上？女人永遠是希望自己是被捧在手中的寶。<br><br>
            &emsp;&emsp;節日送個小禮物、開口說愛她，甜言蜜語都不可少！不要怕肉麻，如果你真想挽回，就趕快去做吧！接著就是做些讓她感動的事，邊觀察她對外遇對象是真的動心，還是一時的慰藉。<br><br>
            <span style="color:red; font-size:20px;">捉姦在床：</span><br>
            &emsp;&emsp;這是對已經精疲力盡，不想再對這段感情付出的人的最佳途徑，也可以徹底和另一半斷絕關係，並且對於小孩監護權及贍養費求償方面有極大勝算。<br>
            相反的這也是斷絕第三者跟另一半糾纏不清的最好方法之一，不要以為這樣就會撕破臉以後夫妻感情會更糟，當然事前這些都會經過我們的建議跟當事人的決定情形來斟酌用何種方式來辦理。<br>
            &emsp;&emsp;然而這最後行動前所運用的精密儀器和專員使用的場合方式，都會讓委託人清楚的明白我們一定是用最符合您的方式，讓不管是任何一方都不會受到不必要的傷害。<br><br>
            提供幾種外遇方式作為參考：<br>
            <ul>
              <li>網路戀情......</li>
              <li>大陸包二奶......</li>
              <li>生意往來......</li>
            </ul>
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal2"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-2" size="lg" centered title="大陸及海外案件">
        <div class="d-block">
          <p class="qa-p">
            &emsp;&emsp;對於大陸二奶問題特別重視，針對台商問題除有專業人員派遣之外，並與當地的業者配合加上精密的蒐證儀器，針對案情無往不利，所有的姦情絕對讓您寮如指掌，並且及早預防。<br><br>
            &emsp;&emsp;大陸地區一直是台商的最愛，近來由於政府對大陸政策鬆綁，使得台商更是有如脫韁野馬，每個人都想到大陸去發展，連一般人,尤其是男性，更是把大陸當作【觀光勝地】【出國考察】的第一順位。原因無他，因為大陸地區土地廣大，女人多，價錢便宜（在台灣酒店消費一次，約在大陸可以消費七天，並且可以帶出場），而且大陸女性對台胞情有獨鍾，深深了解如何施展手腕來擄獲台商的心、並非是台灣一般風化場所可比較的。使得每每去過大陸之友人，剛回台又想再去一次。更遑論居住在大陸的台商或台商員工，老婆不在身邊的甘苦，絕非一般人能體會，所以在當地尋找溫柔女子來慰寂反而是一種非常正當的情形,，有這樣，旁人還以為是你有問題呢！當然，妳也不要以為妳搬過去一起夫唱夫隨就可以預防這種事情的發生，據我們調查的案件顯示近3成外遇問題都是夫妻一起過去創業，創業危堅時夫妻同心協力共創美好的未來，有錢時老公就開始作怪，開始往外發展。案情輕者，往往只是拈花惹草。重者，搞得妻離子散，更甚者，除了【錢】進大陸外，事業也跟著走下坡，瀕臨破產的情形更是屢見不鮮，為人妻者不可不防。<br><br>
            <ul>
              <li>市場調查徵信 - 產品產銷地點、行銷管道等等。</li>
              <li>產品仿冒徵信 - 專利、產品商標之侵權、仿冒，生產公司、地點的追查。</li>
              <li>信用調查徵信 - 公司行號或個人生意往來間之信用程度。</li>
              <li>動產、不動產鑑定 鑑定個人及公司動產、不動產狀況。</li>
              <li>各種疑難徵信 - 協助個人或周遭之人、事、物的困難解決。</li>
              <li>外遇徵信-俗稱通姦的調查。</li>
              <li>個人素行 個人行為操守的偵查。</li>
              <li>婚前徵信 男女朋友交往的忠實性。</li>
              <li>尋人查址 如倒債、捲款潛逃、離家出走（逃妻、逃夫、子女等）。</li>
            </ul>
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal3"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-3" size="lg" centered title="外遇抓姦">
        <div class="d-block">
          <p class="qa-p">
            &emsp;&emsp;真正優良的徵信公司會依照正常合理的抓姦過程來處理一個專案，因為要符合行使訴訟之人要負舉證之責的律法邏輯。換句話說必需採證到正在進行式的姦情才能在法律的觀點上去維護委託人的全面性立場。如果婚姻經過專業的我們蒐證與審慎評估後，無挽回之必要時，就只有用抓姦的方法，方可解決問題並保障自己與孩子的權益；並且我們會將通姦者對受害的委託人造成的傷害降到最低，這才是專業應有的表現。<br><br>
            &emsp;&emsp;【偷情】【外遇】【抓姦】【婚外情】【養小狼狗】【包二奶】這幾個字詞常態性地出現在電視新聞和報章雜誌裡，甚至媒體也經常的運用這些題材拍成電影或綜藝節目探討的主題。現代人對自我個體有著非常強烈的意識抬頭，忠貞也在現代社會的感情上讓人非常重視，「一夫多妻」和「一妻多夫」這種事情已不被通融允許，法律上也有嚴格的條例規範，但也因為不再光明正大，所以才會有所謂的外遇、偷情、抓姦、婚外情甚至在兩岸三通後更有包二奶的事情頻傳；甚至在價值觀扭曲的現今，有許多人為了這種作壞事的叛逆感而樂此不疲。<br><br>
            &emsp;&emsp;國華女人徵信在提供相關諮詢解答到蒐證之後，雖然台灣人傳統”勸和不卻離，家和萬事興”的道德觀念，但是每一段感情，並不是自己委屈求全就可以讓家庭幸福美滿，但是佛也有發火的時候，我們會給您最專業、貼心的建議，讓您在對的這一方取得優勢。
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal4"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-4" size="lg" centered title="家庭暴力">
        <div class="d-block">
          <p class="qa-p">
            &emsp;&emsp;國華女人徵信，專為解決家庭暴力，讓您及您的家人走出家庭暴力的陰霾；同時讓您瞭解什麼是家庭暴力防治法，此法是如何規範及保護您的權益；在現今社會中，人民生活水準提昇，所得卻不成比例的比比皆是；民眾面臨的生活壓力持續提昇，進而造成無辜的家人成了發洩的對像，引發社會上多起的家庭暴力事件。<br><br>
            &emsp;&emsp;家庭暴力影響堪大，它是造成家庭破碎的主要原因之一，更是影響孩子成長階段的心靈發展；真相真探徵信家暴防治部提供家暴相關新聞，讓施暴者瞭解家庭暴力對家人所造成的傷害有多大；是否應該冷靜想想，懸崖勒馬。家庭暴力防治法規，讓受害者勇於對外求助，免於家暴傷害。<br><br>
            <span style="font-size:25px;color:red;">注意事項</span><br>
            <ul class="list">
              <li>簡單清楚的流程，您可明白所有的作業程序，若有任何疑問本公司將有專人為您解答</li>
              <li>本公司有常年配合的律師顧問，您可以放心將此重任託付給我們</li>
              <li>對於客戶的資料，本公司負有保密之責，不會將客戶資料隨意散播</li>
              <li>一旦結案，本公司將會把所有資料銷毀，以確保您我權益</li>
            </ul>
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal5"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-5" size="lg" centered title="婚前徵信">
        <div class="d-block">
          <p class="qa-p">
            <span style="font-size:25px; font-weight:600;">背叛與不忠是人類追求刺激的誘因</span> <br><br>
            &emsp;&emsp;背叛與不忠是人類追求刺激的誘因,當你遇到人生中不如意的事情時,在這裡我們打破傳統,給你們迅速又專業的徵信,你只需要高枕無憂的在家中等待我們給您的答案。你真的了解你身邊的另一半嗎 ? 為了你的終身幸福婚,你記得幫身體做健康檢查,怕身體出狀況,給自己多一點保障。國華女人婚前徵信，絕對是你披上白紗前必做的幸福健診！<br>
            &emsp;&emsp;有鑑於此,只要委託者提供被查人之基本資料，國華女人婚前徵信，即可提供以下六大部分,作為日後交往或結婚之參考。<br>
            <ul>
              <li>家庭背景投資事業有無誇大不實</li>
              <li>家族有無先天性疾病或隔代遺傳之精神疾病</li>
              <li>偵查是否與前男 ( 女 ) 友藕斷絲連以及劈腿之惡行</li>
              <li>蒐集有無前科或不良之紀錄</li>
              <li>個人信用調查及銀行往來之紀錄</li>
              <li>學經歷是否虛假偽造</li>
            </ul>
            <span style="font-weight:600;">國華女人婚前徵信</span>調查您所要調查的對象，無論男女情人或者是準新人，為您提供相關法律諮詢或心理輔導等多項服務，並根除不肖份子以婚姻方式之名行詐騙之實，造成社會治安問題及人性道德之淪喪，亦可避免自身無端成為他人婚姻之第三者。我們提供您最溫柔的建議以及百分之百的忠誠服務！保護您的個人權益，更是我們永不退讓的堅持。<br><br>
            <span style="font-size:25px; font-weight:600;">人的一生,往往需要做出許多重要的決定</span><br><br>
            &emsp;&emsp;人的一生中，往往需要做出許多重要的決定，不論事情的大小；婚姻使得兩個相愛的人做出決定，但我們無法正確的得知所嫁或所娶的是不是用真實的那一面對著您？往往我們都會尋求一種模式，交往過一陣子在愛情的喜悅與甜蜜中，自以為他 ( 她 ) 是我的真命天子、 Mr.right ？但真的了解以及認識我們的另一半嗎？<br>
            &emsp;&emsp;所以為何現今社會的婚姻所維繫的時間越來越短？人們當初眼中的佳偶，卻又往往以怨偶收場，不能老是拿那一套個性不合，因不了解而結合又因了解而分離吧！我們常聽到未雨綢繆，這叫事先規劃的風險，評估管理，投資理財會想到、但為何在人生婚姻上不做些評估及管理呢？屆時再來個吵鬧分離人才兩失！<br>
            &emsp;&emsp;一個婚姻是兩個人的結合，我們自以為像童話故事般的王子與公主，從此過快樂無憂的日子，孰不知這才是故事的開始。但這個故事是要喜劇收場還是歹戲拖棚往往看雙方有無用心，干擾一段婚姻能否美滿，在於雙方有無正確了解彼此？<br>
            &emsp;&emsp;因為任何一件小事甚鉅，比如雙方的家庭背景 ( 如前科、已婚、通緝 ) ；財務狀況 ( 如信用、資產 ， 有無遺傳病，在感情尚未真正投入埋下往後的不定時炸彈前，做好婚前的徵信讓用心經營的人種下良緣得到善果，祝福全天下真心相愛的人，永浴愛河！<br><br>
            <span style="font-size:25px; font-weight:600;">你真的了解你身邊的另一半嗎？</span><br><br>
            &emsp;&emsp;你真的了解你身邊的另一半嗎？ 你怕取錯妻、嫁錯郎嗎？ 怕身體出狀況，在走上紅毯前，為了你的終身幸福， 當然更應該替您的婚姻也把把脈！ 給自己多一點保障， 為什麼命運差那麼多...？為什麼最初的理想會崩潰...？為何愛情會被生活磨蝕而幻滅...？<br>
            &emsp;&emsp;時代的變遷，新台灣人的觀念隨之而改變，道德及兩性間的認知或有所不知，導致離婚、家庭暴力不斷發生，進而影響下一代的教育，導致製造社會上的問題！ 其實戀愛是抽象的，婚姻卻是現實的，會有許多衝突摩擦！所以，愛情是沒有永久保證書的！<br>
            &emsp;&emsp;婚前徵信的重要性： 讓你透視另一半的本質，可提供你不了解的另一面，可供參考，作為你走進婚姻的心理依據！ 健診內容<br>
            <ul class="list">
              <li>感情忠實度</li>
              <li>家世背景</li>
              <li>學經歷、經濟狀況...</li>
              <li>動產、不動產</li>
              <li>另一半家族病史</li>
              <li>交友狀況及前科紀錄</li>
            </ul>
            國華女人婚前徵信&emsp;絕對是你披上白紗前必做的幸福健診！<br><br>
            <span style="font-size:25px; font-weight:600;">婚前徵信的重要性更甚於健康檢查！</span><br><br>
            &emsp;&emsp;人的一生中，不管大小事，都不停的在做選擇賭注。 對男人或女人而言，最大的賭注就是婚姻！押對的人，一生幸福快樂；押錯的人，情路坎坷波折不斷，甚至遭到暴力相向以至於家破人亡。 這其中當然有環境或經濟上的因素，難以克服；但也有一部份是因為婚前對於交往對象的了解不夠，而造成終身的遺憾。<br>
            &emsp;&emsp;以前或許你沒有能力及管道去徹底了解你的交往對象，也許是對方的甜言蜜語而沉溺在幸福浪漫的假象裡，而不敢去面對現實。然而婚前徵信就如同婚前健康檢查一樣重要！ 透過健康檢查可以幫您發現對方是否有家族遺傳病史或其他傳染疾病，在個人可接受的範圍內，或許您會願意接受陪伴您的另外一半一路走下來，哪怕是身體有缺陷的人，因為他 ( 她 ) 是坦白的，能夠讓我們眼睛看的見。<br>
            &emsp;&emsp;婚前徵信的重要性更甚於健康檢查！ 有人因為貪圖對方的一切或家產，交往之初，對當事人百依百順，百般奉承的表現出一副忠誠老實模樣，一旦結婚後慢慢地露出真面目侵占當事人的一切或家產；若配偶不從將以百般虐待，甚至於暴力相向，逼迫 ( 他 ) 她就範以及離婚收取贍養費收場。 也有人交往的對象是走私、販毒者前科累累而不知，等到感情全部投入後，才發現真相後悔已來不及了；這麼重要的終身幸福，您能不重視嗎？ 國華女人徵信可幫您做完整的婚前調查，為您的幸福把關。<br><br>
            <span style="font-size:25px; font-weight:600;">結婚就是兩個人帶了不同的期待走進禮堂</span><br><br>
            &emsp;&emsp;有人說：「結婚就是兩個人帶了不同的期待走進禮堂」所以婚後有人吵鬧有人白頭偕老。台灣的結離婚的比例，從前些年的每十對有一對到近年的的每十對就有七對，數字的飆漲不禁令人咋舌，但相對也讓我們警覺出婚前徵信的重要性。<br>
            &emsp;&emsp;愛情的迷思有時讓人無法理性思考，但透過業者的協助，精準的搜尋與客觀的分析，希望能讓您在婚姻的路途上走的更踏實、甜蜜；而且若一但有不利於你的危機查出，你才能理性的去解決 ( 還要不要繼續這段關係；要不要懸崖勒馬 ) 而重新設定您的標準。 離婚率不斷爬升，失婚男女越來越多，當一對對的佳偶變怨偶，當婚後的您必須面對喜歡酗酒、外遇 ( 偷情 ) 或者還要忍受家庭暴力、沉重負債，小孩的監護權及雙方家長的問題，林林總總的痛苦之後，何不讓我們防範於未然，人人都需要有危機意識，有時靠自己的眼光是有限的，求助於外力並不丟臉，而是建立信心的第一步，話說知己知彼，讓我們運用智慧來換取雙贏的美滿婚姻。真正的原因就是這麼簡單，但人們總是被誤導，生活中許多解決事情的辦法都很容易，但要找出這個辦法卻很不容易。<br><br>
            <span style="font-size:25px; font-weight:600;">您所選的對象真的值得您託付終生嗎?</span><br><br>
            &emsp;&emsp;戀愛是那麼甜蜜美好，因為那只是兩個個體的心靈交流、相處。但婚姻是現實的它是兩個家庭、經濟，兩個人的生活的結合。然而愛情是盲目的，熱戀中的男女往往看不清事實的真相，在現成離婚率高的社會，更增顯婚前徵信的重要性。<br>
            &emsp;&emsp;「婚前徵信」有如選舉前對候選人的徵信，也是抓住幸福的機會，它可以準確地為您倆的愛情開出診斷証明，讓您全盤了解將來的另一半，例如財產、信用、交友(包括對你(妳)是否專一)、健康(包括家族遺傳疾病)、學歷(是否造假)....。每個人尋尋覓覓,直到遇見自以為的真命天子(女)而開始交往戀愛，到論及婚嫁。但您所選的對象真的值得您託付終生嗎？婚姻是人生中重要的一環，可知若是選對了人，婚姻就是一輩子，選錯了人，就可以只有幾年，甚至只有短短幾天！ 經過了婚前徵信的調查，若是選擇正確，可以加深您對將來另一半的信任感；選擇錯誤，方可讓您看清真相，是要繼續？還是放棄？有了「婚前徵信」，讓您做出最明智的選擇，再也不怕娶錯妻、嫁錯郎。<br><br>
            <span style="font-size:25px; font-weight:600;">婚前徵信讓您遠離『危險情人』與『分手暴力』生嗎?</span><br><br>
            &emsp;&emsp;自殺殉情、潑硫酸、情殺…等『分手暴力』事件頻傳，熱戀中的男女往往忽略如何觀察自己心儀的對象是否是『危險情人』，而一頭栽進未來可能會遭遇『分手暴力』的未知風險中。 先前白曉燕案獲判無罪的關係人張智輝女友遭其殺害事件，就是分手談判破裂慘遭情殺的典型個案，如果能在交往中小心觀察，委婉、漸進式疏遠的分手，應該可以避免生命遭受威脅甚至喪命的分手暴力事件。 危險情人的特質（資料提供/高雄市立凱旋醫院）：<br>
            <ul class="list">
              <li>甜言蜜語、容易呈現迷人與自吹自擂的特質。</li>
              <li>缺乏長期知交的人際關係。</li>
              <li>低自尊、不關心自己的前途。</li>
              <li>與家人關係多為不和睦。</li>
              <li>不易對他人表示關懷。</li>
              <li>在校、工作期間行為紀錄不良。</li>
              <li>易生氣、衝動、與他人發生衝突。</li>
              <li>堅持伴侶凡事都需要應順從自己的要求。</li>
              <li>工作穩定度不夠。</li>
              <li>有酗酒、吸毒習慣。</li>
              <li>不易為自己的犯錯感到抱歉或自責。</li>
            </ul>
            &emsp;&emsp;一般熱戀交往的男女朋友即將步入結婚禮堂前，往往疏忽將來兩人世界會面臨的諸多問題，僅會規劃拍美美的婚紗照，細心一點的會去做婚前健康檢查及婚後財務、理財約定，『婚前徵信』可以調查交往中的朋友交友狀況、財務現況、個人信用、前科或法院判決、訴訟紀錄、抵押紀錄、學歷文憑是否屬實、日常品性及行為、家庭成員及相處情況等提供給準新郎、新娘做為評估終身伴侶之參考資料。
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal6"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-6" size="lg" centered title="婚姻諮詢">
        <div class="d-block">
          <p class="qa-p">
            <span style="font-size:25px; font-weight:600;">Q1:我先生經常半夜喝得酩酊大醉才回來，他說是為了公事，才出去交際應酬的，可是我很擔心他這樣殘害自己的身體，我該怎麼勸他呢？</span><br><br>
            &emsp;&emsp;熬夜、喝酒對身體的負面影響很大，而且這種影響往往不是立即展現，所以很多夜貓族、酗酒族就忽視了其危害性。先生經常在外熬夜喝酒交際，不但戕害自己的健康，也害妻子在家提心吊膽，更可能因晚歸減少了夫妻相處的時間，而引起婚姻危機，真的是得不償失！面對先生的熬夜應酬、喝酒交際的行為，妻子在表達不滿之前，不妨先將自己的關懷和擔心表現出來,讓先生知道妳真的很心疼見到他如此不經易地殘害自己。<br>
            &emsp;&emsp;然後在先生清醒時，靜下心來和他討論改善之道：是否一定要如此熬夜、喝酒,才能在公司裡受到重用和認同？有沒有其他方式可以取代？如果先生也有心改善這種情形，夫妻雙方將不難找出解決之道；但是，如果先生無意改善，甚至對這樣燈紅酒綠的生活感到樂此不疲，那麼，也許得借重婚姻諮商專業機構人員的協助，才能有所改進。<br><br>
            <span style="font-size:25px; font-weight:600;">Q2:我結婚五年了，有兩個孩子。最近我愛上了另一個男人，自己也變得喜歡打扮，彷彿又回到過去談戀愛時期；但另一方面，也對先生感到愧疚和有罪惡感，我該怎麼辦呢？</span><br><br>
            &emsp;&emsp;婚姻生活是現實的，它往往需要負擔柴米油鹽的責任，亦有照顧小孩、公婆的負擔，故生活就被一件一件瑣事堆積，而變得平淡無味。此時，若夫妻無法在生活中增添情趣，戀愛的虛幻感覺，即可能趁虛而入，使人有機會掉入外遇的陷阱中。<br>
            &emsp;&emsp;在上述事件中，因當事者處在兩個男人之中，故存在著很多的衝突矛盾，有必要在兩個男人中有所割捨。割捨前，不妨多給自己有段反省的時間，想想兩個男人對自己的意義與價值，及自己在割捨間之利弊得失。若自己一時無法釐清，尋求專業人士的幫助，亦是不錯的方式。 當然，在分離過程中，亦可能會遭遇割捨不下之難題或情緒空虛的害怕，此時若能在時空上配合，即拉遠和欲分離者的距離，並尋求一可信任之情緒之支持者，都是幫助自己走出外遇陰影的方式。當然若能告訴自己的「另一半」，並獲得「另一半」的支持，則在分離的路上，較不易顯得孤單和寂寞。 另外，先生於初知道此消息時，必定會暴跳如雷，但氣消之後，如果能協助太太度過此難關，相信對她將是最大的支柱。<br><br>
            <span style="font-size:25px; font-weight:600;">Q3: 我和我先生結婚五年了，我發現我們的性生活愈來愈平淡乏味，我擔心再這樣下去，一定會影響婚姻的關係與品質，該怎麼辦呢？</span><br><br>
            &emsp;&emsp;經驗分享：夫妻在一起生活愈久，就愈不懂得浪漫，也愈不懂得在生活中製造一些情趣，平淡和無趣成了每天必然的感受。雖然這樣的描述很寫實，但卻過於消極和無奈，其實每對夫妻是可以不必這樣過的。 夫妻結婚多年之後，對於另一半的新鮮感會漸漸減少許多，很多事無形中都變得例行而且單調，性生活也不例外。如果夫妻任何一方已意識到這種情形，不妨多回想以前戀愛或新婚時常去的場所、最喜歡的情境，然後在現實生活中重新營造類似的氣氛，讓彼此重溫舊、重燃愛火。當然你也可以將臥房的佈置稍做調整：燈光調柔和一點、擺些花飾、播放些浪漫的輕柔音樂………。當然，若環境、時間許可，換換做愛的地點，例如客廳的沙發上、渡假小木屋......，也是增進情趣的方法。總之，只要雙方願意肯花點心思營造一些情境與驚奇，日子就不會一直乏味下去的。<br><br>
            <span style="font-size:25px; font-weight:600;">Q4: 我是個晚睡晚起型的人，而我先生卻是早睡早起慣了，我們睡眠時間的不一致，讓我們彼此都很困擾。另外，我喜歡點小燈睡覺，但我先生卻堅持要關燈睡，為此，常常搞得不是我失眠，就是他無法入睡，怎麼辦呢？</span><br><br>
            &emsp;&emsp;兩個原本在不同家庭中成長的人，在結為夫妻之後，首先面臨到的衝突往往是生活習慣的不一致，有些夫妻甚至在新婚之夜就為了擠牙膏的小事而怒目相向。夫妻在遇到生活習慣不一致的情況時，千萬別想一定要對方改變，順應自己的方式，畢竟那些生活習慣已伴隨他數十年之久了。最好是採取折衷、雙贏的辦法，彼此都退半步，幾天的適應期之後，差不多可以習慣新的生活方式，夫妻雙方將不再為這些生活瑣事而大動干戈了。<br>
            &emsp;&emsp;晚睡的妻子和早睡的丈夫，不妨各自提早、延後一小時或兩小時就寢，如此一來，睡眠時間就可以逐漸調到一致了。而喜歡關燈睡覺的先生遇上喜歡開燈睡覺的太太時，何妨將臥室的燈掉，只留太太床頭邊的小燈，如此一來，對先生而言的確是關燈了，對太太而言也達到開燈的需求。<br><br>
            <span style="font-size:25px; font-weight:600;">Q5: 什麼是「家庭暴力」？遭受同居人虐待算不算是遭受家庭暴力呢？</span><br><br>
            &emsp;&emsp;依家庭暴力防治法第二條規定，家庭暴力指的是「家庭成員間實施身體或精神上不法侵害之行為」。 家庭成員」包括：
            <ul class="list">
              <li>配偶或前配偶。</li>
              <li>現有或曾有事實上之夫妻關係、家長家屬或家屬間關係者。（同居者即為事實上婚姻關係，即屬此類）</li>
              <li>現為或曾為直系血親或直系姻親。</li>
              <li>現為或曾為四親等以內之旁系血親或旁系姻親。</li>
            </ul>
            『身體上不法侵害』指的是：<br>
            例如虐待、遺棄、押賣、強迫、引誘從事不正當之職業或行為、濫用親權、利用或對兒童少年犯罪、傷害、妨害自由、性侵害…等。包括有鞭、毆、踢、捶、推、拉、甩、扯、摑、抓、咬、燒、扭曲肢體、揪頭髮、扼喉或使用器械攻擊等方式。<br>
            『精神上不法侵害』指的是：
            <ul class="list">
              <li>言詞虐待：用言詞、語調予以脅迫、恐嚇,以企圖控制被害人。如謾罵、吼叫、侮辱、諷刺、恫嚇、威脅傷害被害人或其親人、揚言使用暴力等。</li>
              <li>心理虐待：如竊聽、跟蹤、監視、冷漠、鄙視、羞辱、不實指控、試圖操縱被害人等足以引起人精神痛苦的不當行為。</li>
              <li>性虐待：強迫性幻想或特別的性活動、逼迫觀看色其影片或圖片等。</li>
            </ul>
            <span style="font-size:25px; font-weight:600;">Q6: 曾聽人提起『婚姻諮商』有助於改善夫妻之間的關係，當面臨家庭暴力時，不想離婚，如果去做婚姻諮商，會不會對問題有所幫助，到底什麼是婚姻諮商？</span><br><br>
            婚姻諮商不是化解夫妻衝突的萬靈丹,不是請輔導者幫助解決夫妻的問題,也不是達到某種仲裁效果,說明誰對誰錯,而是從實務的觀點,幫助雙方： <br>
            <ul class="list">
              <li>由不同角度看問題。</li>
              <li>培養更好的溝通能力與情緒控制能力。</li>
              <li>發展更親密的關係。</li>
              <li>正視困境、面對現狀、有效改善。</li>
              <li>了解差異、面對不同、尊重體諒。</li>
            </ul>
            &emsp;&emsp;好的諮商是提供夫妻更開放的溝通管道，協助雙方更有效的傾聽和表達，也能暸解問題所在，學習如何改善困境或是增加調適能力。如果希望婚姻諮商能對夫妻問題有所幫助，那麼「及早發現，及早治療」的觀念十分重要，在夫妻相處有問題、夫妻溝通有困難時，就可以尋求協助，不一定要等到已經有暴力發生時才去重視。
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal7"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-7" size="lg" centered title="專案尋人查址">
        <div class="d-block">
          <p class="qa-p">
            &emsp;&emsp;本公司擁有豐富的尋人經驗，範圍除了台灣本島外，海外地區涵蓋中國大陸、越南、泰國、新加坡、美國、日本等地區。本公司除了豐富的經驗之外，並有良好的外界關係，加上顧客若提供的正確諮料（如可以提供如室內電話、行動電話、戶籍、或身分證字號等，均可以透過這些來查址或查詢其他諮料，其他國家亦可）以達到在最快最短的時間之內找到想要找的人。<br><br>
            &emsp;&emsp;至於一般客戶所最擔心的價格問題，本公司收費保證公道合理，況且可以撥打免付費電話做諮詢，或許您只要花一點點時間撥個電話，可以得到最大的效益喔。不用懷疑，我們絕對有較阿亮更高級的本事去替您找尋你所要找尋的人。我們全省分佈的資源將會替您服務，相信我們的專業，將會帶給你想要找的。
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal8"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-8" size="lg" centered title="免費法律諮詢">
        <div class="d-block">
          <p class="qa-p">
            &emsp;&emsp;您在感情上有困惑嗎？這是一個女性意識抬頭的時代，女人已經不是弱者了，女人也可以是奧運金牌，也可以是政治首長，甚至家暴也不一定是女性受害，不過這些也只是您打開電視按鈕所看到的新聞，我們要讓您知道的，是當您在家庭內不被重視，無論是家暴（家庭暴力）、另一半外遇，”委曲求全”並不是您唯一可以做的，國華女人徵信無論在專業上貼心的建議、法律上實際的免費諮詢，都可以讓您感受到一整個無微不至的照顧。
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal9"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
    </section>
  </div>
</template>

<script>
export default {
  name: "Service",
  data() {
    return {};
  },
      methods: {
      hideModal() {
        this.$root.$emit('bv::hide::modal', 'modal-lg', '#btnShow')
      },
      hideModal2() {
        this.$root.$emit('bv::hide::modal', 'modal-1', '#btnShow')
      },
      hideModal3() {
        this.$root.$emit('bv::hide::modal', 'modal-2', '#btnShow')
      },
      hideModal4() {
        this.$root.$emit('bv::hide::modal', 'modal-3', '#btnShow')
      },
      hideModal5() {
        this.$root.$emit('bv::hide::modal', 'modal-4', '#btnShow')
      },
      hideModal6() {
        this.$root.$emit('bv::hide::modal', 'modal-5', '#btnShow')
      },
      hideModal7() {
        this.$root.$emit('bv::hide::modal', 'modal-6', '#btnShow')
      },
      hideModal8() {
        this.$root.$emit('bv::hide::modal', 'modal-7', '#btnShow')
      },
      hideModal9() {
        this.$root.$emit('bv::hide::modal', 'modal-8', '#btnShow')
      },
    }
};
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service2.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.lineder {
  font-size: 3rem;
  text-align: center;
}
.lineder::after {
  content: "";
  width: 8%;
  height: 1.5px;
  background: red;
  display: block;
  margin: 0 auto;
}
.warp {
  display: block;
  position: relative;
}
.item-t1{
  margin-top: 10px;
}
.item-content{
  margin-bottom: 20px;
}
.item-content .item {
  padding: 1rem;
  background: white;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
  text-align: inherit;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
.grid {
	position: relative;
	margin: 0 auto;
	/* padding: 1em 0 4em;
	max-width: 1000px; */
	list-style: none;
	text-align: center;
}
.grid figure {
	position: relative;
	float: left;
	overflow: hidden;
	/* margin: 10px 1%;
	min-width: 320px;
	max-width: 480px;
	max-height: 360px;
	width: 48%; */
	background: #3085a3;
	text-align: center;
	cursor: pointer;
}
.grid figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 0.8;
}
.grid figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.grid figure figcaption::before,
.grid figure figcaption::after {
	pointer-events: none;
}
.grid figure figcaption,
.grid figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.grid figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
  z-index: 99;
}
.grid figure h2 {
	word-spacing: -0.15em;
	font-weight: 300;
  font-size: 22px;
}
.grid figure h2 span {
	font-weight: 800;
}
.grid figure h2,
.grid figure p {
	margin: 0;
}
.grid figure p {
	letter-spacing: 1px;
	font-size: 35%;
}
figure.effect-romeo {
	-webkit-perspective: 1000px;
	perspective: 1000px;
}
figure.effect-romeo img {
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(0,0,300px);
	transform: translate3d(0,0,300px);
}
figure.effect-romeo:hover img {
	opacity: 0.6;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}
figure.effect-romeo figcaption::before,
figure.effect-romeo figcaption::after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 80%;
	height: 1px;
	background: #fff;
	content: '';
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(-50%,-50%,0);
	transform: translate3d(-50%,-50%,0);
}
figure.effect-romeo:hover figcaption::before {
	opacity: 0.5;
	-webkit-transform: translate3d(-50%,-50%,0) rotate(45deg);
	transform: translate3d(-50%,-50%,0) rotate(45deg);
}
figure.effect-romeo:hover figcaption::after {
	opacity: 0.5;
	-webkit-transform: translate3d(-50%,-50%,0) rotate(-45deg);
	transform: translate3d(-50%,-50%,0) rotate(-45deg);
}
figure.effect-romeo h2,
figure.effect-romeo p {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
}
figure.effect-romeo h2 {
	-webkit-transform: translate3d(0,-50%,0) translate3d(0,-150%,0);
	transform: translate3d(0,-50%,0) translate3d(0,-150%,0);
}
figure.effect-romeo p {
	padding: 0.25em 2em;
	-webkit-transform: translate3d(0,-50%,0) translate3d(0,150%,0);
	transform: translate3d(0,-50%,0) translate3d(0,150%,0);
}
figure.effect-romeo:hover h2 {
	-webkit-transform: translate3d(0,-50%,0) translate3d(0,-100%,0);
	transform: translate3d(0,-50%,0) translate3d(0,-100%,0);
}
figure.effect-romeo:hover p {
	-webkit-transform: translate3d(0,-50%,0) translate3d(0,100%,0);
	transform: translate3d(0,-50%,0) translate3d(0,100%,0);
}
.p-qa{
  margin-bottom: 0;
}
.p-qa:hover{
  color: slateblue;
}
.qa-p{
  margin-bottom: 0;
}
.list{
  margin: 0.5rem auto;
  list-style-type: decimal;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
  figure.effect-romeo p{
    top: 35%;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>
