<template>
  <div class="Woman17">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Woman
              <span class="cont">女人心事</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/woman_sample" class="">女人心事</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">人間蒸發，超愚蠢的分手手段啊！</h2>
              <h5 class="tl">水瓶鯨魚 轉載自Yahoo!奇摩時尚</h5>
              <div class="">
                <img src="../assets/images/Woman4.jpg" class="img-fluid mb-3" alt="超愚蠢的分手手段">
              </div>
              <p>
                <span class="cont-p">如果「人間蒸發」是一種逼婚手段？</span><br>                
                <span class="cont-p">我以為那還不如和什麼辣妹親吻上報？更有效果吧？</span><br><br>
                上個月，一個記者訪問我時，問了一個題目：「妳最討厭的分手方式是哪一種？」<br><br>
                記者繼續解釋，比如對方和妳說：「我們性格不合」或「我愛上別人了」，或者避不見面，就像人間蒸發。<br><br>
                我笑，快速回答：「這根本不用思考，當然是最後一種，厭惡。」<br><br>
                <span class="cont-p">「性格不合」，多半是禮貌性修辭學。</span><br><br>
                如果是真實狀態，你感覺和我性格不合，我也不會蠢到沒發現、沒看見、不理解，許多夫妻離婚理由都用這四個字，原因是禮貌，誰會在一起八年十年，才發現性格不合呢？<br><br>
                「我愛上別人了」，比較合常理，愛情經常突如其來，大家都喜歡嚐鮮，是人性。<br><br>
                就像那一年，當你愛上我、我愛上你，我們心底都很想和原先那一半這樣說，只是膽小、怯懦、尷尬無語，逼急了才脫口而出。<br><br>
                <span class="cont-p">「避不見面、不回答、不解釋」是最討厭的</span>，好像看推理小說，不知道誰是兇手；看驚悚電影，沒有結局；寫了一個企劃案交給上司，石沉大海；看一場球賽，轉播畫面突然斷訊；朋友跟妳說一個秘密，說到一半，突然不講了。<br><br>
                如果，一個人和另一個人曾經相愛過，分手卻採取人間蒸發手段，是很可恥的，我私人以為。<br><br>
                「人間蒸發」當然是一種形容詞，你我又不是通緝犯、詐欺犯、FBI特務人員、外星人，怎麼可能人間蒸發？電影看太多了嗎？呵。<br><br>
                記者好意補充：「或許他愛上別人、或許他對妳沒感覺了，又說不出口，又怕妳崩潰，只好逃避，希望讓妳慢慢發現。」<br><br>
                「妳的意思是，如果『人間蒸發』是一種逼婚手段？我以為那還不如和什麼辣妹親吻上報？更有效果吧？」我開起玩笑，意指香港倪震和周慧敏戲劇性的緋聞，記者一時愣住，說不出話。哈哈哈哈哈。<br><br>
                真是可愛的記者小姐啊，即使記者在工作崗位上希望問一些讀者渴望或上司設定的題目，記者仍是個真實女人。大家都歷經過情愛故事，或許運氣不好、多多少少各遇過「人間蒸發」的戀人，遭遇過不舒服的情緒，而，這情緒確實不舒服吧？<br><br>
                呵，我也是，我討厭在愛情裡使用「人間蒸發」這種手段的笨蛋。<br><br>
                說實話，與其說討厭，還不如說，我感覺好丟臉，我怎麼會遇到一個這麼膽小、不負責任、沒有危機處理能力的人呢？讓我受傷的是，我竟然還愛過他，我品味真遜啊，哈哈。<br><br>
                因此，某些時候，當我聽到好友討論一段戀愛，最後使用「人間蒸發」來形容，我都會同情她們或他們，如同再度面對自己的傷痕。<br><br>
                說起來，是這世代環境很詭異，每個書店的愛情教戰守則書籍充滿一堆：《把妹達人》、《如何擺平男人》、《戀愛的十種必勝攻略》...很少有書籍是《和平分手的100方法》或《人間蒸發，是最愚蠢的分手手段》，哈哈。<br><br>
                雖然，世界上沒有一個人，熱戀的時候，就會思考怎麼分手，未免觸霉頭。而好聚好散，確實是一件人生重要的學習課題。我私人以為。畢竟，一輩子只愛一個人的傳奇愛情，只能在經典小說才能看到，就算《紅樓夢》、《飄》都沒有這樣的劇情了。<br><br>
                <span class="cont-p">那麼，我們還要為「人間蒸發」的戀人找理由嗎？不，我們只是在為自己找藉口，舔舐傷口，讓自己心情舒坦一點。</span><br><br>
                自從有一天，我檢視我斑駁的傷痕，清楚體認這件事實，我就毫不猶豫把「人間蒸發」四字從我的辭典刪除。世間愛情，沒有「人間蒸發」這件事，只有愛或不愛，要或不要，能或不能，勇敢和膽怯。<br><br>
                可愛的記者小姐聽完，小小嘆息；我看著她，也嘆息起來。<br><br>
                我想，我們歎息的原因是，我們仍無法忘記我們曾經愛過的人，曾給與我們那些浪漫心動的美好情節，我們並且一點都不願意思考他們膽怯時、殘酷凌遲我們的過程。<br><br>
                這瞬間，我心想，若那些愛情中沮喪和心痛的記憶傷痕，也能隨時「人間蒸發」，有多美好啊。
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Woman17',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service5.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
  margin: 1rem auto;
}
.list-in{
  list-style: upper-roman;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>