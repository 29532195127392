<template>
  <div class="Woman18">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Woman
              <span class="cont">女人心事</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/woman_sample" class="">女人心事</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">如果我死了</h2>
              <h5 class="tl">H 轉載自Yahoo!奇摩時尚</h5>
              <p>
                當我還意猶未盡的拿著手機，和死黨妙妙吐著關於威廉的苦水時，威廉卻悠悠的從門口走了進來。<br><br>
                「你終於回來了…」我迅速的切斷了電話，將我的嘴唇貼上了威廉的嘴，我分不清威廉是抗拒或開心，但總之，我的舌頭已經伸進了他的口腔中。<br><br>
                我太愛威廉了。<br><br>
                自從半年前這名冷酷的俊男，因為女朋友人間蒸發離開他後，我便趁著他最脆弱的時候，擁有了他。<br><br>
                他平時不多話，但是做起愛來，卻熾熱的像顆火球。<br><br>
                如果要我選擇，我寧願將我這輩子的時間，都花在他的身體上，因為他的身體，簡直不是人間該有的東西。<br><br>
                我猜，他也很愛我。<br><br>
                他總是在床上賣力的取悅我，不管他自己滿身的汗水，濕透了床單。<br><br>
                只不過，從交往到現在，我就是覺得哪裏不對勁。作愛的時候，我覺得似乎有人在監視我，威廉和我說話的時候，我卻又覺得，他看著的人，不是我。<br><br>
                我一直心存懷疑，懷疑他在外面有女人。<br><br>
                因此，做愛做到一半時，我會忽然停止蠕動，然後睜大眼睛看著他，問他。<br><br>
                「你外面是不是有女人…」<br><br>
                威廉總是會在這個時候，充滿怒氣的看著我，冷冷的。「妳如果以後再挑這種時候，說這種話，我可能會把妳殺死……」<br><br>
                他越是說這種話，我不知怎地，卻越是興奮。<br><br>
                「你殺呀，如果我死了的話，我就什麼事情都會知道了……」我總是在說完這話之後，再開始繼續我身體下半身的律動。<br><br>
                而通常，威廉這時的表情，看起來很爽。<br><br>
                今天威廉在床上的反應，不如以往，似乎意興闌珊，也似乎精神不濟。這更令我心裡面的那團陰影，壟罩了更大的範圍。<br><br>
                我賣力的用我的嘴，去套弄他那逐漸膨大的下體，然而換來的卻是威廉他若有所思的放空表情。<br><br>
                我急著跳上了他身上，瘋狂的讓他抽動著，而這時我終於窺見他的眼角，流露出那一絲想要壓抑又忍不住的快感。<br><br>
                我總算把他的魂給帶回來了，我想。只不過，光只是這樣，還不是我要的，我要他更愛我，於是，我按照慣例在瘋狂的節拍後，停止。<br><br>
                「你外面，是不是有女人…」我喘息著，汗水將我的長髮黏住臉龐，我得意的，笑著。<br><br>
                威廉這次沒有怒氣，只有殺意。<br><br>
                「我說過，如果再挑這種時候，說這種話，我可能會把妳殺死……」這是威廉說過不下數十次的台詞。<br><br>
                「你殺呀，如果我死了的話，我就什麼事情都會知道了……」而我也原封不動的回了他。<br><br>
                只不過，今天有點不同。威廉還等不及我再度扭動我的腰，便伸出了雙手，緊緊的掐住了我的喉嚨。<br><br>
                「你…殺呀……如果我……死了」我認為威廉想要和我玩玩不同的橋段，因此我依舊說著同樣的話，沒想到這時候威廉手上的力氣，竟然更重了。<br><br>
                「如果……我……斯………」威廉的力氣大得我說不出話來，我的心裡開始感到恐懼，難道說，我的懷疑猜忌，真的逼得我的男人崩潰，而失手……??<br><br>
                「………」我開始想要抵抗的時候，已經來不及了，沒多久我就感到我的眼珠凸出，舌頭也微微的露出嘴巴外，心跳越來越慢以致停止，如果現在照鏡子的話，應該是帶點青色的臉色吧。<br><br>
                不會吧，我真的死了!?就這樣不到幾分鐘內死在了威廉的手上。我心念電轉，似乎很多事情都出現了點頭緒，關於他的前女友，以及威廉的冷酷…<br><br>
                威廉光著身子，一手抓起了我的左腿，在地板上拖著，他的神情，就像是拖著垃圾袋要往垃圾車丟去一般的平常，也不理會我的頭在地板上拖著，撞擊到什麼地上的突起物。<br><br>
                我被威廉丟到了他書房內的更衣間，木製的門被威廉一把關了起來，以威廉的生活習性，我看，我就算在這邊待了一百年，也不會被任何人發現吧。<br><br>
                不過，這裡是我的家，雖然我離鄉背井在外面租房子，但是同事或是朋友應該也會來找我吧。<br><br>
                不過，似乎這樣也不太妙，如果威廉發現有人知道他殺了我的話，可能連那個知道秘密的人，也會一併被威廉殺死吧。<br><br>
                屍體是沒有時間觀念的。不知道過了多久，我發現有人來了。有人按了電鈴，有人開了門，有人說了話。<br><br>
                「珍妮呢??」隔著門我聽得出來，那是妙妙的聲音，我心裡只能大喊。「妙妙，快走，快走……」<br><br>
                我沒有聽到威廉回答的聲音，可能不知道隨口說了什麼謊話吧。又沉靜了一陣子，我聽到書房的門被打開的聲音，接著，更衣間的門被拉了開來，我的屍體整個人頭重腳輕的摔落了出來。<br><br>
                開門的人是妙妙，她一臉驚慌，我更擔心的是站在她身後的威廉。<br><br>
                「你把她殺了??」妙妙尖聲叫著，我心裡暗自嘲笑她的愚昧，還須要問嗎??還不快離開!?!<br><br>
                沒想到妙妙轉身就是一巴掌打在了威廉的臉上。<br><br>
                「都還沒拿到她存摺裡面的錢，你這麼衝動幹麻??」<br><br>
                「去拿菜刀來……」妙妙唆使著威廉，很快的離開了書房，隨後我又聽到腳步聲從遠而近的傳來，兩人的手上各自拿了一把菜刀。<br><br>
                我想起了我最愛對威廉說的話。<br><br>
                「如果我死了的話，我就什麼事情都會知道了……」 
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Woman18',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service5.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
  margin: 1rem auto;
}
.list-in{
  list-style: upper-roman;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>