<template>
  <div class="Woman12">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Woman
              <span class="cont">女人心事</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/woman_sample" class="">女人心事</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">簡短是愛情的平安險</h2>
              <h5 class="tl">作家:吳淡如</h5>
              <p>
                男人或女人的喋喋不休，無論為了什麼理由，常成為關係僵化的「快速冷卻劑」。<br>
                據說從前有一個叫保爾﹒尼森的詩人，在他妻子的墓誌銘上寫了一首詩：<br>
                此處，有個女人在此長眠，生前極愛說話的她，如今已進入永恆的寧靜。<br>
                身為丈夫的我，請求走過此地的人們，盡量放低聲音說話。否則聒噪的她可能再度甦醒。<br><br>
                男人都怕喋喋不休的女人嗎？答案百份之兩百是肯定的。喋喋不休的只有女人嗎？當然不！只不過，男人和婦人喋喋不休的場合、方式和理由並不一樣。<br><br>
                一般來說，開會時男人比女人愛以喋喋不休來展示功勞，而女人比男人容易拿起電話講個沒完沒了，不管在辦公室還是在家；女人似乎比較容易對孩子把一句話就可以講完的，講成了十句，比較喜歡解釋一大串理由，說明她要做或不要做哪件事。<br><br>
                家庭中，丈夫的長篇大論通常是用來「說理」的，妻子的長篇大論則是用來「表達情緒」的；但女人常不愛聽男人動不動就說理，男人也不愛聽女人以抱怨表達情緒。兩性名著《男人從火星來，女人從金屋來》的作者葛瑞（John Guy）博士說：「女人在難過時，並不希望聽到一大串理由說明她為何不該難過；男人也並不希望聽到一大串理由，要他滿足她的要求。」喋喋不休，會變成「你關心，他煩心」，或讓自己覺得「狗咬呂洞賓，不識好人心」。<br><br>
                與對方的利害關係有關，話說得越多，越會遭到反持。男人或女人的喋喋不休，無論為了什麼理由，常成為關係僵化的「快速冷卻劑」。<br><br>
                多數女人難過時，她需要他專注地傾聽，而不是他斬草除根、迫不及待地解答，那會讓她覺得雪上加霜。女人以不太直接的語句提出要求時，也可能會激起男人的反感。在一般婚姻生活的對話上，有很多實例，比如：女人不直接說：「今晚我們出去吃飯嗎？」而會說：「我今天做了一大堆事，累都累死了，實在沒力氣做飯，你今晚總該可以帶我吃飯吧！」萬一她有事，沒法到保姆家階孩子，她不會簡短的問：「你去接孩子好嗎？」可能會以一大串牢騷開頭：「每一次都是我去接，你是孩子的爸，偶爾去接一次，沒問題吧？」就算男人樂意，聽到這樣的喋噪不休，也會變成心不甘情不願。<br><br>
                「簡短乃極智之靈魂」——就兩性之間而言，要求、勸告、爭論時，簡短也是愛情的平安險。喋喋不休，只會使人的耳朵因惰性而遺失重點，而不該進去的話，則鑽進心裡煽風點火。<br><br>
                平常愛喋喋不休的人講的話，一定不受重視，因為，別人一定認為你會再講一遍。
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Woman12',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service5.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
  margin: 1rem auto;
}
.list-in{
  list-style: upper-roman;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>