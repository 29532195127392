<template>
  <div class="Law1">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Law
              <span class="cont">法律常識</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/law" class="">法律常識</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  法律內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">丈夫大陸包二奶又生子，元配有何權利？得拒絕該名小孩入籍嗎？</h2>
              <ul class="list">
                  <li>隨著兩岸經貿及民間往來日益密切，兩岸間因經貿及人民間往來所衍生之問題即日益增加，我國為因應兩岸未來更緊密之往來關係，乃於八十一年公布「台灣地區與大陸地區人民關係條例」，以作為未來處理兩岸人民相關權義之依據。</li>
                  <li>有關先生在大陸「包二奶」之情形，在現實社會上確實有數不完之個案，惟大多數在台灣的妻子面對在大陸先生「包二奶」之聽聞，總因國情及距離上之隔閡等因素而無法証實，縱擬委請徵信社查訪，蒐集到相關物証，但日後是否可直接作為訴訟上之証據，亦值得探究。因而，在台灣的太太面對類似之情況時，確實處於相當為難而無助之處境。</li>
                  <li>一般所稱「包二奶」乃係指在台灣仍有婚姻關係之男士，在大陸與大陸女子同居，並提供該名大陸女子一切生活及經濟上之需而言。至於，此種「包二奶」之行為，在我國法律上即會構「通姦」之要件，而夫妻一方對於他方之「通姦」行為，依我國法律隱可以依刑法第二三九條之規定提出通姦之告訴外，並可依民法第一○五二條第一項第二款之規定請求法院判決離婚所生之損害賠償及贍養費。<br>但依現行兩岸分治、我國司法管轄權尚不及大陸之情勢而言，縱在台灣的妻子確實掌握先生在大陸「包二奶」之証據，要對先生及該名大陸女子提出刑事通姦之告訴恐怕有難度，但能對先生提起民事離婚及損害賠償之訴訟。</li>
                  <li>本案元配知曉先生「與人通姦」為由提出離婚訴訟，恐怕日後丈夫在出庭時會否認與大陸女子同居並生一子，因而，若現在即擬提起離婚之訴，似應先讓先生坦承與大陸女子同居之內容加以錄音，並另蒐集先生在大陸與該名大陸女子相處之相片、或將兩人電話中親密的對話加以錄音，或請曾在大陸看過兩人同居之朋友或鄰居出庭作証，如此，該離婚訴訟應較容易成立。或者更容易的，元配可以等待丈夫將該名小孩帶回台灣並入戶籍後，以該名小孩作為先生與該名大陸女子通姦之証據，再提起離婚訴訟，如此不但可以省去蒐集証據對麻煩，且該離婚訴訟應較容易成立。</li>
                  <li>至於，先生若堅持要將該名小孩帶回台灣認祖歸宗，依現行民法規定，認領該名小孩，僅需丈夫單獨辦理即可；至於，元配並無拒絕之權利，而該名小孩經認領後，即視同為婚生子女，亦即以後該名小孩仍可對先生名下之財產加以繼承。至於，該名小孩雖經孫強認領，惟在法律上元配與該名小孩並無任何法律上之關係，亦即日後該名小孩對於元配妻子名下之財產亦無繼承之權利。</li>
              </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Law1',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service4.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
    margin: 1rem auto;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>