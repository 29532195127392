<template>
<div class="container-fluid">
   <div class="row">
      <div class="intro">
         <div class="viewbox">
            <vue-flux :options="vfOptions" :images="vfImages" :transitions="vfTransitions" ref="slider">
               <template v-slot:preloader>
                  <flux-preloader />
               </template>
            </vue-flux>
         </div>
         <div class="viewtext">
            <div class="view-box">
               <div class="in-view">
                  <h2>女人徵信有限公司</h2>
               </div>
            </div>
            <div class="out-view">
               <p style="margin-bottom:0;">Woman Peivate Detective</p>
            </div>
            <!-- <div class="mt-5">
               <p>24小時免付費專線：
                  <a href="tel:0800363333" class="phone">0800-36-3333</a>
               </p>
            </div> -->
         </div>
      </div>
   </div>
</div>



</template>

<script>
import {
   VueFlux,
   FluxPreloader
} from 'vue-flux';

export default {
   components: {
      VueFlux,
      FluxPreloader
   },

   data: () => ({
      vfOptions: {
         allowFullscreen: true,
         autoplay: true,
         autohideTime: 2500,
         delay: 5000
      },
      vfImages: [ require("@/assets/images/1.jpg"),
                  require("@/assets/images/2.jpg"),
                  require("@/assets/images/3.jpg")],
      vfTransitions: [ 'fade' ],
      vfCaptions: [
         'Caption for image 1',
         'Caption for image 2',
         'Caption for image 3',
      ],
   }),
}
</script>

<style scoped >
   .intro{
      display: block;
      height: 100vh;
      width: 100vw;
      position: relative;
   }

   .viewbox{
      width: 100%;
      height: 100%;
      background: white;
   }
   .vue-flux{
      display: block;
      height: 100% !important;
      width: 100% !important;
      /* -webkit-filter: grayscale(100%);
      filter: grayscale(100%); */
   }
   .viewtext{
      position: absolute;
      width: 100%;
      top: 40%;
      left: 0;
      padding: 0 20%;
      z-index: 10;
   }
   .view-box{
      text-align: center;
      background: white;
      padding: 0.5rem;
   }
   .in-view{
      background: teal;
      color: white;
   }
   .in-view h2{
      font-size: 3rem;
      padding: 50px 0px 50px 0px;
      margin-bottom: 0;
   }
   .out-view{
      text-align: center;
      margin: 0 auto;
      width: 50%;
      background: white;
      color: teal;
      padding: 25px;
      margin-top: -30px;
      font-size: 1.5rem;
   }
   .phone{
      color: red;
      text-decoration: none;
      font-size: 40px;
   }
   .phone:hover{
      color: blue;
   }
   @media (max-width: 767px){
      .viewtext{
         padding: 0 4%;
      }
      .out-view{
         width: 61%;
      }
   }
   /* @media(max-width:1025px){
      .intro{
         height: 95vh;
      }
      .intro::before{
         border-width: 95vh 0 0 100vw;
      }
      .viewbox{
         height: 95%;
      }
      .viewtext{
         width: 40%;
         top: 40%;
      }
      .viewtext h2{
         font-size: 4rem;
      }
      .viewtext p{
         font-size: 1.5rem;
      }
      .phone{
         display: block;
      }
   }
   @media(max-width:911px){
      .viewtext h2{
         font-size: 3rem;
      }
      .viewtext p{
         font-size: 1.1rem;
      }
   }
   @media (max-width: 767px){
      .intro{
         height: 92vh;
      }
      .intro::before{
         border-width: 92vh 0 0 100vw;
      }
      .viewbox{
         margin-left: 6.9%;
         width: 85vw;
         height: 90%;
      }
      .viewtext{
         display: none;
      }
   }
   @media(max-width:322px){
      .intro{
         height: 88.5vh;
      }
      .intro::before{
         border-width: 88.5vh 0 0 100vw;
      }
   } */
</style>