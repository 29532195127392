<template>
  <div class="Woman13">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Woman
              <span class="cont">女人心事</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/woman_sample" class="">女人心事</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">人沒變，變的是感覺</h2>
              <h5 class="tl">作家:劉墉</h5>
              <p>
                放暑假，兒子回家住，成天窩在他樓上的臥室裡。<br>
                「也不知道兒子在幹什麼？」有一天，我對太太說：「讓我上去瞧瞧。」 「你千萬別去！」太太居然攔我：「免得生氣。」 「為什麼？」 「因為亂得跟狗窩似的。」 「真的啊！」我伸伸舌頭：「不曉得他在宿舍裡，是不是也這麼亂。」<br><br>
                「也亂！」 「也亂？他不是有女朋友嗎？」我說：「女朋友去，會不幫他收拾？」 太太一笑：「我早問過了，你知道兒子怎麼答嗎？他說女朋友的房間比他的還亂。」 「哎呀！年輕人的房間沒有不亂的。」<br><br>
                一個朋友聽我抱怨，拍拍我：「放心！等他結婚，自然就好了，不好也得好了，否則就真不好了。」 「這是什麼意思？」我不懂。 「很簡單，當他進入社會，常有朋友到家來。有人來，能不收拾嗎？就算沒人來，人去上班，在外頭忙，情緒一定亂，回到家只希望清清爽爽的，如果再看到一團亂，兩個人非翻不可。」 他這話說得一點沒錯，我有次去個親戚家，兩口子正鬧彆扭，丈夫把我拉到裡面，打開臥室門，大聲問：「你看看！家！有這樣的嗎？」 他太太在外面聽到了，立刻拉著嗓子喊：「我從年輕就這樣，你怎麼不嫌我啊？搞到今天，看我什麼地方都不順眼。」<br><br>
                丈夫一下子被堵住了，停了幾秒，吼回去：「此一時也，彼一時也！妳不是大小姐了，妳是太太！」 「那你就去找太太，」太太又吼了回來：「我現在還是大小姐！」 我有個老朋友，最近離婚了，理由很抽象──太太嫌他太「愛現」。 只是，我這個朋友，跟我相交半世紀了，我從沒見他不愛現的時候。<br><br>
                小學，他為了現給女生看，能背整本「小學生字典」；中學他去教堂「現」，聖經上用紅藍綠筆畫得比牧師還多；大學，他跟我學畫，能整夜不睡覺地趕工，只為了要讓我驚訝他能畫得又快又好。進入社會，他演電視劇，不演戲的時候，走在街上還是現，逗得人人都盯著他看。<br><br>
                他跟他前妻也是在街上認識的。「他是個甘草人物，團體裡有了他，就有生氣了。」記得二十年前，他女朋友在婚前對我說：「他的才華，沒話說。」他的才華是沒話說，所以能把那傑出的女朋友，由馬路上吸引到臥室裡，成為他的妻。只是，曾幾何時，他的愛現竟成為他的致命傷。會不會也是「此一時也，彼一時也」。<br><br>
                因為成了丈夫，就應該老成持重，不再活潑愛現呢？ 太太不收拾，丈夫可能會抱怨，妙的是，老婆如果太愛收拾，丈夫也可能怨。有個朋友最近總對我訴苦：「現在啊！我沒覺得有老婆，只覺得有個菲傭，每天吃完晚飯，她能用兩個鐘頭在廚房裡擦擦洗洗，擦洗完了，也累垮了，洗個澡就自己去睡了。結果你知道嗎？下班回家，跟她說句話的時間都沒有。連放假，都不閒著，東掃掃、西擦擦，搞不好，哪天把我都當垃圾掃出去了。」<br><br>
                我怔了一下，笑問：「我只記得十幾年前，常聽你讚美你女朋友多愛乾淨，說到她家，發現她家裡一塵不染，什麼東西都安排得井井有條。還說你一向鼻子敏感，可是到她家全好了。我問你，你是不是說過？」 他點頭。「於是你娶了她，而且到現在十幾年，鼻子都不敏感了，對不對？」我再問。他又點頭。<br><br>
                「那時候，你覺得有潔癖是她的優點，為什麼現在又覺得是缺點了呢？」我問：「是她改了，還是你變了？」 人總是在變， 此一時也彼一時？每個人都總在變，即使身體沒變，感覺也可能變。<br><br>
                好比在火車站，別的車子移動了，你卻覺得是自己的車在動。當自己的車移動時，又錯以為另一輛車在動。 一切都沒變，變的可能是感覺！人多難捉摸啊！ 一個吸引你的妖嬈女子，在成為你老婆之後，那妖嬈就可能被你譏為淫賤。<br><br>
                一個被妳讚賞為急公好義的人，成為妳的丈夫之後，就可能被妳評為好管閒事。一個豪放大方的朋友，成為你的職員之後，就可能被你責備「公私不分」。一對相戀的情侶，結婚之後，一切都沒變，卻可能「相愛容易，相處難」，使婚前一切的優點，都變為對方眼中的缺點。 為什麼不想想，妳當初為何看上他？<br><br>
                為什麼不想想，如果他真不灑脫了、不再整齊了、不再逗趣了、不再豪情了…他還是不是妳原來心中的那個他？當你們彼此說對方變了的時候，會不會兩個人都沒變，變了的只是那種感覺、那點情懷、那分默契？                
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Woman13',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service5.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
  margin: 1rem auto;
}
.list-in{
  list-style: upper-roman;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>