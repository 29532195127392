<template>
  <div class="About">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              About US
              <span class="cont">關於我們</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  關於我們
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="item-content">
              <div class="item md-size">
                <div class="row item-t1">
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="about_us_img d-flex align-items-center h-100">
                      <div class="img"></div>
                    </div>
                  </div>
                  <div class="col-lg-9 col-md-8 col-sm-12">
                    <div class="h-100">
                      <h3 id="element">
                        公司簡介
                      </h3>
                      <p>
                        &nbsp;&nbsp;女人徵信社是合法、優質、誠信之徵信社，專業女人徵信人員，提供女性特有的細心、專業、將心比心的優質服務！男人內心往往無法擺脫三妻四妾的渴望，於是多少未婚劈腿、已婚外遇的情況頻繁發生，尤其大陸包二奶更是長久之陋習；女人徵信社了解您面對外遇的委屈憤怒、面對丈夫大陸包二奶的手足無措，讓女人徵信社給您專業的建議，協助您解決婚姻中的外遇問題！
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row item-t1">
                  <div class="col-lg-9 col-md-8 col-sm-12">
                    <div class="h-100">
                      <h3 id="element">
                        婚姻專家
                      </h3>
                      <p>
                        &nbsp;&nbsp;婚姻中最基本的元素就是忠誠，沒有人願意與第三者分享愛情，然而多少男人偷偷外遇、甚至大陸包二奶，罔顧人夫、人父的家庭責任；您需要女人徵信社協助您對抗外遇的侵略！外遇是難解的婚姻習題，而大陸包二奶的台商更是相互包庇，於是多少女性朋友面對外遇問題手足無措、不知如何是好，著急的吵鬧來表達自身的憤怒不滿，然而這樣的舉動卻讓外遇的丈夫藉口生事、甚至以此表示自己的外遇是正確的！女人徵信社了解您面對丈夫外遇與大陸包二奶的委屈，然而多少人錯誤的做法卻讓自己失去更多；您需要女人徵信社的專業建議、實質協助，為您順利解決外遇的危機！
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="about_us_img d-flex align-items-center h-100">
                      <div class="img2"></div>
                    </div>
                  </div>
                </div>
                <div class="row item-t1">
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="about_us_img d-flex align-items-center h-100">
                      <div class="img3"></div>
                    </div>
                  </div>
                  <div class="col-lg-9 col-md-8 col-sm-12">
                    <div class="h-100">
                      <h3 id="element">
                        專業能力
                      </h3>
                      <p>
                        &nbsp;&nbsp;女人徵信社看過太多男人外表老實、貌似忠誠，然而一到大陸猶如脫韁野馬而開始包二奶、甚至三奶，女人徵信社也看過太多女人為婚姻付出所有，當丈夫外遇還苦苦守候以為對方不過一時暈船，然而最後卻被逼的離婚失去所有！太多美好婚姻的破裂都是因為外遇，太多不幸的例子都是因為女人不懂得保護自己；女人徵信社看過太多不幸的案例，女人徵信社希望能夠藉由女人徵信人員的溫柔、貼心與專業，幫助受外遇所苦、婚姻因大陸包二奶而瀕臨破碎的女性朋友解決困難！外遇不是世界末日，勇敢的面對才是解決之道；讓女人徵信社陪伴您順利解決外遇問題！
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row item-t1">
                  <div class="col-lg-9 col-md-8 col-sm-12">
                    <div class="h-100">
                      <h3 id="element">
                        案件達成
                      </h3>
                      <p>
                        &nbsp;&nbsp;女人徵信社在大陸包二奶等外遇案件有豐富經驗，並且有實務法律談判訴訟經驗，您可以放心的把您的問題交給我們！外遇從來都難以根絕，當外遇發生，想要挽回該怎麼做？要蒐證該如何抓姦？當離婚是無法避免時，該如何才會不傷害到子女？女人徵信社了解您的顧慮與需求，給您圓滿的解決方案；您的任何問題，交給女人徵信社為您順利解決！
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="about_us_img d-flex align-items-center h-100">
                      <div class="img4"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item mobil">
                <div class="row item-t1">
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="about_us_img d-flex align-items-center h-100">
                      <div class="img"></div>
                    </div>
                  </div>
                  <div class="col-lg-9 col-md-8 col-sm-12">
                    <div class="h-100">
                      <h3 id="element">
                        公司簡介
                      </h3>
                      <p>
                        &nbsp;&nbsp;女人徵信社是合法、優質、誠信之徵信社，專業女人徵信人員，提供女性特有的細心、專業、將心比心的優質服務！男人內心往往無法擺脫三妻四妾的渴望，於是多少未婚劈腿、已婚外遇的情況頻繁發生，尤其大陸包二奶更是長久之陋習；女人徵信社了解您面對外遇的委屈憤怒、面對丈夫大陸包二奶的手足無措，讓女人徵信社給您專業的建議，協助您解決婚姻中的外遇問題！
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row item-t1">
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="about_us_img d-flex align-items-center h-100">
                      <div class="img2"></div>
                    </div>
                  </div>
                  <div class="col-lg-9 col-md-8 col-sm-12">
                    <div class="h-100">
                      <h3 id="element">
                        婚姻專家
                      </h3>
                      <p>
                        &nbsp;&nbsp;婚姻中最基本的元素就是忠誠，沒有人願意與第三者分享愛情，然而多少男人偷偷外遇、甚至大陸包二奶，罔顧人夫、人父的家庭責任；您需要女人徵信社協助您對抗外遇的侵略！外遇是難解的婚姻習題，而大陸包二奶的台商更是相互包庇，於是多少女性朋友面對外遇問題手足無措、不知如何是好，著急的吵鬧來表達自身的憤怒不滿，然而這樣的舉動卻讓外遇的丈夫藉口生事、甚至以此表示自己的外遇是正確的！女人徵信社了解您面對丈夫外遇與大陸包二奶的委屈，然而多少人錯誤的做法卻讓自己失去更多；您需要女人徵信社的專業建議、實質協助，為您順利解決外遇的危機！
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row item-t1">
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="about_us_img d-flex align-items-center h-100">
                      <div class="img3"></div>
                    </div>
                  </div>
                  <div class="col-lg-9 col-md-8 col-sm-12">
                    <div class="h-100">
                      <h3 id="element">
                        專業能力
                      </h3>
                      <p>
                        &nbsp;&nbsp;女人徵信社看過太多男人外表老實、貌似忠誠，然而一到大陸猶如脫韁野馬而開始包二奶、甚至三奶，女人徵信社也看過太多女人為婚姻付出所有，當丈夫外遇還苦苦守候以為對方不過一時暈船，然而最後卻被逼的離婚失去所有！太多美好婚姻的破裂都是因為外遇，太多不幸的例子都是因為女人不懂得保護自己；女人徵信社看過太多不幸的案例，女人徵信社希望能夠藉由女人徵信人員的溫柔、貼心與專業，幫助受外遇所苦、婚姻因大陸包二奶而瀕臨破碎的女性朋友解決困難！外遇不是世界末日，勇敢的面對才是解決之道；讓女人徵信社陪伴您順利解決外遇問題！
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row item-t1">
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="about_us_img d-flex align-items-center h-100">
                      <div class="img4"></div>
                    </div>
                  </div>
                  <div class="col-lg-9 col-md-8 col-sm-12">
                    <div class="h-100">
                      <h3 id="element">
                        案件達成
                      </h3>
                      <p>
                        &nbsp;&nbsp;女人徵信社在大陸包二奶等外遇案件有豐富經驗，並且有實務法律談判訴訟經驗，您可以放心的把您的問題交給我們！外遇從來都難以根絕，當外遇發生，想要挽回該怎麼做？要蒐證該如何抓姦？當離婚是無法避免時，該如何才會不傷害到子女？女人徵信社了解您的顧慮與需求，給您圓滿的解決方案；您的任何問題，交給女人徵信社為您順利解決！
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'About',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.about_us_img {
  width: 100%;
}
.mobil{
  display: none;
}
.img{
  margin: 0 auto;
  width: 180px;
  height: 180px;
  border-radius: 90px;
  border: 1px solid #006ff0;
}
.img::before{
  content: '';
  position: absolute;
  top: calc(50% - 80px);
  left: calc(50% - 80px);
  width: 160px;
  height: 160px;
  border-radius: 80px;
  border: 1px solid #006ff0;
}
.img::after{
  content: '';
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  width: 60px;
  height: 60px;
  background-image: url(../assets/images/About-icon/bezier.svg);
  background-size: cover;
  filter: invert(50%) sepia(142%) saturate(1827%) hue-rotate(530deg) brightness(63%) contrast(170%);
}
.img2{
  margin: 0 auto;
  width: 180px;
  height: 180px;
  border-radius: 90px;
  border: 1px solid #006ff0;
}
.img2::before{
  content: '';
  position: absolute;
  top: calc(50% - 80px);
  left: calc(50% - 80px);
  width: 160px;
  height: 160px;
  border-radius: 80px;
  border: 1px solid #006ff0;
}
.img2::after{
  content: '';
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  width: 60px;
  height: 60px;
  background-image: url(../assets/images/About-icon/share.svg);
  background-size: cover;
  filter: invert(50%) sepia(142%) saturate(1827%) hue-rotate(530deg) brightness(63%) contrast(170%);
}
.img3{
  margin: 0 auto;
  width: 180px;
  height: 180px;
  border-radius: 90px;
  border: 1px solid #006ff0;
}
.img3::before{
  content: '';
  position: absolute;
  top: calc(50% - 80px);
  left: calc(50% - 80px);
  width: 160px;
  height: 160px;
  border-radius: 80px;
  border: 1px solid #006ff0;
}
.img3::after{
  content: '';
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  width: 60px;
  height: 60px;
  background-image: url(../assets/images/About-icon/gear-wide-connected.svg);
  background-size: cover;
  filter: invert(50%) sepia(142%) saturate(1827%) hue-rotate(530deg) brightness(63%) contrast(170%);
}
.img4{
  margin: 0 auto;
  width: 180px;
  height: 180px;
  border-radius: 90px;
  border: 1px solid #006ff0;
}
.img4::before{
  content: '';
  position: absolute;
  top: calc(50% - 80px);
  left: calc(50% - 80px);
  width: 160px;
  height: 160px;
  border-radius: 80px;
  border: 1px solid #006ff0;
}
.img4::after{
  content: '';
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  width: 60px;
  height: 60px;
  background-image: url(../assets/images/About-icon/book-half.svg);
  background-size: cover;
  filter: invert(50%) sepia(142%) saturate(1827%) hue-rotate(530deg) brightness(63%) contrast(170%);
}
.item-content{
  margin-bottom: 20px;
}
.item-content .item {
  padding: 1rem;
  background: white;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
  text-align: inherit;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 22px; 
  height: 22px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
       transform: rotate(45deg);
       transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}

.item-content .item h2 {
  width: 100%;
  background: linear-gradient(
    to left,
    rgba(134, 205, 249, 1) 0%,
    rgba(87, 139, 213, 1) 100%
  );
  font-family: YuMincho, yu-mincho-pr6, sans-serif;
  font-weight: 500;
  color: #fff;
  padding: 10px 0px;
  text-align: center;
}
.item-content .item h3 {
  color: burlywood;
  border-bottom: 1px solid burlywood;
  padding-bottom: 5px;
  font-weight: 500;
  margin: 20px 0 20px;
}
.item-content .item h4 {
  background: burlywood;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  padding: 8px 10px;
  display: inline-block;
  /* margin: 30px 0 20px; */
  min-width: 100px;
  text-align: left;
}
.item-content .item h5 {
  color: burlywood;
  font-weight: 500;
  font-family: cursive;
}
.item-content .item li {
  letter-spacing: 1.5px;
}
.item-content p {
  font-family: cursive;
  line-height: 28px;
  letter-spacing: 1.5px;
  text-align: left;
  font-size: 20px;
}

@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
  .md-size{
    display: none;
  }
  .mobil{
    display: block;
  }
}
</style>