import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Service from '../views/Service.vue'
import Service1 from '../views/Service1.vue'
import Service2 from '../views/Service2.vue'
import Service3 from '../views/Service3.vue'
import Service4 from '../views/Service4.vue'
import Service5 from '../views/Service5.vue'
import Service6 from '../views/Service6.vue'
import Service7 from '../views/Service7.vue'
import Service8 from '../views/Service8.vue'
import Service9 from '../views/Service9.vue'
import Service10 from '../views/Service10.vue'
import Service11 from '../views/Service11.vue'
import Service12 from '../views/Service12.vue'
import News from '../views/News.vue'
import News1 from '../views/News1.vue'
import News2 from '../views/News2.vue'
import News3 from '../views/News3.vue'
import News4 from '../views/News4.vue'
import News5 from '../views/News5.vue'
import News6 from '../views/News6.vue'
import News7 from '../views/News7.vue'
import News8 from '../views/News8.vue'
import News9 from '../views/News9.vue'
import News10 from '../views/News10.vue'
import News11 from '../views/News11.vue'
import News12 from '../views/News12.vue'
import News13 from '../views/News13.vue'
import News14 from '../views/News14.vue'
import News15 from '../views/News15.vue'
import law from '../views/law.vue'
import law1 from '../views/law1.vue'
import law2 from '../views/law2.vue'
import law3 from '../views/law3.vue'
import law4 from '../views/law4.vue'
import law5 from '../views/law5.vue'
import law6 from '../views/law6.vue'
import law7 from '../views/law7.vue'
import law8 from '../views/law8.vue'
import law9 from '../views/law9.vue'
import law10 from '../views/law10.vue'
import law11 from '../views/law11.vue'
import law12 from '../views/law12.vue'
import law13 from '../views/law13.vue'
import law14 from '../views/law14.vue'
import woman_sample from '../views/woman_sample.vue'
import woman1 from '../views/woman1.vue'
import woman2 from '../views/woman2.vue'
import woman3 from '../views/woman3.vue'
import woman4 from '../views/woman4.vue'
import woman5 from '../views/woman5.vue'
import woman6 from '../views/woman6.vue'
import woman7 from '../views/woman7.vue'
import woman8 from '../views/woman8.vue'
import woman9 from '../views/woman9.vue'
import woman10 from '../views/woman10.vue'
import woman11 from '../views/woman11.vue'
import woman12 from '../views/woman12.vue'
import woman13 from '../views/woman13.vue'
import woman14 from '../views/woman14.vue'
import woman15 from '../views/woman15.vue'
import woman16 from '../views/woman16.vue'
import woman17 from '../views/woman17.vue'
import woman18 from '../views/woman18.vue'
import woman19 from '../views/woman19.vue'
import woman20 from '../views/woman20.vue'
import Contact from '../views/Contact.vue'
import Sitemap from '../views/Sitemap.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/Service',
    name: 'Service',
    component: Service
  },
  {
    path: '/Service1',
    name: 'Service1',
    component: Service1
  },
  {
    path: '/Service2',
    name: 'Service2',
    component: Service2
  },
  {
    path: '/Service3',
    name: 'Service3',
    component: Service3
  },
  {
    path: '/Service4',
    name: 'Service4',
    component: Service4
  },
  {
    path: '/Service5',
    name: 'Service5',
    component: Service5
  },
  {
    path: '/Service6',
    name: 'Service6',
    component: Service6
  },
  {
    path: '/Service7',
    name: 'Service7',
    component: Service7
  },
  {
    path: '/Service8',
    name: 'Service8',
    component: Service8
  },
  {
    path: '/Service9',
    name: 'Service9',
    component: Service9
  },
  {
    path: '/Service10',
    name: 'Service10',
    component: Service10
  },
  {
    path: '/Service11',
    name: 'Service11',
    component: Service11
  },
  {
    path: '/Service12',
    name: 'Service12',
    component: Service12
  },
  {
    path: '/News',
    name: 'News',
    component: News
  },
  {
    path: '/News1',
    name: 'News1',
    component: News1
  },
  {
    path: '/News2',
    name: 'News2',
    component: News2
  },
  {
    path: '/News3',
    name: 'News3',
    component: News3
  },
  {
    path: '/News4',
    name: 'News4',
    component: News4
  },
  {
    path: '/News5',
    name: 'News5',
    component: News5
  },
  {
    path: '/News6',
    name: 'News6',
    component: News6
  },
  {
    path: '/News7',
    name: 'News7',
    component: News7
  },
  {
    path: '/News8',
    name: 'News8',
    component: News8
  },
  {
    path: '/News9',
    name: 'News9',
    component: News9
  },
  {
    path: '/News10',
    name: 'News10',
    component: News10
  },
  {
    path: '/News11',
    name: 'News11',
    component: News11
  },
  {
    path: '/News12',
    name: 'News12',
    component: News12
  },
  {
    path: '/News13',
    name: 'News13',
    component: News13
  },
  {
    path: '/News14',
    name: 'News14',
    component: News14
  },
  {
    path: '/News15',
    name: 'News15',
    component: News15
  },
  {
    path: '/About',
    name: 'About',
    component: About
  },
  {
    path: '/law',
    name: 'law',
    component: law
  },
  {
    path: '/law1',
    name: 'law1',
    component: law1
  },
  {
    path: '/law2',
    name: 'law2',
    component: law2
  },
  {
    path: '/law3',
    name: 'law3',
    component: law3
  },
  {
    path: '/law4',
    name: 'law4',
    component: law4
  },
  {
    path: '/law5',
    name: 'law5',
    component: law5
  },
  {
    path: '/law6',
    name: 'law6',
    component: law6
  },
  {
    path: '/law7',
    name: 'law7',
    component: law7
  },
  {
    path: '/law8',
    name: 'law8',
    component: law8
  },
  {
    path: '/law9',
    name: 'law9',
    component: law9
  },
  {
    path: '/law10',
    name: 'law10',
    component: law10
  },
  {
    path: '/law11',
    name: 'law11',
    component: law11
  },
  {
    path: '/law12',
    name: 'law12',
    component: law12
  },
  {
    path: '/law13',
    name: 'law13',
    component: law13
  },
  {
    path: '/law14',
    name: 'law14',
    component: law14
  },
  {
    path: '/woman_sample',
    name: 'woman_sample',
    component: woman_sample
  },
  {
    path: '/woman1',
    name: 'woman1',
    component: woman1
  },
  {
    path: '/woman2',
    name: 'woman2',
    component: woman2
  },
  {
    path: '/woman3',
    name: 'woman3',
    component: woman3
  },
  {
    path: '/woman4',
    name: 'woman4',
    component: woman4
  },
  {
    path: '/woman5',
    name: 'woman5',
    component: woman5
  },
  {
    path: '/woman6',
    name: 'woman6',
    component: woman6
  },
  {
    path: '/woman7',
    name: 'woman7',
    component: woman7
  },
  {
    path: '/woman8',
    name: 'woman8',
    component: woman8
  },
  {
    path: '/woman9',
    name: 'woman9',
    component: woman9
  },
  {
    path: '/woman10',
    name: 'woman10',
    component: woman10
  },
  {
    path: '/woman11',
    name: 'woman11',
    component: woman11
  },
  {
    path: '/woman12',
    name: 'woman12',
    component: woman12
  },
  {
    path: '/woman13',
    name: 'woman13',
    component: woman13
  },
  {
    path: '/woman14',
    name: 'woman14',
    component: woman14
  },
  {
    path: '/woman15',
    name: 'woman15',
    component: woman15
  },
  {
    path: '/woman16',
    name: 'woman16',
    component: woman16
  },
  {
    path: '/woman17',
    name: 'woman17',
    component: woman17
  },
  {
    path: '/woman18',
    name: 'woman18',
    component: woman18
  },
  {
    path: '/woman19',
    name: 'woman19',
    component: woman19
  },
  {
    path: '/woman20',
    name: 'woman20',
    component: woman20
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/Sitemap',
    name: 'Sitemap',
    component: Sitemap
  }
]


const router = new VueRouter({
  mode:'history',
  base: '/',
  routes,
  scrollBehavior(to,from,savedPosition){
    if(savedPosition){
      return savedPosition;
    }else{
      return {x:0,y:0}
    }
  },
})

export default router
