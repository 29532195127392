<template>
  <div class="Woman15">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Woman
              <span class="cont">女人心事</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/woman_sample" class="">女人心事</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">見面‧做愛‧回家</h2>
              <h5 class="tl">水瓶鯨魚 轉載自Yahoo!奇摩時尚</h5>
              <div class="">
                <img src="../assets/images/Woman2.jpg" class="img-fluid mb-3" alt="見面‧做愛‧回家">
              </div>
              <p>
                <span class="cont-p">為什麼才短短三個月，這股重生的愛情力量只剩下無趣的六個字「見面，作愛，回家」...</span><br>
                去年剛離婚的辛西亞說起最近的戀情，自三個月蜜月期過後，她和新戀人大約就保持著「見面，做愛，回家」的狀態。<br>
                比辛西亞小兩歲的30歲男人阿杰正在苦惱「怎麼跟百事達櫃檯的可愛女孩要電話」，聽到辛西亞的話，立刻震撼不已。<br>
                阿杰說：「現代的愛情都是這樣嗎？」語氣充滿不可思議，大抵還有些沮喪吧。<br>
                辛西亞是阿杰大學同系的學姐，兩個人感情一直很好。阿杰也陪伴辛西亞歷經老公婚外情的痛苦折磨，辛西雅離婚後，阿杰更目睹一段美麗的新戀情如何讓一朵憔悴的花又重新嬌豔綻放。<br>
                當時，阿杰多為學姊高興，更為見証了戀愛的力量，非常感動。為什麼才短短三個月，這股重生的愛情力量只剩下無趣的六個字「見面，作愛，回家」。<br>
                蘿貝卡轉述給我聽的時候，我笑出來。雖然我只見過阿杰兩次，印象卻極深刻，兩次他都非常認真地討論村上春樹、米蘭昆德拉的小說以及人生的意義。在酒吧談論這種題目，如果在30分鐘之內，我還可以接受，阿杰竟足足講了一個半小時。<br>
                「真是純情的男孩啊，他一定受到打擊了。」我說。<br>
                「沒錯，他是30歲的文藝青少年。」蘿貝卡嘆息。<br>
                「30歲還有這種男人，奇貨可居呢。」<br>
                「我一點也不想遇到這種奇貨，當朋友還不錯，是個純粹的男人。」<br>
                「阿杰一定認為女人很殘酷吧。」<br>
                「可能吧。不過，我了解阿杰受傷的心情，因為他還在為怎麼跟女生要一個電話而小心翼翼，辛西亞對於新戀情描述竟然這麼冷漠。」<br>
                「辛西雅並不冷漠，她只是很寫實啊，三個月的蜜月期算久了。」<br>
                「我還好，我以前的戀愛蜜月期也差不多三個月。」<br>
                「那好像是好久以前的事了。」<br>
                「沒錯，妳說對了，五年以前的事了。」<br>
                「那時候，妳幾歲？」<br>
                「23歲。」<br>
                「我23歲的蜜月期也有三個月啊，現在有兩個禮拜就可以偷笑了。」<br>
                「真恐怖，蜜月期是隨年紀大小而成反比嗎？」<br>
                「最準確的說法是，隨戀愛經驗多寡而成反比。」<br>
                然後，我跟蘿貝卡都笑了，多麼不爭的事實啊。<br>
                初戀的時候，那種甜蜜度彷彿可以天長地久；再度戀愛的時候，蜜月期就有時間表；當戀愛次數越來越多，大家對愛情本質與彼此性格的辨識度，都成CSI鑑識專家。<br>
                辛西亞其實準確地描述了多數都會男女的戀愛模式：「見面，做愛，回家」。蘿貝卡補充另一種型態：「吃飯，喝酒，做愛，回家」。我說，這又有什麼不同。見面，包括：「看電影、逛街、吃飯、喝酒」。至於回家呢？<br>
                蘿貝卡說：「當然一定要回家，自己的家總是比較舒服，一個人睡也比較自在。」<br>
                我說：「如果他到妳家呢？」<br>
                「做完愛，把他趕回家……」蘿貝卡笑著：「這有點傷腦筋啦，所以我比較喜歡去對方家，這樣我就可以控制回家的時間。」<br>
                「哈，我比較熟悉自己的家，因為我有戀床症。而且又不是每天約會，如果第二天沒有事，偶爾一起睡，還好。」<br>
                「妳還要考慮第二天有沒有事喔？」<br>
                「當然啊。男人要留妳在家睡，也會考慮第二天有沒有事啊，比如上班，所以體貼一點的男人會?，等一等送妳回家；懶惰的就會說，我幫妳叫車。」<br>
                「哎呀，如果去旅館就不會有這種困擾。」<br>
                「那還是『見面，做愛，回家』啊，頂多最後的步驟是，各回各自的家。」<br>
                蘿貝卡忽然笑了：「阿杰如果聽到女人們竟然在討論這種問題，一定會抓狂。」<br>
                「其實很多男人都會討論這種問題，阿杰比較例外。」我也笑了。<br>
                「對啊，男人應該比較常思考，我跟這女人需要約會多久才可以上到床？」<br>
                「我有個男性朋友的底限是三次，如果跟一個女人約會了三次還無法上床，他就決定放棄追求這個女人。」<br>
                「真現實啊。」<br>
                「的確現實，不過，有些女人也一樣，只是大多數女人都比男人有耐性。」<br>
                「倒也是，如果有男人跟我約會幾次，卻沒有企圖心，我會以為他若不是Gay，就是有其他喜歡的對象。」<br>
                「如果妳遇到阿杰，搞不好半年都上不了床。」<br>
                「不要開玩笑了，世界上有這種純情男也很可貴啊。」<br>
                「我沒有說不可貴，他至少可以略平衡一下我們對身邊那些狡猾男的厭倦感啊。」<br>
                「我突然發現，即使我和一個像阿杰這樣的男人戀愛，最後的結果好像還是一樣——見面‧做愛‧回家。想想真悲哀，難道沒有別的事好做嗎？」<br>
                「有啊，你們可以一起看DVD啊。」<br>
                這時候，我跟蘿貝卡又一起笑出聲，隨即沉默了許久，決定喝完這杯酒就各自回家。上計程車前，我轉頭看著蘿貝卡。<br>
                「其實我很羨慕阿杰，還有那種純情的心境。」我說。<br>
                「我也是。」蘿貝卡說。               
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Woman15',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service5.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
  margin: 1rem auto;
}
.list-in{
  list-style: upper-roman;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>