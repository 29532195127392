<template>
  <div class="Woman6">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Woman
              <span class="cont">女人心事</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/woman_sample" class="">女人心事</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">已婚裝單身 搞什麼鬼？</h2>
              <h5 class="tl">自由時報 2008/08/04 文/阿桑</h5>
              <p>
                &emsp;&emsp;「ㄟ，怎麼都沒看到你戴婚戒呢？」同事猛然問了我一句：「幹嘛！結了婚還裝單身？」冤枉啊！小女子我可是謹守婦道的良家婦女喔，沒戴婚戒是因為……婚戒太大顆，打電腦不方便嘛！這是實話，可不是愛現！說起我那6.8克拉重（ㄟ……不是鑽石，只是顆海水藍寶啦！）的名牌大婚戒，真的是戴著打字都嫌手酸，況且沒事戴這麼隆重華麗的戒指是怎樣？等著人家來搶嗎？所以婚禮結束後就乖乖收到銀行保險箱去囉！絕對不是像同事A子一樣，明明是有夫之婦了，還硬打著「未婚」身分在外招搖撞騙。<br><br>
                <span class="cont-p">女人裝未婚 指使男人好做事</span><br>
                &emsp;&emsp;記得那時A子和愛情長跑多年的男友低調結婚後，還諄諄告誡我們不能對外透露她結婚的消息，「因為，這樣人家做事比較方便嘛！」外表像個小公主的A子撒嬌的拜託我們。誰不知道女人以未婚身分指使男人作牛作馬比較有用，何況A子外表可愛聲音ㄋㄞ，那些吃她這一套的男人活該當她男傭，再加上「給人方便，自己方便」，有時利用一下A子的男人脈，的確比較好做事，所以我們也樂得對外不多話、不點頭、不暗示，讓A子繼續假裝單身。<br><br>
                <span class="cont-p">老公不戴婚戒 一律居心不良論</span><br>
                &emsp;&emsp;不過換作是自己老公，想來「裝單身」這一套可就不能默許他。不知道你有沒有發現，這年頭，要找到無名指上戴婚戒的男人可不容易，這絕不是因為男人都找不到老婆，或者他們真的未婚，很多時候單純只是因為他「忘了戴」、「懶得戴」、「不方便戴」，背後的真正理由是為了好做事或者為了把妹我不管，我只在意自己的老公有沒有戴婚戒，只要沒戴，就一律以「居心不良」治罪。<br>
                &emsp;&emsp;各位姊妹要了解，男人家手指粗，負載能力一定比女人強，就算戴個純鉑金婚戒也感覺輕如鴻毛吧，絕不會像我們女人一樣打幾個字就覺得手指快斷了（若他真是用這個理由拒戴婚戒，連小小婚戒的重量都不堪負荷，這種男人趕快離掉都不用覺得可惜！）。至於忘了戴和懶得戴，那更不能原諒，婚戒都會忘了，婚約大概也忘了九成了吧，也許他甚至已經忘了他結婚了。<br><br>
                <span class="cont-p">滿足悶騷男人 婚戒由他選</span><br>
                &emsp;&emsp;話說回來，當愛情走到論及婚嫁階段時，雙方最在意的通常是求婚戒女方滿不滿意，誰在乎男方的感受呢？有人堅持要買對戒，款式誰決定？當然是新娘！有人更隨便，託媽媽去銀樓買個純金或K金戒就打發了。這種不合己意的婚戒，難怪新郎只想趕快脫下來藏好，誰要戴個又聳又醜的婚戒在手上，換作女人，你願意嗎？<br>
                &emsp;&emsp;再則，誰說婚戒一定要成雙成對？這年頭，男人真的愛漂亮，對婚戒的要求高一些也情有可原，女人實在要貼心一點，若你的男友很悶騷，就讓他對婚戒有點自主權，選只他自己喜歡的款式吧！<br><br>
                <span class="cont-p">天天戴婚戒 謹記對婚姻的承諾</span><br>
                &emsp;&emsp;當初我特地陪老公去選一只他自己中意的婚戒，低調、大方，質地和款式都符合他的要求，他答應我這只婚戒他一定會天天戴著，謹記他承諾過的忠誠。小小一只，價格不斐，戴上去更襯托他的成熟穩重，這只婚戒深得老公的心，所以只要有一天我沒看到老公手上的婚戒，我就威脅他：「不戴是吧！還我！我放保險箱去，以後送給兒子！」酷愛這只婚戒的老公，第二天一定乖乖戴上，不敢遺忘。當然，老公也曾質疑過我沒戴婚戒的事，我只回他一句：「那你再送我一顆小鑽戒戴啊！」從此他就不再廢話了！                
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Woman6',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service5.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
  margin: 1rem auto;
}
.list-in{
  list-style: upper-roman;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>