<template>
  <div class="Woman2">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Woman
              <span class="cont">女人心事</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/woman_sample" class="">女人心事</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">你劈腿我就外遇</h2>
              <h5 class="tl">轉載自 蘋果日報</h5>
              <p>
                不知道是基於報復還是補償，茱莉忍受了老公劈腿的痛苦後，自己也意外的發生了一段戀情。<br>
                <span class="cont-p">戀愛感覺來勢洶洶</span><br>
                &emsp;&emsp;當初為了讓自己安靜幾天，她開車上山到農場度假結束時遇到山崩，一位熱心的男人引領她繞道，走另一條山路一起下山。<br>
                &emsp;&emsp;下山後茱莉為了感謝那個男人就主動邀他吃飯。茱莉竟然對著陌生人說著心事，陌生男人笑了起來說：「你的坦白讓我很驚訝，我們才剛認識呢。」「可是你給我一種奇異的安全感，讓我可以信賴。 」茱莉的眼神有點迷離。<br>
                &emsp;&emsp;一頓飯結束意猶未盡，男士說要回請茱莉，於是約了下一次，戀情就從這裡開始的。兩人約來請去，從陌生到熟稔到渴望見面，戀愛的迷離幻境發生得那麼自然、美麗又刺激。熱戀中的茱莉，時常假藉加班甚至連例假日也拋家棄子，陷入前所未有的狂亂狀態。<br>
                &emsp;&emsp;原先對老公劈腿的憤怒和恐懼早已化作輕煙，隨著戀愛的狂風吹散。取而代之的是隱隱的內疚，甚至於理解。<br>
                &emsp;&emsp;理解老公劈腿時所有不可自拔的情緒，即使一再被她逼問說「為什麼」時，也找不到真確的答案。此刻茱莉懂了，這種不倫的戀情，開始得莫明其妙，來勢洶洶讓人難以抵擋。<br>
                <span class="cont-p">女兒發燒聯絡不上</span><br>
                &emsp;&emsp;又是一個蹺家約會的日子，茱莉意亂情迷、心猿意馬的遲遲歸來，發現桌上老公留的的字條：「女兒發高燒抽筋，送醫院急診。」<br>
                &emsp;&emsp;茱莉趕到醫院時醫生說：「還好爸爸送得早，沒傷到腦神經。」老公淡然的說：「妳關機，所以聯絡不上妳。」茱莉的臉瞬間脹紅，灼燒著內心的不安。<br>
                &emsp;&emsp;女兒生病讓茱莉覺醒自己是一個有家的女人，她主動跟男友提出分手。從此茱莉不再逼問老公劈腿的事，她相信，老公遲早也會回家的。
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Woman2',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service5.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
  margin: 1rem auto;
}
.list-in{
  list-style: upper-roman;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>