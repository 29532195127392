<template>
  <div class="header">
    <!-- <VueScrollFixedNavbar> -->
    <b-navbar
      toggleable="lg"
      type="dark"
      :class="{ classA: scrollPosition < 100, classB: scrollPosition > 100 }"
    >
      <b-navbar-brand>
        <router-link
          to="/"
          exact
          class="navbar-brand"
          style="font-family: Brush Script MT, Brush Script Std, cursive; border-bottom: 0px; font-weight: 100; background: transparent;"
        >
        <div class="logo">
          <!-- <p><em>女人</em>徵信有限公司</p>  -->
        </div>
        </router-link>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item>
            <router-link to="/" class="nav-link" exact>首頁</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/About" class="nav-link">關於我們</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/Service" class="nav-link">服務項目</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/News" class="nav-link">真實案例</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/law" class="nav-link">法律常識</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/woman_sample" class="nav-link">女人心事</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/Contact" class="nav-link">聯絡我們</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/Sitemap" class="nav-link">Sitemap</router-link>
          </b-nav-item>
        </b-navbar-nav>
        <!-- <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
          <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
        </b-nav-form>
        <b-nav-item-dropdown text="Lang" right>
          <b-dropdown-item href="#">EN</b-dropdown-item>
          <b-dropdown-item href="#">ES</b-dropdown-item>
          <b-dropdown-item href="#">RU</b-dropdown-item>
          <b-dropdown-item href="#">FA</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown right>
          <template #button-content>
            <em>User</em>
          </template>
          <b-dropdown-item href="#">Profile</b-dropdown-item>
          <b-dropdown-item href="#">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav> -->
      </b-collapse>
    </b-navbar>
    <!-- </VueScrollFixedNavbar> -->
  </div>
</template>

<script>
// import { VueScrollFixedNavbar } from "vue-scroll-fixed-navbar";
// import $ from 'jquery'

export default {
  name: "Navbar",
  data() {
    return {
      scrollPosition: null,
    };
  },
  // components:{
  //   VueScrollFixedNavbar
  // },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
};
</script>

<style scoped>
.navbar {
  background-color: transparent;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 999;
}
.navbar-dark .navbar-brand {
  color: black;
}
.navbar-dark .navbar-nav .nav-link {
  color: burlywood;
}
.navbar-dark .navbar-toggler {
  background-color: brown;
}
.classB {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 999;
  background-color: ghostwhite;
}
.logo{
  display: inline-block;
  background-image: url(../assets/images/logo.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 66px;
  width: 400px;
  position: relative;
  bottom: -6px;
}
.logo p{
  font-style: italic;
  color: white;
  padding-left: 45px;
}
.logo:hover{
  opacity: 0.5;
}
.logo p:hover,.logo p:hover em{
  color: rgb(255, 222, 89);
}
.logo p em{
  color: white;
}
a:hover{
  color: rgb(255, 222, 89);
}
@media (max-width: 1025px) {
  .logo{
    height: 45px;
    width: 215px;
  }
}
@media (max-width: 911px) {
  .navbar-collapse {
    background-color: ghostwhite;
  }
  .navbar-nav {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .logo{
    height: 50px;
    width: 240px;
  }
}
@media(max-width:322px){
  .logo{
    height: 30px;
    width: 195px;
  }
}
</style>


