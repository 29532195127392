<template>
  <div class="Serve10">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Service
              <span class="cont">專利商標仿冒</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/Service" class="">服務項目</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  專利商標仿冒
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">專利商標仿冒</h2>
              <p>
                由於仿冒行為日益嚴重，許多企業紛紛求助無門，有鑑於此，中華女人徵信特別成立專利商標仿冒調查小組，專門針對企業疲於應付的專利商標仿冒問題做徹底的調查。<br><br>
                <span style="color:red;font-weight:600;font-size:18px;">Ｑ：智慧財產到底是什麼 ?</span><br>
                「智慧財產」含專利權、著作權、商標權、積體電路電路佈局權、營業秘密及其他無形智慧資產。<br><br>
                智慧財產（Intellectual Property）是指由人類的智慧活動而產生出來的成果，常附著於有形的媒體，其價值不在媒體本身，而在於人們透過媒體所表達出來概念的欣賞，或利用此一概念創造有形產品的更高附加價值；而佔有和支配智慧財產的法律地位，即為智慧財產權（Intellectual Property Rights,簡稱IPR）。智慧財產權的範圍十分廣泛，包括：<br><br>
                <ul class="list">
                    <li>保護人類智慧結晶的創造成果如專利、植物新品種、微生物菌種、積體電路電路佈局、營業秘密及工業設計等；</li>
                    <li>表彰人類創造發明所發展出來的產業識別標記如商標、標章等；</li>
                    <li>表彰人類精神創作的著作權如著作人格權與著作財產權</li>
                </ul>
                <span style="color:red;font-weight:600;font-size:18px;">Ｑ：何謂著作權 ?</span><br>
                在人類社會裡，文明的發展與延續，必須藉由許多人的發明、創作才能完成。精神方面的創作，尤其是文學、科學、藝術或其他學術領域的作品更是文明資產的一部分，我們稱他們為著作，為了保障這些著作創作者的權益，由國家制定法律予以保護，法律所規定的這些權利，就叫做著作權。<br><br>
                當創作者完成一項著作時，就這項著作立即享有著作權，而受到著作權法的保護。因此著作權是在著作完成的時候立即發生的權利，也就是說著作人享有著作權，不須要經由任何程序，當然也不必登記。<br><br>
                著作權包括了著作人格權與著作財產權兩個部分。著作人格權，是用來保護著作人的名譽、聲望及其人格利益的，因為和著作人的人格無法分離，所以不可以讓與或繼承。著作人格權包括有公開發表權、姓名表示權及禁止別人不當竄改著作，以致損害著作人名譽之禁止不當改變權等三種權利。<br><br>
                著作財產權，主要是賦予著作人即創作著作的人財產上的權利，使他獲得實質上的經濟利益，促使作者繼續從事創作活動，精進創作的質與量，豐富文化內容。著作財產權的主要內容，包括重製權、公開口述權、公開播送權、公開上映權、公開演出權、公開展示權、改作權、編輯權及出租權，一共有九種權利。<br><br>
                為了尊重著作人的權益，利用別人的著作時，不但不能侵害到著作人格權，還應得到著作財產權人的同意或授權。<br><br>
                <span style="color:red;font-weight:600;font-size:18px;">Ｑ：仿冒的定義在哪裡?</span><br>
                假如說像7-11，有人仿冒成8-11，這樣構成仿冒嗎 ? 像7-11這種是屬於商標，所以適用商標法，明知道有7-11這個名稱而故意創造出一個8-11，如果他的圖案又很像原先7-11的樣子，對於消費者而言會產生混淆，有時候原先會到7-11買東西的客人會跑到8-11，這是由於8-11的圖案太相近，致使旁人難以區分所導致，如此就會侵害7-11的商業利益，因此應構成仿冒，而仿冒的問題亦只存在於商標法。<br><br>
                此舉違反商標法第六十二條一項：<br>
                未得商標權人同意，有下列情形之一者，視為侵害商標權：<br>
                明知為他人註明知註冊商標，而使用相同或近似之商標，或以該著名商標中之文字，作為自己公司名稱、商號名稱、網域名稱，或其他表彰營業主體或來源之標識，致減損著名商標之識別性或信譽者。<br><br>
                以下再列出第二項：<br>
                明知為他人之註冊商標，而以該商標中之文字，作為自己公司名稱、商號名稱、網域名稱，或其他表彰營業主體或來源之標識，致商品或服務相關消費者混淆誤認者。<br><br>
                <span style="color:red;font-weight:600;font-size:18px;">Ｑ：到底何謂侵權行為 ?</span><br>
                侵權行為意義：因故意或過失，不法侵害他人之權利者負損害賠償之責任。<br><br>
                <span>客觀要件：</span><br>
                <ul>
                    <li>需有加害行為</li>
                    <li>行為需不法</li>
                    <li>需侵害權利或利益</li>
                    <li>致生損害</li>
                    <li>損害與結果間有因果關係</li>
                </ul>
                <span>主觀要件：</span>
                <ul>
                    <li>需有責任能力</li>
                    <li>需為故意或過失</li>
                </ul>
                效力：<br>
                被害日起10年內應主張，發現侵害後2年間不行使消滅，但為不當得利於15年內依不當得利請求反還。<br><br>
                加害人因侵權行為取得債權者，雖因時效消滅仍得拒絕履行，如：以詐欺脅迫開立之借據。
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Serve10',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service2.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
    margin-bottom: 0.8rem;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
@media (max-width: 322px) {
  .inner {
    width: 60%;
  }
  .inner-banger {
    padding: 0px;
  }
  .breadcrumb{
    font-size: 16px;
  }
  .title{
    font-size: 1.9rem;
  }
}
</style>