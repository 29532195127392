<template>
  <div class="Law8">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Law
              <span class="cont">法律常識</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/law" class="">法律常識</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  法律內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">夫妻離婚 須分擔扶養義務</h2>
              <h5 class="tl">蘋果日報 2008/09/10 記者鄧玉瑩採訪整理</h5>
              <p>
                <span class="cont-p">台中趙小姐問：</span><br>
                &emsp;&emsp;我和前夫離婚時，只說好一人一個小孩，但沒有談到教育問題，後來前夫去大陸工作，兩個小孩都來和我住，前夫經商失敗回來後，一直要接回大兒子，但大兒子不肯，所以一直住我這。我一個月賺不到兩萬元要扶養兩個小孩，又因前夫還有工作能力，申請不到補助，請問我該如何向他要小孩生活扶養費呢？<br><br>
                <span class="cont-p">律師余德正答：</span><br>
                &emsp;&emsp;雖然您和前夫已經離婚，但是如果您的兩個孩子尚未滿二十歲，也就是在《民法》規範的未成年之子女，依《民法》規定，父母對於未成年之子女，有保護及教養的權利義務。<br>
                &emsp;&emsp;而所謂的「保護及教養之權利義務」，包括「扶養」在內，且依《民法》相關規定，父母雖然離婚，但並不影響其對於未成年子女所應負擔之扶養費義務，也就是說，兩人仍應按各自的經濟能力，分擔扶養義務。<br><br>
                <span class="cont-p">可向法扶基金會求助</span><br>
                &emsp;&emsp;所以，趙小姐的前夫在法律上，仍必須負擔兩個未成年子女的扶養費用，一直到成年為止，若您的前夫目前是有工作能力，卻不願意負擔兩個小孩的扶養費用，您可以向法院提出訴訟，要求前夫支付兩個小孩的扶養費。<br>
                &emsp;&emsp;至於您的前夫對兩個小孩應支付多少扶養費？法官會先確認兩個小孩每月需要的扶養費用金額，再決定您的前夫，就扶養費用應該負擔的比例，然後做出判決。<br>
                &emsp;&emsp;如果您本身因為經濟因素無法委任律師，又擔心自己不瞭解法律的話，可就近向當地的法律扶助基金會尋求協助。                
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Law8',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service4.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
    margin: 1rem auto;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>