<template>
  <div class="Woman9">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Woman
              <span class="cont">女人心事</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/woman_sample" class="">女人心事</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">戀情二十年 天天在捉姦</h2>
              <h5 class="tl">蘋果日報 2008/10/28</h5>
              <p>
                <span class="cont-p">Q：為何為初戀男友扛債半生？</span><br>
                A：他大我13歲，是我在飯店工作時的客戶，常約我，我看他出手大方又幽默，很快跌進感情。他坦承已結婚，但老婆對他不好，我說沒關係，因為我10幾歲就半工半讀，一路走來都靠自己，很渴望被人呵護的溫暖。<br>
                交往後，我才發現他生活很複雜，從未真正工作，都利用人際關係賺錢；黑道出事時，他就當中間人，拿錢請白道解決。賺大錢時他會給我一些，問題是他常上酒家應酬，錢永遠不夠，常要我幫他調錢，從此我一直為他負債。<br>
                與黑道談判救男友<br>
                有次他拿錢給白道，人家沒幫他辦事，黑道以為他污了錢，把他抓去打。我很大膽，還跑去跟那些黑道講道理，把他救回來。<br>
                幾年後他被判詐欺，關了兩年，剛開始我每天寫信給他，定時去探監。有天竟有個酒店小女生跟我同時去看他，他只叫女生走，也沒解釋什麼。我覺得他折磨我太多，很想離開他，慢慢就不再寫信，但他關回來後一直追我，我心軟，還是跟他同居。<br><br>
                <span class="cont-p">Q：妳想過跟他結婚嗎？</span><br>
                A：沒耶，因為跟著他不可能有安定的生活。他很不負責，離婚後就不曾再去看前妻和小孩。<br><br>
                <span class="cont-p">Q：他到底給妳什麼滿足？性？</span><br>
                A：我性慾不強，工作累，又知道他在外面女人不斷，根本不想跟他做。他就像家人，在生活小細節上蠻照顧我的。<br>
                那時我也很笨，追到某個女人的家在外面等，他出來後看到我嚇一跳，結果吵完架，我還是自己回家。<br><br>              
                <span class="cont-p">Q：捉姦的心情？</span><br>
                A：當然難堪，但我跟他沒婚約，我有什麼立場？他們大不了就是搞男女之間的事，我不在乎，只是生氣他騙我。<br>
                有陣子我搬回家照顧我爸，一星期只去他家住兩天，發現他又交一個酒店小姐，就故意去他家堵。他看到我，都叫那女人走；有時半夜那女人回來找他，我還在，他會叫那女人睡客房，我知道他永遠對我比較好，就不再那麼吃醋。<br>
                後來我更清楚：他只是利用那些女人，滿足性需求，要她的錢或人際關係。但對我就不一樣，畢竟我很單純，一路陪他度過難關，等於傳統的配偶角色，他自覺虧欠我。<br>
                結束感情活出自我<br><br>
                <span class="cont-p">Q：妳有正宮娘娘的心態？</span><br>
                A：我就是用情很深，覺得他很可惡也很可憐，一直想救他。每當他有錢，開始應酬搞關係、交女人，我就很沮喪地離開他，但沒多久他一定掉入谷底，我就回來照顧他，如此周而復始循環23年，幫他扛的債永遠還不完。<br>
                後來我看清他不能給我什麼，開始自立做保險業務，完全不想過問他又交了哪些人、在搞什麼事，也不敢讓他認識我的新朋友和客戶，以免他又跑去跟人家借錢，連累到我。<br>
                到45歲，我終於受夠只能有地下情人關係、挖東牆補西牆的負債生活，就趁他去大陸時永遠搬離那個家，只留了封信謝謝他23年來對我的照顧。之後他幾次找我借錢，但我不願再給了。<br>
                現在，我快50歲了，偶爾在街上遇到他，看他落魄的樣子，既為他難過，也很感謝他。如果不是當初一直為他扛債，我不可能有今天這麼強的能力，活得自主又快樂，不再需要男人照顧。
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Woman9',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service5.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
  margin: 1rem auto;
}
.list-in{
  list-style: upper-roman;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>