<template>
  <div class="Woman1">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Woman
              <span class="cont">女人心事</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/woman_sample" class="">女人心事</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">婚姻的危機－外遇</h2>
              <h5 class="tl">轉載《網路社會學通訊期刊》南華大學應用社會系吳亭臻</h5>
              <p>
                前言：<br>
                隨著社會風氣的轉變，許多價值觀念開始與傳統迴異，在過去，外遇是一件羞恥的、不可告人的作為，但現在，雖然它仍然不是一件光榮的事，但是社會大眾對它的接受度，已經明顯高於過去，這樣的演變，削弱的婚姻絕對性的基架，也讓感情的忠誠度不再不容置疑。因此，我想就電影《出軌》的寫實內容，用其他探討婚姻外遇的論點分析，希望能將電影中的幾個重點呈現出來。<br><br>
                <span class="cont-p">電影《出軌》內容簡介：</span><br>
                &emsp;&emsp;愛德華與康妮在紐約市郊過著人人欣現的生活，夫妻感情穩定，還有一個八歲大的兒子，但是引定的生活在忙碌的工作之中，一切都變的平淡無味，康妮是一位美麗的女子，雖然中年卻仍然保持良好的形貌，然而，康妮在一次的意外邂逅，遇見一個年僅二十八歲的男子，男子的浪漫溫柔與極度的挑逗，挑起了她的淺在慾望，她背著丈夫，一再的外出約會，並且在精神與肉體上，都做出了極大的背叛，最後，丈夫在與男子談話時，失手將他殺死，在情慾、憤怒、罪惡、欺騙之下，這一段婚姻不再平靜。<br><br>
                <span class="cont-p">討論點一：外遇好玩嗎？</span><br>
                &emsp;&emsp;外遇的問題，在理論上可以說是夫妻中的一方與第三者有性行為的關係。在簡春安的《婚姻中的外遇問題》中提到：「一般人都以為一定是夫妻兩人個性不合或相處不來才容易有這種現象，事實卻不盡然。」<br>
                &emsp;&emsp;臺灣的外遇問題，經常有下列一些基本過程： 有些人以為外遇很好玩，認為外遇是一件炫耀的事。或許是由於傳統文化中大男人主義的影響，很多人以為外遇很刺激，所以躍躍欲試，一旦有機會，很快就自願上鉤了。<br><br>
                <span>討論點三：外遇的法則？</span><br>
                外遇關係的形成不是三天兩日驟然而成的，經常都帶有一些「保護色」與「自然法則」。例如：
                <ul class="list">
                  <li>開始時，男人不斷的跟女人訴苦說他的太太有多差，完全不了解他，所以他是一個受苦者或孤獨者。有時候明明太太已經很不錯了，他會故意「醜化」她；若是太太略有瑕疵，他則特意「惡化」。無形中，女人就會很輕易的以拯救者的角色介入別人的家庭。</li>
                  <li>男人常強調女人氣質好，有深度，只有她才是他日後唯一快樂的來源，也只有她才是他一生中唯一的知音。所以很多女人在此時明明知道男人太誇張，但也樂於擁有這種非凡的成就，陷於大惑而不自知。</li>
                  <li>兩人的話題經常由公事開始，私下約會時也經常對外人宣稱他們是在討論公事。一段時間後才由「公事」進而談到「旁人雜事」，再由「旁人雜事」談到兩人「私事」再由分享「感受」進而到發生肉體關係。</li>
                  <li>兩人的約會形態是由參加團體活動為幌子，演變為三四個人的小團體活動，再變成為兩個人私下幽會，更而在外地公開出雙入對。</li>
                </ul>
                <span class="cont-p">討論點四：外遇的傷害</span><br>
                從輔導的角度與經驗來分析，事實卻不盡然，外遇其實禍害無窮：<br>
                <ul class="list-in">
                  <li>
                    <span class="cont-p">外遇造成心理上的傷害。</span><br>
                    夫妻間有一方若有了外遇，必然引起連續不斷的爭吵與紛爭。爭吵時對人的心境平衡影響相當的大。兩人的關係充滿「敵意」，不斷的處在警戒狀態或「備戰」狀況中，隨時處於火山爆發邊緣，對夫妻雙方傷害性都很大。就是太太怕先跟先生吵，先生也免不了會有衝突感或愧疚感。
                  </li>
                  <li>
                    <span class="cont-p">外遇帶來生理上的危害。</span><br>
                    衝突與爭吵已經對身體有不良的影響，外遇者由於要應付兩個女人的要求，生理上容易透支。理論上分析，外遇者為了處理關係的不確定感（如他與這女人還不是夫妻），必須藉更多的性行為來安慰對方，以肉體關係來肯定兩個人之間的關係。
                  </li>
                  <li>
                    <span class="cont-p">外遇也會帶來事業上的危機。</span><br>
                    外遇極耗心神，常使人在事業上分心，收入難免減少。加上兩個家庭的開支與需求，支出加倍，使外遇者經常心勞力瘁。此時若有試探，外遇者容易貪污瀆職，在事業上的表現也大不如昔，報紙中的社會新聞不乏有這些例子。
                  </li>
                  <li>
                    <span class="cont-p">外遇畢竟對名節有損。</span><br>
                    縱使有些人以為「那個男人不偷腥」，事實上旁人有時不便批評，只用開玩笑的口氣挖苦一番，其實心裏面難免會有看笑話或輕視的心態。
                  </li>
                  <li>
                    <span class="cont-p">外遇對子女有最惡劣的影響</span><br>
                    縱使有人能克服上述的種種難題，他亦不能否認，他不希望他的兒女跟他一樣，他更不願意他的子女在長大成人後成為外遇的受害者。然而外遇本身就是最不好的家庭教育，對子女而言，是最不好的「身教」。另一方面，由於外遇帶來家庭中的激盪，夫妻的衝突，對子女心理的影響更是可怕。年幼時也許不顯著，等孩子長大成人後，經常會對婚姻產生恐懼感，在人際關係中有自卑感，角色認同過程中也容易有混淆不清的現象。有些外遇者一直等到十幾年後孩子們出現困境時才悔不當初。
                  </li>
                  <li>
                    <span class="cont-p">外遇關係經常以「恨」收場</span><br>
                    有些人以為外遇是為了尋求理想中的愛，為了愛可以不惜冒天下之不諱。其實這只是一廂情願的想法。外遇者在開始階段固然有愛的歡愉與享受，但為期甚短，很少不以衝突或恨收場的。美滿婚姻在積極方面固然需要夫妻雙方不斷的努力與付出，在消極方面，也需要雙方在觀念上知道去避免不良的誘惑與試探。外遇問題對家庭危害甚巨，值得大家警惕。
                  </li>
                </ul>
                <span class="cont-p">對照電影中的內容：</span><br>
                &emsp;&emsp;片中的愛德華與康妮本來是一對生活安適的佳偶，男的事業有成，女的聰穎美麗，兒子查理也活潑可愛，一家三口居住在紐約市郊，親友都很羨慕。<br>
                &emsp;&emsp;但是為康妮總覺得愛德華忙於工作，雖然他愛著她，但是許多話題、感覺都已經平淡無味，而她總是忙於家務、孩子、學校的義賣會等等，彷彿一切只能這樣過下去，生活已經有了一種格式化的規則，因此，當她遇到年輕有魅力的男子保羅，她馬上就被他吸引了，加上保羅一再的挑逗，康妮終於越出的婚姻的邊界，走向背叛的選擇。<br>
                &emsp;&emsp;一開始，康妮只是覺得好玩、刺激，但是漸漸地，這樣的行為讓她覺得自己很有吸引力，這證明了她美貌依舊，而保羅的年輕熱情，也令她在一成不變的生活中，找到甜蜜心動的感覺，但是當激情過後，取而代之的，是罪惡感與心虛、悔恨，她徬徨於兩個男人之間，做不出正確的選擇。<br>
                &emsp;&emsp;康妮的微妙變化讓愛德華覺得既懷疑又擔心，他不願相信康妮會出軌，但是來自朋友的耳語又令他不得不去追究，私家偵探終於拍到了康妮外遇的證據，愛德華也終於證實康妮已經背叛了他。愛德華找到保羅的家，而且在談話的過程中發現，康妮將自己送給她的一份特別的禮物轉送給了保羅，他看著當初自己所選給康妮的一份真心被這樣踐踏，又看到保羅床上的凌亂被單，一股既絕望又憤怒的衝動讓他失手殺害了保羅，一個美滿的婚姻因此無路可退。<br>
                &emsp;&emsp;這部電影的劇情跟上面一段的分析不謀而合，外遇的開始、發展、影響以及傷害，都在這一個真實的例子中呈現出來，我覺得背叛的傷害是很大的，尤其是當一方真心真意的奉獻自我，辛苦工作只為家人的時候，如果遇到了對方出軌，實在是很殘忍的一件事，因此，恨意的萌生就會取代理智的考量，之後逤做出的傷害更是難以預計。<br><br>
                結論：<br>
                <span class="cont-p">幸福是夫妻共同追求的</span><br>
                婚姻幸福與否不能由籠統的離婚率來代表。離婚率較歐美為低並不意味著國人的婚姻較歐美各國的婚姻幸福。<br>
                婚姻幸福不幸福，或是婚姻如何能維持幸福，要看夫妻雙方的成長能力而定。國內的婚姻有三個普遍的現象：<br>
                <ul class="list-in">
                  <li>枯竭的婚姻：夫妻雙方相敬如「冰」，夫妻兩個人談不上話，別提水乳交融，更別希望兩人親密相愛。剛結婚時的相愛情形只是在爭吵時才被引出來做為指控對方的記錄。</li>
                  <li>義務型的婚姻：夫妻雙方鑑於傳統文化的束縛，兩人極力扮演好夫妻的角色，各盡應盡的義務。</li>
                  <li>受苦型的婚姻：兩人已是水火不容，但是礙於情面，只好維持著貌合神離的關係，彼此都知道對方已另有對象，但是為了孩子、為了面子、為了家庭的傳統，兩人不敢離異，情願在這種關係中痛苦的生存。</li>
                </ul>
                這些婚姻的現象使一個家庭雖然沒有激烈的爭吵與衝突，但是家庭的氣氛卻是一片死寂；雖然使夫妻兩人維持著婚姻關係，卻是無可奈何的相聚；雖然全家大小日夜均可見面，但每個人卻巴不得早日可以離開家庭而得到「自由」。<br><br>
                夫妻之間的誤解與衝突永遠存在，兩人卻無法去克服，最後只好以逃避的方法來處理。這些現象最基本的原因是他們的婚姻與家庭絲毫沒有成長。如何使婚姻成長呢？<br><br>
                <span class="cont-p">簡春安在《外遇的分析與處置》中提到，下列方法可以使夫妻成長：</span><br>
                <ul class="list-in">
                  <li>夫妻之間必須「設立」共同的目標</li>
                  <li>夫妻生活中，不妨每個禮拜有一固定時間為配偶做一件事。</li>
                  <li>設法恢復婚前的約會活動</li>
                  <li>滿足對方，不要控制對方。改變自己，了解配偶；不是改變配偶，要求他了解你自己。</li>
                </ul>
                總而言之，在人與人相處之間若要維持和諧、愉快，當事者必須是個成長的個體。<br>
                同樣的，夫妻相處若要能白首偕老，而且相處情深，恩愛日常，更需要夫妻兩人不斷的努力，不斷的充實，了解配偶、滿足配偶，就可達成。                
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Woman1',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service5.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
  margin: 1rem auto;
}
.list-in{
  margin: 0.5rem auto;
  list-style: decimal;
}
.list-in li{
  margin: 1rem auto;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>