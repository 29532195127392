<template>
  <div class="Contact">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Contact
              <span class="cont">聯絡我們</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  聯絡我們
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="item-content">
              <div class="item">
                <div class="row justify-content-center item-t1">
                  <div class="col-12">
                    <h2 class="lineder mb-5" id="element">聯絡我們</h2>
                  </div>                  
                  <div class="col-lg-6 col-md-10 col-sm-12">
                    <h4 data-text="24小時免費咨詢：" class="text-center">24小時免費咨詢：<br class="mobil"><a href="tel:0800503333">0800-503-333</a></h4>                    
                    <h2 class="text-center">各地區連絡電話</h2>                                      
                    <ul class="list">
                      <li>台北：<a href="tel:0800503333">0800-50-3333</a></li>
                      <li>板橋：<a href="tel:0800503333">0800-50-3333</a></li>
                      <li>桃園：<a href="tel:0800234333">0800-234-333</a></li>
                      <li>新竹：<a href="tel:0800234333">0800-234-333</a></li>
                      <li>台中：<a href="tel:0800421111">0800-42-1111</a></li>
                      <li>嘉義：<a href="tel:0800421111">0800-42-1111</a></li>
                      <li>台南：<a href="tel:0800827999">0800-827-999</a></li>
                      <li>高雄：<a href="tel:0800827999">0800-827-999</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {};
  },
};
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service6.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.lineder {
  font-size: 3rem;
  text-align: center;
}
.lineder::after {
  content: "";
  width: 8%;
  height: 1.5px;
  background: red;
  display: block;
  margin: 0 auto;
}
.item-t1{
  margin-top: 10px;
}
.item-content{
  margin-bottom: 20px;
}
.item-content .item {
  padding: 1rem;
  background: white;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
  text-align: inherit;
}
.list{
  margin-bottom: 2rem;
  list-style: circle;
  padding-left: 36.333333%;
}
.list li{
  margin: 0.8rem auto;
  font-size: 18px;
  /* background-image: linear-gradient(to left, darkgoldenrod 0%, darkgoldenrod 70%, transparent 50%);
  background-size: 16px 1px;
  background-position: bottom;
  background-repeat: repeat-x; */
}
.disabled:hover{
  cursor: not-allowed;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
h4 {
    position: relative;
    color: rgba(0, 0, 0, .3);
    font-size: 35px;
}
h4:before {
    content: attr(data-text);
    position: absolute;
    overflow: hidden;
    max-width: 9em;
    white-space: nowrap;
    color:red;
    animation: loading 5s linear;
    animation-iteration-count: infinite;
}
.mobil{
  display: none;
}
@keyframes loading {
    0% {
        max-width: 0;
    }
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
  .list{
    padding-left: 28.333333%;
  }
  h4{
    font-size: 1.8rem;
    color: black;
  }
  h4:before {
    content: '';
  }
  .mobil{
    display: block;
  }
}
@media (max-width: 322px){
  .list{
    padding-left: 23.333333%;
  }
}
</style>

