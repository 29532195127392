<template>
  <div class="Woman5">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Woman
              <span class="cont">女人心事</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/woman_sample" class="">女人心事</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">不帶孩子的離婚難題</h2>
              <h5 class="tl">蘋果日報 吳淡如</h5>
              <p>
                「媽媽，妳到底在做什麼工作？」星期六，按照離婚後的約法三章，是淑瑄和孩子相處的時間。她正陪念小三的兒子組裝模型時，兒子忽然這麼問她。<br><br>
                <span class="cont-p">造謠離婚媳婦妓女</span><br>
                &emsp;&emsp;「媽媽在公關公司工作啊。不是跟你說過了嗎？」「可是奶奶說，媽媽是妓女。」淑瑄聽到這話，頓覺五雷轟頂。她的婆婆在離婚後對孩子亂說話，已經不是第一次了。離婚前，婆婆就不喜歡她。離婚後，婆婆更把她當箭靶。<br><br>
                <span class="cont-p">婚姻走不下去時，女人的最大困擾之一，就是要不要帶孩子。</span><br>
                &emsp;&emsp;離婚後，不少女子面臨重新就業的問題，工作已經夠勞累，帶著孩子，經濟上和時間分配上都更加窘迫。所以，有不少的離婚女子，在面臨這種抉擇時，如果相信「他雖不是個好丈夫，但卻是個好爸爸」時，常不得已把監護權讓給有經濟實力、又有人可以幫忙照顧小孩的夫家。<br>
                &emsp;&emsp;離婚後，男人不帶孩子在身邊，一個人生活，等於又恢復單身的自由，沒有人會質疑他沒有父愛。但女人可苦了。以淑瑄為例，當人家聽說她離了婚，又把孩子留在夫家時，都會質疑她沒有母愛，這種壓力，是她之前想像不到的。<br><br>
                <span class="cont-p">孩子終有是非判斷</span><br>
                &emsp;&emsp;慧妍是另一種例子。她因丈夫有外遇而離婚，丈夫已經再婚了，孩子的後母對他們還算不錯，但就是喜歡和她較勁，證明她只不過是個不負責任的親生母親。孩子每兩個禮拜來和她過一次周末，回到家中，這新媽媽一定會仔細詢問：「你們在那邊到底在做什麼？」如果孩子誠實回答：「媽媽讓我打電玩或看電視。」不久後她就會接到前夫的電話，指責她不該寵壞孩子。如果孩子回去時感冒或被蚊子咬了，前夫也會氣沖沖的指責她照顧不周。<br><br>
                <span class="cont-p">和以前的家庭已失去溝通管道的離婚女子，常背著各式各樣無法澄清的罪名。</span><br>
                &emsp;&emsp;不管孩子跟誰，既然已經離了婚，夫妻不成仁義在，至少要有「不說對方壞話」的美德。畢竟孩子會長大，終有一天，他會發現事實，發現誰在興風作浪、誰的心態有問題。               
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Woman5',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service5.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
  margin: 1rem auto;
}
.list-in{
  list-style: upper-roman;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>