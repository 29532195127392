<template>
  <div class="Woman3">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Woman
              <span class="cont">女人心事</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/woman_sample" class="">女人心事</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">離婚後，我竟成了前夫的二奶</h2>
              <h5 class="tl">寧夏網 2007-06-04</h5>
              <p>
                離婚了，就不要來找我！我想，每個人都會這麼想。可在一起的朝朝暮暮就有著太多的藕斷絲連，分得不徹底，一不小心，我就做了二奶......<br><br>                
                <span class="cont-p">恩愛丈夫有了外遇</span><br>
                &emsp;&emsp;我和老公季東結婚後，感情一 直比較好，我們生活裡的唯一缺陷就是沒孩子，這讓我有些沮喪。季東反而常常安慰我，他說：“你急什麼啊，要孩子是要有機會的，你越急他就越躲著你，而你心平氣和，他就忽然而至！”我覺得季東說得好像有些道理，更為他對我的理解而感動。
                &emsp;&emsp;可讓我沒想到的是，平靜的生活裡竟然隱藏著一塊暗礁！5年前的一天下午，因為學校要粉刷教室，我們提前下班。難得有這樣的機會，我去買了一些菜，準備做些好吃的慰勞季東。可當我打開家門時，眼前的一幕讓我徹底驚呆了，季東正和一個女孩赤身裸體地抱在一起！<br>
                &emsp;&emsp;我頓時感覺天旋地轉，季東有些慌張，連忙穿衣服，而那個女孩卻是不緊不慢，一臉無所謂。那個女孩是我的遠房表妹，名叫陶玲，比季東小8歲，那年正讀大學三年級。陶玲走了以後，我心裡這個氣啊，隨手給了季東一記耳光。季東給我跪下了，他說他是一時衝動，並告訴我他和陶玲才是第一次。想想這麼多年恩愛，我的心軟了，決定原諒他們。<br>
                &emsp;&emsp;可事情的發展遠非我所料，一個多月後的一天，我又看到了同樣的場景。我質問陶玲，而陶玲的回答卻讓我吃驚而憤怒，她說：“表姐，我和季東已經快一年了，我們是真心相愛，應該退出的是你！你不覺得你橫在中間是個累贅嗎？”她的話粉碎了季東的謊言，我回過頭來，用眼光逼著季東，意思很明瞭，那是要他給我作個解釋！但他的頭一直低著，一句話也不說。<br><br>            
                <span class="cont-p">離婚了，為什麼還沒解脫</span><br>
                &emsp;&emsp;在吵吵鬧鬧中，2000年6月，我和季東正式離婚。從法院出來，我看看季東，這個和我同床共枕5年、這個讓我進退兩難的男人，那一刻，他也站在法院門口發呆。我一陣冷笑，心裡詛咒他去死吧，我永遠也不想再看見他！我伸手打了個車，剛上了前門，季東卻開後門鑽了進來。這法院判決也是，房子一人一半，兩室一廳，還得從一個大門進去，那和沒離婚有什麼區別。我狠狠地瞪了季東一眼，想把他趕下去，但想想畢竟在一起這麼多年，我的心就軟了。<br>
                &emsp;&emsp;到了晚上，我想不到的事情又發生了，季東竟然摸進我的房間，用力把我壓在身下，我奮力地想推開他，但根本不是他的對手。他嬉皮笑臉地對我說：“好歹也夫妻一場，那總得留點紀念吧！”罷了，反正5年也睡了，也不在於多這一次，明天一定要在門上上把鎖......<br>
                &emsp;&emsp;第二天，我請人在我的房間上了鎖。但讓我沒想到的是，離婚前我找不到季東的人影，離婚以後他卻天天和我在一起吃飯。兩天以後，我實在忍不住了：“對不起，請你不要和我黏在一起，我找到合適的就馬上結婚！當然，你也可以去找你的情人。”而季東則說這幾天他工作忙，以後他會注意。但一個星期以後，他卻告訴我，陶玲畢業以後跟著另外的男人去了廣州。聽了他的混帳話，我的火直往上冒：“那是你們的事情，就是你們都死了，那也和我沒關係，請你以後離我遠一些，不然鬧起來大家都不好看！”<br>
                &emsp;&emsp;那以後，我不再理睬季東。然而過了十幾天，我發現自己竟然懷孕了。他在玩什麼把戲<br>
                &emsp;&emsp;季東知道我懷孕的消息以後，就很認真地說：“我們結婚5年都沒有孩子，現在有了，你就忍心把他拿掉嗎？再說，又沒有多少人知道我們離婚，就給我一次機會吧！”看著季東誠懇的樣子，想想和季東畢竟在一起生活了5年，有了孩子，也許會穩定。<br>
                &emsp;&emsp;大半年以後，孩子生下來了，是個男孩，很可愛。可隨著寶寶一天天長大，煩惱也來了。孩子是非婚姻生產，報不了戶口，沒辦法，我只得厚下臉皮求季東和我重婚，季東也樂呵呵地同意。但我們都很馬虎，今天拖明天、明天拖後天，這一拖就是大半年。<br>
                &emsp;&emsp;那天早晨，天氣不錯，我和季東一道去民政局，準備去辦理重婚手續。但季東的手機響了，他接聽了一下，回答說馬上來，然後就對我說，他們老總找他有很重要的事情，說完慌慌張張地走了，那天晚上他也沒回來。從那以後，如果我再提重婚的事情，他不是說不要著急，就是說沒時間。<br>
                &emsp;&emsp;但那次以後，他就經常出去，很晚才回來，有時候根本就不回來。直覺告訴我，這其中可能發生了什麼。<br>
                &emsp;&emsp;又過了半年，季東卻忽然對我熱情起來，我回來以後，他會替我把拖鞋拿好，還搶著做飯，不但這樣，他晚上還天天摟住我睡覺，新婚的甜蜜一下子好像又重現了。有一天晚上，季東更是深情地對我說：“老婆，我這輩子虧欠你太多，我要用餘生來報答你！”他的話感動得我熱淚盈眶。<br><br>
                <span class="cont-p">他領回一個大肚子女人</span><br>
                &emsp;&emsp;這樣甜蜜地過了一個月，季東像是很隨意地對我說，陶玲回來了。這話頓時讓我警惕起來，季東看我驚訝的樣子，笑著說：“她剛離婚，工作也沒有，還挺著一個大肚子，怪可憐的。不如把她接到我們這裡來，你正好放暑假，可以照顧她一下！”<br>
                &emsp;&emsp;季東的話讓我氣得發瘋。那天晚上我一夜沒睡，心裡想如果我拒絕陶玲，那無疑會把季東推到她那邊，既然這樣，那我何不拿出一個女人的胸襟出來，讓季東和陶玲都感謝我，那我的婚姻不是牢靠了嗎？當然，我還要提醒季東趕快和我辦理重婚手續。<br>
                &emsp;&emsp;這樣想，我決定讓陶玲住到我們家來，其實，本來我有許多附加條件的，那就是讓季東趕快和我重婚，趕緊給孩子上戶口，但我又覺得，這時候如果提這樣的條件，好像是乘人之危，於是，我怎麼也說不出口。<br>
                &emsp;&emsp;季東見我同意，很高興地在我臉上親了一下，說：“還是老婆最好，我會一輩子感謝你！”看他那興奮的樣子，我的心裡忍不住一陣失落。
                &emsp;&emsp;陶玲來了，我陰沉著臉，想讓她難看。可她倒好，一見到我，就表姐長、表姐短的，那親熱勁頭好像我們之間根本就沒發生過什麼不愉快的事情！我真是服了她了，這樣的女人，真是能屈能伸，我冷漠地點點頭，居高臨下地說：“孩子幾個月啦，你在我們這裡不要拘束，把這裡當成自己的家！”說著，我用眼光看著季東，可季東卻裝著沒聽見。<br><br>
                <span class="cont-p">3個人在一個屋簷下</span><br>
                &emsp;&emsp;一個月不到，陶玲生了一個女孩，挺可愛的，但有一點讓我覺得不安，我覺得這個孩子和季東非常像，而且季東抱著那個孩子的親熱勁更讓我覺得不爽。於是，有一天我實在忍不住，對季東嘲諷道：“看你那神態，好像是你生的一樣！”<br>
                &emsp;&emsp;季東趕緊為自己辯護說：“你又來了，我能不高興嗎，我是他姨父啊！”我冷笑地回答：“知道就好！不要好了傷疤忘了疼！”這樣又過了一個月，陶玲抱著孩子說要走了。到了這一步，我也好人做到底，和季東一起幫她租了個房子。在回去的路上，我心裡一陣輕鬆，她帶著感激離開我，那自然不會再回來騷擾我們，那我就可以和季東過安定幸福的生活了。<br>
                &emsp;&emsp;這樣過了一個多月以後，有一天晚上，季東對我說，在這裡他就覺得不安，因為他會想起許多對不起我的事情，乾脆我們把這個房子賣掉，然後再買一套，為我們重婚作準備，再買的房子房產證上用我的名字。<br>
                &emsp;&emsp;我想了想，覺得這樣也好。又過了半個月，來了個買主，看過房子以後很滿意。本來我們房子值三十幾萬的，可那個買主只肯出29萬，我有點猶豫。季東就對買主說：“30萬吧，我也不和你討價還價了！”說著，他把我拉到一邊，悄悄地商量，他說他一天都不想呆在這裡了，在這裡他會做噩夢！<br>
                &emsp;&emsp;見季東如此堅決，我們就和那個人簽訂賣房協議。房款30萬，一次性付清。可讓我想不通的是，錢一到手，季東就拿去15萬，我很奇怪他的行為，他卻不在意地笑笑：“我先把我那部分存起來，你也這樣，到買房子的那天，我們看看誰出的錢多。”他這麼說還真有道理，再聯想到他這麼多天和我一道看新房，我打消了不少疑慮。<br><br>
                <span class="cont-p">這幾年原來我一直在做二奶</span><br>
                &emsp;&emsp;房子賣了，沒地方住，我就抱著孩子回到娘家，季東去了他們單位的宿舍。開始幾天，季東還陪我去看房子，但兩個星期以後，我打他手機他不接聽，後來乾脆停機了。<br>
                &emsp;&emsp;等我趕到他們單位，那裡的人卻告訴我，季東已經辭職了。我慌了，連忙跑到陶玲的出租屋，卻發現陶玲也搬走了，我有種預感，季東和陶玲聯合欺騙了我，這是怎樣的欺騙啊，那麼有心計、那麼有步驟，而我卻傻兮兮地信以為真。<br>
                &emsp;&emsp;半年後，我已經徹底心灰意冷。可就在這時候，我再次遇見季東，其實，他並沒有走遠，他還在這個城市，只是換了一個工作單位。我要季東給我一個合理的解釋，季東哈哈大笑說：“傻女人，你和我什麼關係啊，之所以對你好，那是陶玲沒有人照顧！看看，這是我和陶玲的結婚證書！”我顫抖地用手接過，仔仔細細地看著，那上面有季東和陶玲的照片，再看看領證日期，那是在大肚子陶玲來我家前的好幾個月。原來，我這幾年只是做了季東的二奶。<br>
                &emsp;&emsp;我再次忍住自己激動的情緒，問季東：“你有沒有想到過和我重婚？”季東想了一下說：“你還記得那次我們去重婚嗎，在那以前，我是有這樣的想法，但那天我接到陶玲回南京的電話，我的想法就改變了！”<br>
                &emsp;&emsp;原來是這樣，我再次癱軟在地上。沒辦法，我只得再次上法庭，但上了法庭我又得到什麼呢，法庭判決季東每個月給兒子生活費30元，直到他18歲成年。但這有什麼用呢，我已經浪費了好幾年的青春，更讓我揪心的是，孩子在成長的過程中，沒有爸爸的關懷，那對他幼稚的心靈又是怎樣的摧殘啊！
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Woman3',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service5.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
  margin: 1rem auto;
}
.list-in{
  list-style: upper-roman;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>