<template>
  <div class="Woman4">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Woman
              <span class="cont">女人心事</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/woman_sample" class="">女人心事</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">遠離家暴 找回自信</h2>
              <h5 class="tl">曾淑慧（臺灣士林地方法院檢察署統計主任）</h5>
              <p>
                &emsp;&emsp;近年來，家庭暴力的事件層出不窮，隨便翻開報紙，沒有一天缺少家暴新聞，受害者從婦女延伸到小孩，甚至亦有男性被虐者，隨著外籍配偶之增加，因語言的隔閡，文化的差異，家暴案件日趨增加與嚴重。而一般人普遍存在「家醜不外揚」的觀念，甚至連執行公權力的警察也常有「清官難斷家務事」、「法不入家門」的想法，使得家暴案件，在長期隱匿下，逐漸演變成嚴重、駭人聽聞的社會案件，許多是連電視電影編劇都想不出來的情節。<br><br>
                &emsp;&emsp;家庭暴力指的是<span style="color: red;">「家庭成員間實施身體或精神上不法侵害之行為」</span>；一般泛指發生於家庭成員間的暴力虐待行為，包含配偶前夫妻、同居人、同性伴侶、親子、手足、姻親之間的身體語言、精神、經濟控制、財務破壞及性虐待。家庭暴力是嚴重的犯罪行為，沒有任何一個人有權利對你進行肢體、性或情緒的虐待。因為，人人都有免於恐懼和免於受虐的權利。在婦女團體努力奔走下，立法院於1998年通過家庭暴力防治法，隔年施行，受虐者可以聲請緊急保護令。<br><br>
                &emsp;&emsp;保護令區分為通常保護令及暫時保護令。被害人、檢察官、警察機關或直轄市、縣﹙市﹚主管機關得向法院聲請保護令。被害人為未成年人、身心障礙者或因故難以委任代理人者，其法定代理人﹙警察分局長、社會局﹚、三等親以內之血親或姻親，得為其向法院聲請保護令。違反保護令罪，處三年以下有期徒刑、拘役或科或併科新臺幣十萬元以下罰金。<br><br>
                &emsp;&emsp;根據統計，94年各地方法院檢察署偵查家庭暴力案件新收2,743件﹙男性2,674人，女性261人﹚，其中家庭暴力罪占六成三、違反保護令罪占三成七。偵查終結被起訴家庭暴力罪計653件﹙男性633人，女性89人﹚，其中以傷害罪占86.1％為最多，其次依序為妨害自由罪、殺人罪、妨害性自主罪等；被起訴違反保護令罪者計1,066件﹙男性1,037人，女性38人﹚，其中以違反「禁止實施家庭暴力行為」占60.9％居冠，次為違反「禁止直接或間接騷擾、接觸、通話或其他連絡行為」占29.4％，再次為違反「遠離住居所、工作場所、學校或其他特定場所」占4.8％。<br><br>
                &emsp;&emsp;希望藉由公眾人物的勇敢站出來，能激勵更多潛藏的受虐人，不再黑夜哭泣，勇於向社會公權力及專業人員尋求協助，從中找回自信，慢慢擴大生活圈，最後是「改善生活，奔向自由」。<br><br>
                <ul>
                  <li>婦幼保護專線:113</li>
                  <li>行政電話:02-89127331</li>
                  <li>外籍配偶保護諮詢專線:0800-088-885</li>
                  <li>男性關懷專線:0800-013-999</li>
                </ul>
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Woman4',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service5.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
  margin: 1rem auto;
}
.list-in{
  list-style: upper-roman;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>