import { render, staticRenderFns } from "./law11.vue?vue&type=template&id=5e7f6e14&scoped=true&"
import script from "./law11.vue?vue&type=script&lang=js&"
export * from "./law11.vue?vue&type=script&lang=js&"
import style0 from "./law11.vue?vue&type=style&index=0&id=5e7f6e14&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e7f6e14",
  null
  
)

export default component.exports