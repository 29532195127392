<template>
  <div class="Serve9">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Service
              <span class="cont">詐騙行為蒐證</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/Service" class="">服務項目</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  詐騙行為蒐證
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">詐騙行為蒐證</h2>
              <p>
                女人徵信有限公司了解現今詐騙集團猖獗，許多人一不小心就受騙上當；女人徵信有限公司提供詐騙行為蒐證服務，提供相關防詐騙諮詢與蒐證協助！<br><br>
                詐騙案件之受害者，與學歷與年紀並沒有直接關係；詐騙集團往往針對人性之貪念與弱點，而受害者往往因為缺乏防詐騙資料而受騙上當。於是面對疑似詐騙的行為時，勿存貪念、冷靜謹慎、查證再查證是防詐騙的不二法門！現今詐騙集團猖獗，每個人除了需要除勿存貪念外，也需要多吸收資訊，藉他人經歷教訓引為鑑戒，遇事遵行勿急勿躁多思考詳查證等守則妥加研判，就可以成功防詐騙，讓詐騙集團銷聲匿跡！<br><br>                
                <span style="color:red;font-weight:600;font-size:18px;">防詐騙三要領</span><br>
                「冷靜」、「查證」、「報警」<br><br>
                <span style="color:red;font-weight:600;font-size:18px;">勿存貪念、勿忽略徵信手續</span>
                <ul class="list">
                    <li>貪念正是歹徒利用人性的弱點造成遭受詐騙的主因，切記！勿心存貪念，因小失大。</li>
                    <li>正當公司舉辦贈獎活動必有合法律師作信譽保證並請政財稅機關見證，應先向相關見證機關確認。領取彩金如須先繳納稅金，為避免遭受詐騙集團詐騙可親自登門拜訪，以確定真假。</li>
                    <li>收到他人支票可經由銀行徵信期開戶日期往來情形及存款基數，開戶時間太短而金額龐大時更要特別留意防詐騙。</li>
                    <li>現今亦有詐騙集團偽裝公務人員進行詐騙； 遇公務人員執行勤務時，除其佩帶證件及所著服飾外，應請其出示身分證件。</li>
                </ul>
                <span style="color:red;font-weight:600;font-size:18px;">妥善保存重要證件</span><br>
                身分證、護照、駕照、信用卡 … 等證件，應妥善保管必輕易交予他人。發現遺失或破損應即時申請補發，防止被非法冒用。重要文件及帳戶存摺、空白支票等資料，注意避免流出或遺失。需要用簽蓋章作為鑑定辨識依據之文件，最好以簽名代替蓋章，較能防止印章被仿冒導致權益受損。<br><br>                
                <span style="color:red;font-weight:600;font-size:18px;">細心照料家中年長者</span><br>
                金光黨行騙對象以年老者居多，平時常提醒他們有關歹徒行騙手法。其存摺交家人代為保管避免讓其單獨提領鉅款。<br><br>
                <span style="color:red;font-weight:600;font-size:18px;">預防不法仲介詐欺</span><br>
                房屋買賣要找信用可靠或自己熟識的代書業者經手，對所交易標的物，應先徵信相關土地資料、設定狀況及貸款情形，並可向原屋主詢，若情況有疑慮應暫緩簽約。<br><br>
                <span style="color:red;font-weight:600;font-size:18px;">小心宗教陷阱</span><br>
                台灣社會對於宗教有虔誠的信仰，於是宗教被許多詐騙集團及有心份子所利用，也因此防詐騙亦須注意宗教詐騙的陷阱！<br><br>
                <span style="color:red;font-weight:600;font-size:18px;">預防謊報親友傷病詐騙</span><br>
                遇有報稱親友傷病，先保持冷靜，確認醫院、病房，並向相關親友探詢，避免受騙。<br><br>
                <span style="color:red;font-weight:600;font-size:18px;">警政署防詐騙專線「165」</span><br>
                「165」全年不打烊，受理諮詢、檢舉或報案
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Serve9',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service2.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
}
.list{
  margin: 0.5rem auto;
  list-style-type: decimal;
}
.list li{
    margin-bottom: 0.8rem;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
@media (max-width: 322px) {
  .inner {
    width: 60%;
  }
  .inner-banger {
    padding: 0px;
  }
  .breadcrumb{
    font-size: 16px;
  }
  .title{
    font-size: 1.9rem;
  }
}
</style>