<template>
  <div class="Woman16">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Woman
              <span class="cont">女人心事</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/woman_sample" class="">女人心事</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">女人，喜歡男人怎麼吻她？</h2>
              <h5 class="tl">水瓶鯨魚 轉載自Yahoo!奇摩時尚</h5>
              <div class="">
                <img src="../assets/images/Woman3.jpg" class="img-fluid mb-3" alt="女人，喜歡男人怎麼吻她">
              </div>
              <p>
                男女接吻有一百種方式，但，哪一種接吻最讓女人心跳？<br>
                日本知名綜藝節目《男女糾察隊》某個單元〈你有多了解女人〉，節目中找了一堆搞笑男諧星來做現場測驗，然後再公佈1000名街頭女性的問卷調查，考核這些男藝人了解女人的功力。
                <ul class="list-in">
                  <li><span class="cont-p">先問妳，我可以吻妳嗎？妳答應了，才吻妳。</span></li>
                  <li><span class="cont-p">兩個人含情脈脈，自然而然就接吻。</span></li>
                  <li><span class="cont-p">約會時，忽然就吻了妳。</span></li>
                  <li><span class="cont-p">送妳回家時，突然把妳拉過來，用力吻了妳。</span></li>
                </ul>
                「好難回答喔，每個年齡層，喜歡的都不一樣吧。」和我一起看電視的27歲女生友惠這樣說。<br>
                說的也是，突然想起我的初吻，當年青春期的我被喜歡的男人問：「我可以吻你嗎？」我可是心跳加速、滿臉通紅呢；如果現在還有男人吻我之前，竟然問我：「可以吻妳嗎？」我心底一定會想：「哇咧，我怎麼會和這種呆男人約會！」哈哈哈。<br><br>
                性格比較害羞的友惠選擇（二），她感覺接吻應該是一種盡在不言中的浪漫。<br><br>
                暫且先不管我和友惠的私下討論，呵，你的四選一，選好了嗎？<br>
                你認為女人最喜歡是哪一種？<br>
                你選擇的答案跟我一樣嗎？<br>
                你和節目1000個日本女人的統計調查一樣嗎？<br>
                寫到這裡，我還賣關子，未免太迂迴，哈哈哈。<br><br>
                既然本文討論的是綜藝節目，綜藝節目都喜歡有「提示」；提示你，我自己選的答案是標準答案喔，也就是那1000名日本街頭女性的統計報告的榜首。<br>
                你猜對了嗎？結果，1000個街頭女性調查統計，最多女性選擇的答案是（四）送妳回家時，突然把妳拉過來，用力吻了妳」。我也是。<br>
                你如果驚訝地問我：「這，不是太粗魯了嗎？」<br>
                那麼，你顯然沒受過日本偶像劇、韓國愛情劇的夢幻洗禮。<br>
                換言之，我問你？麻辣火鍋算不算粗魯？好好一鍋美妙的食物，幹麻加一堆辣椒和花椒？想辣死人、麻死人喔？根本毀壞食物原味！太粗魯！<br>
                哦，不。只有不合你胃口，是粗魯；你喜歡的，叫作驚喜。<br>
                其實，大多數日本女生選擇了（四），只因為女人們想像題目中吻了自己的男人，是自己心儀的人，而第四個答案最有戲劇效果，彷彿就像日本偶像劇中的女主角。<br>
                倘若是不喜歡的男人或陌生的男人幹這種事，飆悍的女人可能會去警察局告男人性騷擾；膽怯的女人會躲在棉被哭，或打電話給朋友訴苦：「我今天遇到一件很噁心的事，太噁心了！！」<br>
                「對，只有我心儀已久的男人吻我、冒犯我、抱住我，我才會心動。」<br>
                所以，男人們請記住，可不要剛好看過那個節目或看了這篇文章的前半段，就冒冒失失把女人拉到懷中給她一個用力的吻，那麼有一天你吃上性騷擾官司，我完全不會同情你。<br>
                因為對於女人而言，只有喜歡的男人，突如其來親吻自己，才會忍不住心跳；簡單說，上桌的菜，只有合口味的，才會被挑動食慾。                
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Woman16',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service5.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
  margin: 1rem auto;
}
.list-in{
  margin: 0.5rem auto;
  list-style: decimal;
}
.list-in li{
  margin: 1rem auto;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>