<template>
  <div class="Main">
    <section class="hero-inne">
      <Intro />
    </section>
    <section class="bg about">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="about-box">
              <h2>關於我們</h2>
            </div>
            <div class="out-box">
              <p>
                您的青春奉獻給丈夫與家庭，以為這一切都是有代價的，期待著美好的將來，然而事實卻往往不是如此；婚外情的第三者往往出現在不屬於她的未來藍圖中，多少男人有了新歡忘卻舊愛的付出，又有多少男人因為婚外情而執意離婚！女人徵信公司不捨您委屈的淚水，而您失去的青春又怎是一句對不起就作罷！女人徵信公司為您成功抓姦、爭取離婚籌碼，為您爭取最大利益！
              </p>
              <router-link to="/About">
                <button class="learn-more mt-4">
                  <span class="circle" aria-hidden="true">
                    <span class="icon arrow"></span>
                  </span>
                  <span class="button-text">View More</span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="between-bg">
      <div class="container-fluid">
        <div class="row">
          <div class="col-9 bg-case" :style="bg1">
          </div>
          <div class="col-3 bg-case-right">
            <div class="py-5 case-text">
              <span>服</span><br>
              <span>務</span><br>
              <span>內</span><br>
              <span>容</span>
              <div id="mouse-scroll">
                <div class="mouse">
                  <div class="mouse-in"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-1 service">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <h2 class="title">服務項目</h2>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="row row-cols-lg-3 row-cols-md-2 row-cols-1 g-4">
              <div class="col">
                <router-link to="/Service1">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=1" alt="" :style="image1">
                    </div>
                    <div class="c-box">
                      <h2>外遇抓姦</h2>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <p class="card-text">社會風氣日趨開放，男女之間的關係也相對複雜；婚前劈腿、婚後外遇的情況愈加平常，於是調查劈腿與抓姦的需求也日與遽增。您是否婚姻中面臨外遇問題？不滿伴侶外遇出軌、第三者破壞您的家庭嗎？讓女人徵信有限公司的外遇抓姦服務來協助您！無論您是想要抓姦蒐證外遇證據，或者是希望藉此訴請離婚，女人徵信有限公司的外遇抓姦服務都是您最好的選擇！</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service7">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=2" alt="" :style="image1">
                    </div>
                    <div class="c-box">
                      <h2>離婚協助</h2>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <p class="card-text">不是每段婚姻都可以白頭偕老、甜蜜恩愛的走到最後；外遇、家暴等等，或者因為觀念的不同、對未來的分歧，都有可能是造成離婚的原因。面對外遇、家暴頻傳、離婚節節攀升的現今，誰都不敢保證未來會發生的事情。於是，對離婚多一點了解也是保障自身的方法。</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service3">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=3" alt="" :style="image1">
                    </div>
                    <div class="c-box">
                      <h2>感情挽回</h2>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <p class="card-text">先生外遇怎麼辦?等於第三者我該怎麼知己彼？如何感情挽回？挽回感情要注意些什麼？要怎麼做才可以感情挽回？
                          您想挽回他/她的心嗎?交給女人徵信有限公司來處理吧！我們幫您了解外遇對象的一切，對症下藥成功挽回您的婚姻，讓迷失於外遇的另一伴能找到回家的路。
                          女人徵信有限公司擁有最豐富的感情挽回經驗，我們主要需針對客戶的狀況，並運用科技與技巧、打動人心，以達到感情挽回之目的。</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service4">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=4" alt="" :style="image1">
                    </div>
                    <div class="c-box">
                      <h2>婚前徵信</h2>
                    </div>                    
                    <div class="card">
                      <div class="card-body">
                        <p class="card-text">愛情是抽象的，婚姻卻是現實的！為什麼戀愛時彼此眼中是如此完美，結了婚卻都變了樣？最主要的原因，就是缺乏婚前徵信！當愛情讓您盲目時，藉由婚前徵信可以讓您看清事實、清醒過來，藉由實際證據讓您更知道對方是否適合自己！
                          為何當初以為是佳偶天成，結婚後卻發現對方不如自己想像？人們總不自覺想在心愛的人面前表現出自己最好的一面，於是往往隱藏缺點，更會刻意隱藏不希望對方知道的事情；而婚前徵信能夠幫助您調查了解，讓您更加認識對方！
                          家暴的施暴者是在結婚之後才會暴力相向的嗎？當然不是！如果事先知道對方是否有施暴紀錄，就能避免家庭暴力發生在自己身上；於是您需要婚前徵信的協助！
                        </p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service6">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=5" alt="" :style="image1">
                    </div>
                    <div class="c-box">
                      <h2>跨國專案</h2>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <p class="card-text">台商大陸包二奶的新聞頻傳，女人徵信有限公司專業跨國專案服務提供台商台幹行蹤調查、大陸二奶調查、跨海抓姦服務；女人徵信有限公司專業跨國專案服務，除了有專業人員派遣大陸之外，更使用高科技徵信器材跟蹤調查蒐證，讓您輕輕鬆鬆的掌握另外一半的行蹤、是否包二奶等相關情形，讓您及早預防二奶破壞家庭！</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service5">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=6" alt="" :style="image1">
                    </div>
                    <div class="c-box">
                      <h2>家暴徵信</h2>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <p class="card-text">當您面臨家庭暴力的威脅時，讓女人徵信有限公司來協助您。很多人以為家庭暴力只是短暫的行為，為了維持家庭和諧與給孩子健全的家庭，於是不斷忍耐家暴；然而事實上，施暴者的施暴對象往往會延續到孩子身上；勇敢站出來解決家庭暴力，不僅是保護自己，也是保護孩子與家人的正確行為！</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service8">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=7" alt="" :style="image1">
                    </div>
                    <div class="c-box">
                      <h2>尋人徵信</h2>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <p class="card-text">您是否有知心好友因為時間過去而失去聯絡？面臨債務糾紛而捲款潛逃的欠債者？枕邊伴侶因為外遇、誤會而失去蹤跡？子女因為結交壞朋友而離家出走？或者因為各種原因而有尋人需求？女人徵信有限公司有專業尋人專家，無論您尋人對象是誰，都讓女人徵信有限公司為您成功尋人！</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service2">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=8" alt="" :style="image1">
                    </div>
                    <div class="c-box">
                      <h2>工商徵信</h2>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <p class="card-text">現在時代進步，科技愈來愈發達，一切都朝向e世代前進，工商徵信在經濟生活與工商社會，愈來愈重要，且扮演著市場交易安全的監控、市場交易的穩定性、交易信用驗證的重要角色。</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service9">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=9" alt="" :style="image1">
                    </div>
                    <div class="c-box">
                      <h2>詐騙行為蒐證</h2>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <p class="card-text">女人徵信有限公司了解現今詐騙集團猖獗，許多人一不小心就受騙上當；女人徵信有限公司提供詐騙行為蒐證服務，提供相關防詐騙諮詢與蒐證協助！</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service10">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=10" alt="" :style="image1">
                    </div>
                    <div class="c-box">
                      <h2>專利商標仿冒</h2>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <p class="card-text">由於仿冒行為日益嚴重，許多企業紛紛求助無門，有鑑於此，中華女人徵信特別成立專利商標仿冒調查小組，專門針對企業疲於應付的專利商標仿冒問題做徹底的調查。</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service11">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=11" alt="" :style="image1">
                    </div>
                    <div class="c-box">
                      <h2>其他徵信社服務</h2>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <p class="card-text">女人徵信有限公司了解現今社會型態之複雜，人們所面臨之難題亦隨之增加，於是女人徵信有限公司除了外遇抓姦、婚前徵信、尋人查址等服務之外，也提供市場調查、文書鑑定、寵物協尋，以及其他各種糾紛難題協助，當您生活面臨各種危機時，請聯絡女人徵信有限公司讓我們的專業迅速的優質服務幫助您順利解決各種問題！</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service12">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=12" alt="" :style="image1">
                    </div>
                    <div class="c-box">
                      <h2>二十四小時行蹤監控</h2>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <p class="card-text">當您有所懷疑就要調查清楚，讓我們專業二十四小時行蹤監控服務為您找出真相！
                          孩子慢慢的長大，社會現在如此的亂，您是否擔心孩子在外交友的情形？？近朱者赤；近墨者黑，成長的過程中，只要錯一步，就可能無法回頭。就讓我們為您跟蹤調查，無論是下課後的行蹤、和朋友去的場所、以及孩子戀愛交往的對象…藉由我們的行蹤監控服務，讓您掌握子女行蹤，避免遺憾發生！
                        </p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="link-box d-flex justify-content-center mb-4">
              <router-link to="/Service">
                <button class="learn-more">
                  <span class="circle" aria-hidden="true">
                    <span class="icon arrow"></span>
                  </span>
                  <span class="button-text">View More</span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="between-bg">
      <div class="container-fluid">
        <div class="row">
          <div class="col-9 bg-case" :style="bg2">
          </div>
          <div class="col-3 bg-case-right">
            <div class="py-5 case-text">
              <span>案</span><br>
              <span>例</span><br>
              <span>新</span><br>
              <span>聞</span>
              <div id="mouse-scroll">
                <div class="mouse">
                  <div class="mouse-in"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-2 Case">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="Case-title d-flex bd-highlight">
              <div class="me-auto">
                <h4 class="text-white">專欄</h4>
                <br>
                <span class="text-white">Special Column</span>
              </div>
              <div class="ml-auto align-self-center">
                <ul class="nav nav-tabs nav-justified">
                  <li class="nav-item">
                    <a class="nav-link" @click.prevent="setActive('home')" :class="{ active: isActive('home') }" href="#home">真實案例</a>
                  </li>
                  <li class="nav-item ml-2">
                    <a class="nav-link" @click.prevent="setActive('profile')" :class="{ active: isActive('profile') }" href="#profile">法律常識</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="tab-content py-3" id="myTabContent">
              <div class="tab-pane fade" :class="{ 'active show': isActive('home') }" id="home">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <router-link to="/News5" class="row">
                      <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="news" :style="news1"></div>
                      </div>
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <div class="news-content h-100 d-flex align-items-center">
                          <p>男子戴綠帽 收傳票才知道</p>
                        </div>
                      </div>
                      <div class="sed"></div>
                    </router-link>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <router-link to="/News4" class="row">
                      <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="news" :style="news2"></div>
                      </div>
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <div class="news-content h-100 d-flex align-items-center">
                          <p>假孫女養出真親情 追身世為領養</p>
                        </div>
                      </div>
                      <div class="sed"></div>
                    </router-link>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <router-link to="/News3" class="row">
                      <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="news" :style="news3"></div>
                      </div>
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <div class="news-content h-100 d-flex align-items-center">
                          <p>原諒妻出軌 再告不起訴</p>
                        </div>
                      </div>
                      <div class="sed"></div>
                    </router-link>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <router-link to="/News2" class="row">
                      <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="news" :style="news4"></div>
                      </div>
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <div class="news-content h-100 d-flex align-items-center">
                          <p>佼佼保證 阿寶非第三者</p>
                        </div>
                      </div>
                      <div class="sed"></div>
                    </router-link>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <router-link to="/News1" class="row">
                      <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="news" :style="news5"></div>
                      </div>
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <div class="news-content h-100 d-flex align-items-center">
                          <p>宇多田離婚 只為喘口氣</p>
                        </div>
                      </div>
                      <div class="sed"></div>
                    </router-link>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <router-link to="/News6" class="row">
                      <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="news" :style="news6"></div>
                      </div>
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <div class="news-content h-100 d-flex align-items-center">
                          <p>為婚外情殺妻女 張鶴齡判死刑</p>
                        </div>
                      </div>
                      <div class="sed"></div>
                    </router-link>
                  </div>
                </div>
                <div class="link-box d-flex justify-content-center my-4">
                  <router-link to="/News">
                    <button class="learn-more">
                      <span class="circle" aria-hidden="true">
                        <span class="icon arrow"></span>
                      </span>
                      <span class="button-text">View More</span>
                    </button>
                  </router-link>
                </div>
              </div>
              <div class="tab-pane fade" :class="{ 'active show': isActive('profile') }" id="profile">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <router-link to="/law8" class="row">
                      <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="news" :style="law1"></div>
                      </div>
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <div class="news-content h-100 d-flex align-items-center">
                          <p>夫妻離婚 須分擔扶養義務</p>
                        </div>
                      </div>
                      <div class="sed"></div>
                    </router-link>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <router-link to="/law4" class="row">
                      <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="news" :style="law2"></div>
                      </div>
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <div class="news-content h-100 d-flex align-items-center">
                          <p>大陸二奶可否爭取台商財產？</p>
                        </div>
                      </div>
                      <div class="sed"></div>
                    </router-link>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <router-link to="/law7" class="row">
                      <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="news" :style="law3"></div>
                      </div>
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <div class="news-content h-100 d-flex align-items-center">
                          <p>分居三年，就可以訴請離婚？</p>
                        </div>
                      </div>
                      <div class="sed"></div>
                    </router-link>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <router-link to="/law6" class="row">
                      <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="news" :style="law4"></div>
                      </div>
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <div class="news-content h-100 d-flex align-items-center">
                          <p>什麼情況可以申請保護令？</p>
                        </div>
                      </div>
                      <div class="sed"></div>
                    </router-link>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <router-link to="/law5" class="row">
                      <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="news" :style="law5"></div>
                      </div>
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <div class="news-content h-100 d-flex align-items-center">
                          <p>什麼狀況下，可請求離婚？</p>
                        </div>
                      </div>
                      <div class="sed"></div>
                    </router-link>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <router-link to="/law3" class="row">
                      <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="news" :style="law6"></div>
                      </div>
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <div class="news-content h-100 d-flex align-items-center">
                          <p>大陸包二奶之刑責</p>
                        </div>
                      </div>
                      <div class="sed"></div>
                    </router-link>
                  </div>
                </div>
                <div class="link-box d-flex justify-content-center my-4">
                  <router-link to="/law">
                    <button class="learn-more">
                      <span class="circle" aria-hidden="true">
                        <span class="icon arrow"></span>
                      </span>
                      <span class="button-text">View More</span>
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-3 A-link">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="text-center py-4">各大徵信公會強力推薦</h2>
            <div class="row row-cols-lg-3 row-cols-md-2 row-cols-1 g-4">
              <div class="col">
                <a href="http://www.chinese007.org.tw/" target="_blank">
                  <div class="link-img" :style="link1"></div>
                </a>
              </div>
              <div class="col">
                <a href="http://www.worldclass.com.tw/" target="_blank">
                  <div class="link-img" :style="link2"></div>
                </a>          
              </div>
              <div class="col">
                <a href="http://www.marryprotect.com.tw/" target="_blank">
                  <div class="link-img" :style="link3"></div>
                </a>                
              </div>
              <div class="col">
                <a href="http://www.tcdetect.org.tw/" target="_blank">
                  <div class="link-img" :style="link4"></div>
                </a>                
              </div>
              <div class="col">
                <a href="http://www.kaohsiung.org.tw/" target="_blank">
                  <div class="link-img" :style="link5"></div>
                </a>                
              </div>
              <div class="col">
                <a href="http://www.tpedetect.org.tw/" target="_blank">
                  <div class="link-img" :style="link6"></div>
                </a>                
              </div>
            </div>
            <hr>
            <div class="row row-cols-lg-3 row-cols-md-2 row-cols-1 g-4">
              <div class="col">
                <h3 class="link-title">各區聯絡電話<font-awesome-icon icon="long-arrow-alt-right" class="ml-2" /></h3>
                <ul class="list">
                  <li>台北：<a href="tel:0800503333">0800-50-3333</a></li>
                  <li>板橋：<a href="tel:0800503333">0800-50-3333</a></li>
                  <li>桃園：<a href="tel:0800234333">0800-234-333</a></li>
                  <li>新竹：<a href="tel:0800234333">0800-234-333</a></li>
                  <li>台中：<a href="tel:0800421111">0800-42-1111</a></li>
                  <li>嘉義：<a href="tel:0800421111">0800-42-1111</a></li>
                  <li>台南：<a href="tel:0800827999">0800-827-999</a></li>
                  <li>高雄：<a href="tel:0800827999">0800-827-999</a></li>
                </ul>
              </div>
              <div class="col">
                <h3 class="link-title">相關連結<font-awesome-icon icon="long-arrow-alt-right" class="ml-2" /></h3>
                <ul class="list">
                  <li><a href="https://www.uicco.org/" target="_blank">一統徵信</a></li>
                  <li><a href="https://www.gwohaw.org/" target="_blank">國華徵信</a></li>
                  <li><a href="http://www.lover007.com.tw/etong/" target="_blank">一統女子偵探</a></li>
                  <li><a href="http://www.lover007.com.tw/007/" target="_blank">環球女人偵探有現公司</a></li>
                  <li><a href="http://www.girl-1.com.tw/" target="_blank">珍女人外遇徵信社</a></li>
                  <li><a href="http://www.girlworld.com.tw/" target="_blank">世界女人徵信</a></li>
                  <li><a href="http://www.find-it.com.tw/" target="_blank">真相網</a></li>
                  <li><a href="http://www.1980.org.tw/" target="_blank">張老師</a></li>
                  <li><a href="http://www.lifeline.org.tw/" target="_blank">生命線</a></li>
                  <li><a href="http://law.moj.gov.tw/" target="_blank">台灣法規資料庫</a></li>
                  <li><a href="http://www.worldvision.org.tw/" target="_blank">台灣世界展望會</a></li>
                  <li><a href="http://www.npo.org.tw/" target="_blank">台灣公益資訊中心</a></li>
                  <li><a href="http://www.missingkids.org.tw/" target="_blank">失蹤兒童少年資料管理中心</a></li>
                </ul>
              </div>
              <div class="col">
                <h3 class="link-title">公司執照<font-awesome-icon icon="long-arrow-alt-right" class="ml-2" /></h3>
                <ul class="list">
                  <li>
                    <b-button v-b-modal.modal-1>
                      <img src="../assets/images/執照/1.jpg" alt="公司執照">
                    </b-button>
                    <b-modal id="modal-1" size="lg" centered title="公司執照">
                      <div class="text-center">
                        <img src="../assets/images/執照/0.jpg" alt="">
                      </div>
                      <template #modal-footer>
                      <div class="w-100">
                        <b-button
                          variant="outline-danger"
                          size="sm"
                          class="float-right btn2"
                          @click="hideModal"
                        >
                          Close
                        </b-button>
                      </div>
                    </template>
                    </b-modal>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Intro from "@/components/Intro.vue";

export default {
  name: "Main",
  data() {
    return {
      isShow: false,
      image1: {
        width:'150px',
        height:'150px',
      },
      bg1:{
        backgroundImage: `url(${require("../assets/images/btw.jpg")})`,
      },
      bg2:{
        backgroundImage: `url(${require("../assets/images/btw2.jpg")})`,
      },
      link1:{
        backgroundImage: `url(${require("../assets/images/Link/1.jpg")})`,
      },
      link2:{
        backgroundImage: `url(${require("../assets/images/Link/2.jpg")})`,
      },
      link3:{
        backgroundImage: `url(${require("../assets/images/Link/3.jpg")})`,
      },
      link4:{
        backgroundImage: `url(${require("../assets/images/Link/4.jpg")})`,
      },
      link5:{
        backgroundImage: `url(${require("../assets/images/Link/5.jpg")})`,
      },
      link6:{
        backgroundImage: `url(${require("../assets/images/Link/6.jpg")})`,
      },
      news1:{
        backgroundImage: `url(${require("../assets/images/news/1.jpg")})`,
      },
      news2:{
        backgroundImage: `url(${require("../assets/images/news/2.jpg")})`,
      },
      news3:{
        backgroundImage: `url(${require("../assets/images/news/3.jpg")})`,
      },
      news4:{
        backgroundImage: `url(${require("../assets/images/news/4.jpg")})`,
      },
      news5:{
        backgroundImage: `url(${require("../assets/images/news/5.jpg")})`,
      },
      news6:{
        backgroundImage: `url(${require("../assets/images/news/6.jpg")})`,
      },
      law1:{
        backgroundImage: `url(${require("../assets/images/law/1.jpg")})`,
      },
      law2:{
        backgroundImage: `url(${require("../assets/images/law/2.jpg")})`,
      },
      law3:{
        backgroundImage: `url(${require("../assets/images/law/3.jpg")})`,
      },
      law4:{
        backgroundImage: `url(${require("../assets/images/law/4.jpg")})`,
      },
      law5:{
        backgroundImage: `url(${require("../assets/images/law/5.jpg")})`,
      },
      law6:{
        backgroundImage: `url(${require("../assets/images/law/6.jpg")})`,
      },
      activeItem: 'home'
    };
  },
  components: {
    Intro,
  },
  methods: {
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-1', '#btnShow')
    }
  }
};
</script>


<style scoped>
a,
a:visited {
  color: black;
  text-decoration: none;
}
a:hover{
  text-decoration: none;
  color: black;
}
.bg {
  background-image: url(../assets/images/about.jpg);
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.about-box{
  padding: 40% 0 0;
  width: 50%;
  padding-left: 2%;
  position: relative;
  top: 30px;
}
.about-box h2{
  background: white;
  margin-bottom:0;
  padding: 20px 0;
  text-align: center;
}
.out-box{
  background: teal;
  width: 52%;
  margin-bottom: 40%;
  padding: 4rem 0.8rem;
}
.out-box p{
  margin-bottom: 0;
  color: whitesmoke;
}
.bg-1 {
  background:linear-gradient(to right, rgb(83, 175, 158) 0%, rgb(83, 175, 158) 100%);
}
.title{
  width: 100%;
  color: white;
  text-align: center;
  padding: 1.8rem 0px;
  margin-bottom: 30px;
  font-size: 3rem;
}
.card-box{
  position: relative;
  margin-bottom: 30px;
}
.card-img{
  margin: 0 auto;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: rgb(255, 222, 89);
  z-index: 5;
  margin-bottom: 40px;
}
.card-img img{
  border-radius: 50%;
}
.card-img2{
  position: absolute;
  top: 0;
  left: 31.333333%;
  margin: 0 auto;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid teal;
  background: rgb(255, 222, 89);
  z-index: 5;
}
.c-box{
  width: 90%;
  background: rgb(83, 150, 158);
  position: absolute;
  top: 42%;
  left: 5%;
  text-align: center;
  z-index: 3;
}
.c-box h2{
  margin-bottom: 0;
  padding: 0.5rem 0;
  color: white;
}
.card{
  position: initial;
  background: transparent;
  border: 0px;
  z-index: -1 !important;
  height: 100%;
}
.card-body {
  text-align: center;
  background: white;
}
.card-title{
  margin-top: 20px;
  font-weight: 600;
  font-size: 25px;
}
.card-text{
  display: inline-block;
  text-align: left;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; 
  -webkit-box-orient: vertical;
  white-space: normal;
  padding-top:30px ;
}
.bg-case{
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-case-right{
  background: linear-gradient(70deg,rgb(83, 180, 158) 0%, rgb(83, 150, 158) 100%);
}
.case-text{
  margin: 0 auto;
}
.bg-case-right span{
  display: block;
  margin: 0 auto;
  padding-top: 25px;
  font-size: 60px;
  font-weight: 800;
  text-align: center;
  color: white;
}
.bg-2 {
  background: linear-gradient(to right, rgb(83, 175, 158) 0%, rgb(83, 175, 158) 100%);
}
.title2{
  background: rgb(83, 150, 158);
  width: 100%;
  color: white;
  text-align: center;
  padding: 1.8rem 0px;
  margin-bottom: 30px;
  font-size: 3rem;
}
.card-conect{
  margin: 0 auto;
  height: 100%;
  width: 30%;
}
.Case-title{
  padding: 50px 0 20px;
}
.Case-title h4{
  display: inline-block;
  margin-bottom: 0;
  font-size: 35px;
}
.Case-title span{
  font-family: cursive;
  font-style: oblique;
}
.nav-tabs{
  border: 0;

}
.nav-tabs .nav-link{
  border-radius: 0.25rem;
  border:1px solid #006ff0;
}
.nav-tabs .nav-link:hover{
  background:#006ff0;
  color: white;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  color: white;
  background: #006ff0;
  border-color:transparent;
}
.btn2{
  border: 1px solid red;
  padding: .375rem .75rem;
}
.news{
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 50%;
  height: 100%;
  border-radius: 0.5rem;
  box-shadow: 5px 5px aliceblue;
}
.news-content p{
  margin-bottom: 0;
  color: white;
}
.sed{
  margin: 0 auto;
  padding-bottom: 20px;
  border-bottom: 1px solid bisque;
  width: 95%;
  margin-bottom: 20px;
}
a:hover .news-content p{
  font-weight: 600;
}
a:hover .news{
  transform: scale(1.05);
}
button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
button.learn-more {
  width: 12rem;
  height: auto;
}
button.learn-more .circle {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #282936;
  border-radius: 1.625rem;
}
button.learn-more .circle .icon {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
button.learn-more .circle .icon.arrow {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
button.learn-more .button-text {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #282936;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}
button:hover .circle {
  width: 100%;
}
button:hover .circle .icon.arrow {
  background: #fff;
  -webkit-transform: translate(1rem, 0);
          transform: translate(1rem, 0);
}
button:hover .button-text {
  color: #fff;
}
.btn-more{
  text-align: right;
  padding-right: 20px;
}
.btn-more-link{
  position: relative;
  padding-right: 25px;
}
.btn-more-link::before{
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  background-image: url(../assets/images/arrow-right.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 24px;
  height: 24px;
}
.animated:hover .btn-more-link::before ,.animated:hover .btn-more-link{
  -webkit-transition: .6s all;
  transition: .6s all;
  letter-spacing: 1.5px;
  right: -8px;
  color: blueviolet;
}
.animated:hover .btn-more-link::before{
  filter: invert(100%) sepia(158%) saturate(2974%) hue-rotate(251deg) brightness(65%) contrast(70%);
}
.card-img img{
  -webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}
.animated2:hover .card-img img{
  -webkit-filter: grayscale(0%);
	filter: grayscale(0%);
  box-shadow: 0px 5px 5px black;
  transform: scale(1.15);
	-webkit-transition: .6s ease-in-out;
	transition: .6s ease-in-out;
}
.animated2:hover .btn-more-link::before,.animated2:hover .btn-more-link{
  -webkit-transition: .6s all;
  transition: .6s all;
  letter-spacing: 1.5px;
  right: -8px;
  color: blueviolet;
}
.animated2:hover .btn-more-link::before{
  filter: invert(100%) sepia(158%) saturate(2974%) hue-rotate(251deg) brightness(65%) contrast(70%);
}

#mouse-scroll {
  margin: 0 auto;
}
#mouse-scroll .mouse {
  margin: 0 auto;
  height: 60px;
  width: 14px;
  border-radius: 10px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  /* border: 2px solid #ffffff; */
  top: 170px;
}
#mouse-scroll .mouse-in {
  height: 50px;
  width: 2px;
  display: block; 
  margin: 5px auto;
  background: #ffffff;
  position: relative;
}
#mouse-scroll .mouse-in {
 -webkit-animation: animated-mouse 1.2s ease infinite;
  moz-animation: mouse-animated 1.2s ease infinite;
}

@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.bg-3{
  background: rgb(70, 175, 162);
}
.A-link h2{
  color: white;
  font-size: 3rem;
}
.link-title {
  background: red;
  color: white;
  padding: 0.3rem;
  padding-left: 60px;
  transform: skew(-20deg);
  position: relative;
}
.link-title::before{
  content: '';
  position: absolute;
  background: rgb(70, 175, 162);
  top: -2%;
  left: 5%;
  width: 6%;
  height: 105%;
  transform: skew(-1deg);
}
.list li{
  padding: 0.5rem;
  font-size: 18px;
  color: whitesmoke;
  background-image: linear-gradient(to left, black 0%, black 70%, transparent 50%);
  background-size: 15px 1px;
  background-position: bottom;
  background-repeat: repeat-x;
}
.list a{
  color: whitesmoke;
}
.list a:hover{
  color: red;
}
.list button:hover{
  opacity: 0.7;
}
.link-img{
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 11%;
  margin-bottom: 20px;
}
.link-img:hover{
  opacity: 0.7;
}
@media(max-width:1025px){
  .c-box h2{
    font-size: 1.8rem;
  }
}

@media (max-width: 767px){
  .about-box{
    width: 95%;
    padding-left: 5%;
  }
  .out-box{
    width: 100%;
  }
  .bg-case-right span{
    font-size: 30px;
  }
  .news{
    margin-bottom: 20px;
    height: auto;
  }
  .sed{
    width: 91%;
  }
}
@media (max-width: 322px) {
  .card-img2{
    left: 23.333333%;
  }
  .c-box h2{
    font-size: 1.8rem;
  }
  .nav-tabs .nav-link{
    font-size: 22px;
  }
}
</style>

