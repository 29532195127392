<template>
  <div id="app">
    <div id="nav">
      <Navbar/>
    </div>
    <router-view/>
    <BackTop/>
    <Footer/>
  </div>
</template>


<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import BackTop from './components/BackTop.vue'
import Footer from './components/Footer.vue'


export default {
  name: 'Home',
  components: {
    Navbar,
    BackTop,
    Footer
  }
}
</script>

<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  a:hover{
    text-decoration: none;
    
  }
}
#nav {
  a {
    // font-weight: bold;
    // color: white;
    &.router-link-exact-active {
      font-weight: bold;
      border-bottom: 1px solid rgb(255, 222, 89);
      color: burlywood;
    }
  }
  a:hover{
    color: rgb(255, 222, 89);
    font-weight: bolder;
  }
}
  a,
  a:visited {
    color: black;
    text-decoration: none;
  }

@media(max-width:911px){
  a {
    &.router-link-exact-active {
      font-weight: bold;
      background: transparent;
      border-radius: 5px;
      border-bottom: 0px;
    }
  }
  #nav a.router-link-exact-active {
    font-weight: bold;
    color: rgb(255, 222, 89);
    border-bottom: 0;
  }
}
</style>
