<template>
  <div class="Woman11">
    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-box">
            <div class="hero-img"></div>
          </div>
          <div class="inner">
            <h1>
              Woman
              <span class="cont">女人心事</span>
            </h1>
          </div>
          <div class="inner-banger ml-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="">TOP</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/woman_sample" class="">女人心事</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  內容
                </li>
              </ol>
            </nav>
          </div>
          <div class="scroll-btn">
            <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -120,duration: 1500}">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
              <h2 class="title" id="element">凋零的花朵...家庭暴力</h2>
              <h5 class="tl">(地方/基隆市) [轉載自暖暖代誌] [2005/5/11]</h5>
              <p>
                <span style="color:#336699">
                今天我收到了花，雖然今天不是我的生日或其他特別的日子。<br>
                昨夜，我們第一次發生了爭吵，當時他對我說了許多狠毒的話，深深刺傷了我。<br>
                我知道他必定十分後悔，而且不是有意說那些話，因為他在今天送花給我。<br>
                今天我收到了花，雖然今天不是我們的結婚周年紀念或其他特別的日子。<br>
                昨夜我被他摔到牆上，他動手並且勒住我的脖子使我快要窒息。<br>
                昨夜的事真像一場惡夢。<br>
                我實在不敢相信竟然會發生那種事情。<br>
                今早我醒來時感覺全身痠痛，淤青遍體。<br>
                我認為他一定為此事後悔，因為他在今天送花給我。<br>
                今天我收到了花，雖然今天不是母親節或其他特別的日子。<br>
                昨夜，他又痛打了我一頓。<br>
                這一次的情況比上幾次更糟。<br>
                我很想離開他，但是如果我離開了他，我靠什麼過活？<br>
                我一個人如何能照料我的孩子們？<br>
                我如何賺錢？<br>
                我雖然很痛恨他，但是又怕離開他。<br>
                然而我知道他一定很後悔，因為他在今天送花給我。<br>
                今天我收到了花，今天是個很特別的日子。<br>
                因為我的喪禮在今天舉行。<br>
                昨夜，他終於殺了我。<br>
                他狠揍我致死。<br>
                如果當時我有勇氣和力量離開他，今天我就不會收到花了。
                </span><br><br>
                這首悲歌摘自彭南元先生於九十三年六月二十九日在南強工商教師研習坊的資料。一首如此的家庭暴力哀歌，很可能就在你我的週遭、社區的暗角喃喃自吟，而後飽受摧殘的她終究成為一朵飲恨凋零的花朵！<br><br>
                <span class="cont-p">是誰毀了她？</span><br>
                <ul class="list-in">
                  <li>文化：長久以來文化賦予男人權力，美國有一警長毆妻成性，但他的工作表現可以模糊這個缺點。男人需要的是外在成功，人格與家事都是小事。而女人就被要求一定要家庭成功，造成許多高教育婦女隱忍暴力問題特別嚴重。</li>
                  <li>合理化：時下流行的觀點強調，使用暴力的男人是因為童年創傷，內心是一個長不大的男孩；或是從小就沒有看到父母好好相處，所以不能情緒控制與憤怒管理。或者說男人因為缺乏自信或安全感，不敢對人表現出任何的不滿，於是就把怒氣出在老婆或孩子身上，然而這是「家醜不外揚」的私領域。又說因為酗酒或是失業才打老婆，但為什麼他們不會毆打酒友與讓他失業的老闆，而是回家半夜打老婆？</li>
                  <li>社會孤立：外籍新娘受暴的比例很高，男方會認為新娘是「取」來的（街頭的廣告都如此寫），所以動輒施暴、性虐待都是常態。且外籍新娘求助意願不是很高，他們來台時背負許多家鄉的期待，離開台灣是件很羞恥的事。她從海岸的那一頭走來，要回去的路太長，離婚後的失落感遠比台灣婦女還嚴重，會考慮更多，這些家暴法都幫不了忙。有些甚至不知道怎麼找資源，連驗傷都不知道，常常只能兩個來自同樣國家的女孩抱在一起痛哭而已。</li>
                  <li>經濟與孩子：也有受暴婦女說：「我們是嫁狗隨狗，先生要打就給他打，不要緊！誰叫我們捧著他們給的飯碗？！」</li>
                </ul>

                <span class="cont-p">你可以受到保護</span><br>
                台灣在民國88年6月24日家暴防治法公佈實施以來已五年多，雖仍有許多的環節可再落實與加強，但你可以這麼做：<br>
                <ul class="list-in">
                  <li>
                    <span>被配偶毆打或精神虐待時</span>
                    別害怕！首先，他毆打妳，在刑事上可能構成刑法第二百七十七條的普通傷害罪、或者刑法第二百七十八條的重傷罪。其次，在民事上，他已對妳的身體權造成侵害，妳亦可對他提出損害賠償的訴訟，要求他負擔妳被毆打後在財產上與精神上所遭受的損害賠償。另外，根據家庭暴力防治法，妳還可向法院聲請保護令，讓他不能接近妳。最後，妳還可以根據民法第一千零五十二條第一項第三款，向法院訴請離婚。要採取上述種種法律行動之前，切記蒐集證據是件至關要緊的事，包括被打後的驗傷單、他毆打、辱罵妳時的人證、物證（如紙條、錄音帶）。
                  </li>
                  <li>
                    <span>不讓施暴者靠近</span>
                    過去女人被丈夫打，女人只能離家出走。現在有了「保護令」的制度，女人可以藉著公權力的伸張，而讓施暴者不接近妳。根據家庭暴力防治法，保護令分通常保護令、暫時保護令二種，暫時保護令又可分成一般性暫時保護令與緊急性暫時保護令。違反保護令罪並非告訴乃論，而是公訴罪。刑度和普通傷害罪一樣，處三年以下有期徒刑、拘役或併科新台幣十萬元以下罰金。<br>
                    對於大部分遭受家庭暴力的婦女來說，她們並非想離婚，也不想讓丈夫入獄，只希望在家庭生活中結束暴力。讓我們發揮社區愛心，關心周遭的家庭及弱勢族群，讓一朵朵美麗的花兒在被摧殘之前，得到妥適的保護與照顧，誠願每個家庭脫離暴力，綻放美麗的顏色。
                  </li>
                </ul>
                <span class="cont-p">家庭暴力防治資源</span><br>
                婦幼防護專線113<br>
                &emsp;&emsp;自家庭暴力防治法於87年6月公布後，內政部家庭暴力防治委員會於九十年與世界展望會簽約，設立「113」婦幼保護專線，受理協助受害者危機處理諮詢與救援服務。<br>
                &emsp;&emsp;「113」為免付費電話，雖是單一線路窗口，但包含家庭暴力、兒童保護、性侵害等三種服務，由專職社工人員全年無休，二十四小時輪班接線，受理全省受虐兒童少年暨婦女個案舉報，並進行個案的通報及轉介，可彌補現行縣市政府及民間團體之兒童少年暨婦女保護專線在非辦公時間內，無法及時受理的缺憾。<br><br>
                <span class="cont-p">警政資源</span><br>
                &emsp;&emsp;警察局在各警察分局均設有「家庭暴力防治官」，他們是一群受過處理家庭暴力案件專業訓練的警察人員，會竭盡所能協助被害人。如果正處於遭受家庭暴力的急迫危險，或有繼績遭到重大傷害之疑慮時，就應該快打110電話報案，簡單扼要說出受害之狀況和地點，警察機關便會立刻派員前往處理。<br>
                其處理方式包括：隔離施暴者、將受傷者送醫、依法逮捕施暴者、製作＜處理家庭暴力案件調查紀錄表＞與＜處理家庭暴力案件現場報告表＞記錄、轉介相關服務資源、代向法院聲請＜緊急性暫時保護令....等。<br><br>
                <span class="cont-p">社政資源</span><br>
                &emsp;&emsp;內政部家庭防治及性侵害防治委員會除提供婦幼保護專線113外，尚提供外籍配偶保護諮詢專線0800-088-885，以及男性關懷專線電話0800-013-999等，均可藉此尋求相關資源、諮詢與安全防護。<br>
                目前各縣市機關均已設置家庭暴力暨性侵害防治中心，其處理方式包括：安撫與情緒支持、送醫及緊急安置等。基隆市政府家庭暴力暨性侵害防治中心是設在基隆市麥金路482號5樓，電話02-24201122。<br><br>
                <span class="cont-p">衛政資源</span><br>
                &emsp;&emsp;在衛政資源方面，行政院衛生署設有性侵害防治責任醫院，當被害人至醫療院所療傷時，醫院不得無故拒絕為被害人診療，也應由社工人員或護理人員陪伴被害人進行診療的過程，並注意診療時的隱私及安全。<br>
                醫院在為被害人診療的過程中，被害人或被害人的法定代理人均可向醫院要求開立驗傷診斷書，作為提出告訴的參考依據（傷害罪屬告訴乃論，須於六個月內決定是否提出告訴）。若被害人仍有其它問題，亦可請醫院社工協助處理。基隆市的責任醫院有：<br>
                <ul class="list-in">
                  <li>行政院衛生署基隆醫院（02）24292525，基隆市信二路268號</li>
                  <li>國軍基隆醫院（02）24625714，基隆市正隆街100號</li>
                  <li>長庚紀念醫院基隆分院 （02）24313131，基隆市麥金路222號</li>
                </ul>
                <span class="cont-p">民間支持團體</span><br>
                &emsp;&emsp;目前以防治家庭暴力為目標的社會團體以「台灣世界展望會」及「勵馨基金會」、「台北市婦女救援基金會」為主。<br>
                &emsp;&emsp;「台灣世界展望會」承辦「兒童少年暨婦女保護熱線中心」，正是要幫助國內兒童少年暨婦女免於遭受到虐待、遺棄、強暴等狀況，讓他們能夠在充滿愛與尊嚴的環境中，獲得生命的完全成長，也讓生活在這塊土地的每一個人，都成為兒童少年暨婦女的守護天使。其電話：(02)2585-6300；網址為：<a href="https://www.worldvision.org.tw/" class="a-link" target="_blank">https://www.worldvision.org.tw/</a>。<br>
                &emsp;&emsp;「勵馨基金會」以基督信仰與專業倫理為其核心價值，效法耶穌基督委身於弱勢族群的精神，以實際行動來關懷台灣的兒少與婦女，欲達成「促進兒少權益、提昇女性尊嚴、發展重要他人、更新社會文化」之目的。所提供的服務有：中途之家庇護安置服務、「蒲公英治療中心」心理諮商治療服務、未婚懷孕之諮詢安置服務、學校據點外展服務、法院關懷服務、婚暴婦女及兒童服務、少女潛能發展服務、社區服務、不幸兒少後續追蹤服務等，其電話：(02)2367-9595；網址：<a href="http://www.goh.org.tw/" class="a-link" target="_blank">http://www.goh.org.tw/</a>。<br>
                &emsp;&emsp;「台北市婦女救援基金會」是跨足於政府和民間社會之間雙方互動與對話的橋樑，其致力於建構一個支援民間婦女團體行動力、促進民間婦女團體意見溝通的資源及資訊交流中心。其服務對象為：遭受親密關係中被配偶身體虐待及精神虐待等暴力傷害的婦女，及未滿十八歲的目睹暴力子女。所提供之婦女個別服務有：個案管理、緊急危機處理、緊急庇護轉介、法律服務、陪同服務、經濟及就業協助服務、媒合個別心理諮商等，並針對婚暴婦女及目睹暴力的兒童，舉辦之服務工作有：婚暴婦女半自助支持團體、目睹暴力兒童遊戲治療團體、婚暴婦女及目睹暴力兒童心理治療工作坊等。其電話：02-27009595；網址：<a href="http://www.twrf.org.tw/" class="a-link" target="_blank">http://www.twrf.org.tw/</a>。<br><br>
                <span class="cont-p">網路資源及權益</span><br>
                &emsp;&emsp;目前有許多網站均有提供家庭暴力防治的相關資源，若想初步認識家庭暴力，除了上述之政府、醫療機關、基金會的網站外，在此亦提供一個以專題介紹家暴的網站台灣婦女資訊網：(<a href="http://taiwan.yam.org.tw/womenweb/hvdraft/index.html" class="a-link" target="_blank">家庭暴力專題</a>)，在此站中有專文深入淺出地討論各項相關議題，協助我們對於家庭暴力與防範之道有更多的的認識。
              </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'Woman11',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero{
  position: relative;
}
.hero-box {
  padding: 90px 0 0 22%;
  width: 100%;
  background: white;
}
.hero-img {
  width: 100%;
  background-image: url(../assets/images/service5.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  z-index: 1;
}
.hero h5 {
  max-width: 1050px;
  margin: auto;
  padding: 18px 0;
}
.inner {
  position: absolute;
  left: 0;
  bottom: 25%;
  width: 40%;
}
.inner h1 {
  color: darkgray;
  padding: 2.5rem 0;
  text-align: left;
  font-size: 7rem;
}
.inner h1 .cont {
  display: block;
  font-size: 1rem;
  letter-spacing: 0.2em;
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 27%;
  position: relative;
}
.cont::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 100%;
  width: 25%;
  border-bottom:1px solid black;
}
.inner-banger {
  background: transparent;
  padding: 0px 4rem 0px 0px;
  width: 100%;
}
.breadcrumb {
  background: transparent;
  margin-bottom: 0px;
  font-size: 18px;
  float: right;
  font-family: monospace,cursive;
}
.breadcrumb-item a:hover {
  color: rgb(255, 222, 89);
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: lightblue;
}
a,
a:visited {
  color: lightblue;
  text-decoration: none;
}
.title{
    border-left: 5px solid palevioletred;
    margin-bottom: 20px;
}
.tl{
  background: bisque;
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-size: 15px;
}
.cont-p{
  color: red;
  font-weight: 600;
}
.list{
  margin: 0.5rem auto;
  list-style-type: simp-chinese-informal;
}
.list li{
  margin: 1rem auto;
}
.list-in{
  margin: 0.5rem auto;
  list-style: decimal;
}
.list-in li{
  margin: 1rem auto;
}
.a-link:hover{
  color: blue;
}
.scroll-btn{
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid red; 
  border-bottom: 2px solid red;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
@media(max-width:1025px){
  .inner h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .hero-box{
    padding-top: 70px;
  }
  .hero-img{
    opacity: 0.7;
  }
  .inner h1 {
    font-size: 1.85rem;
    color: darkblue;
  }
  .scroll-btn{
    display: none;
  }
  .inner-banger {
    padding: 0px 2rem 0px 0px;
  }
}
</style>