<template>
  <div class="Footer">
    <div class="footer-inner">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <p class="text-center">Copyright ©2021 女人徵信有限公司 All rights reserved. Design by 錢進整合媒體</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
};
</script>

<style scoped>
.footer-inner{
  margin: 0 auto;
  width: 100%;
  background: rgba(0, 0, 0,0.75);
  padding: 20px 0;
}
p{
  margin-bottom: 0;
  color: whitesmoke;
}
</style>
